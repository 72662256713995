
const TopRightIcon = (props) => {
  const { className, active } = props
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1665 5.83333L5.83317 14.1667" stroke="currentColor" strokeWidth={active ? '2' : '1.25'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.6665 5.83333H14.1665V13.3333" stroke="currentColor" strokeWidth={active ? '2' : '1.25'} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TopRightIcon
