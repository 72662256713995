const Margin = () => {
  return (
    <svg width="56" height="26" viewBox="0 0 56 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="54" height="24" rx="4" stroke="#565E70" strokeDasharray="2 2"/>
      <rect x="5" y="5" width="46" height="16" rx="4" fill="#EFF0F7"/>
    </svg>
  )
}

export default Margin
