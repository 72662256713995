import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react'
import { FormattedMessage } from 'react-intl';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Toggle = ({ value, onChange, action, disabled, icon, helperEnabled, helperText, helperTextColor, helperBg, helperToggleAlignment, dataAttribute, className = '', reactIntlHelperId }) => {

  const styles = {
    action: {
      primary: {
        'unToggled': 'bg-gray-800',
        'toggled': 'bg-primary'
      }
    }
  }

  const { action: actionStyles } = styles
  const actionBg = actionStyles[action]
  const disabledStyles = 'pointer-events-none opacity-50'

  return(
    <div className={`flex items-center relative ${className}`}>
      {
        helperEnabled && 
        <div className={`flex min-w-[314px] min-h-[40px] rounded pl-[16px] text-[15px] font-rubik items-center ${helperBg && `bg-${helperBg}`} ${helperTextColor && `text-${helperTextColor}` }`}>
          <div className={`text-secondary text-sm leading-[14.22px] ${helperToggleAlignment === 'left' ? 'ml-[56px] mr-[32px]' : 'mr-[80px]'}`}>
            {
              reactIntlHelperId ? <FormattedMessage id={reactIntlHelperId} defaultMessage={helperText}/> : helperText
            }
          </div>
        </div>
      }
      <Switch
        checked={value}
        onChange={onChange}
        {...(dataAttribute && { "data-attribute": `toggle-${dataAttribute}` })}
        className={classNames(
          value ? `${actionBg['toggled']}` : `${actionBg['unToggled']}`,
          `inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 ${disabled && disabledStyles} border-transparent rounded-[10px] cursor-pointer transition-colors ease-in-out duration-200
          ${helperEnabled && (helperToggleAlignment === 'right' ? 'absolute top-[10px] right-[18px]' : 
          helperToggleAlignment === 'left' ? 'absolute top-[10px] left-[18px]' : '' )}`
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-[16px] w-[16px] rounded-[50%] bg-white shadow-md transform ring-0 transition ease-in-out duration-200'
          )}
        />
        {
          icon && 
          <span className={`top-[2px] absolute ${value ? 'left-[4px]' : 'right-[4px]'}`}>
            {icon}
          </span>
        }
      </Switch>
    </div>
  )
}

Toggle.propTypes = {
  disabled: PropTypes.bool,
  action: PropTypes.string,
  helperEnabled: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextColor: PropTypes.string,
  helperBg: PropTypes.string,
  helperToggleAlignment: PropTypes.oneOf(['right', 'left']),
  onChange: PropTypes.func,
  icon: PropTypes.element,
  value: PropTypes.bool,
  dataAttribute: PropTypes.string,
  reactIntlHelperId: PropTypes.string
};

Toggle.defaultProps = {
  disabled: false,
  action: "primary",
  helperEnabled: false,
  helperText: "Toggle helper text Toggle helper text Toggle helper text",
  helperTextColor: "secondary",
  helperBg: "gray-400",
  helperToggleAlignment: 'right',
  onChange: () => console.log(""),
  value: false,
  dataAttribute: "",
  reactIntlHelperId: ""
};