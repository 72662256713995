import gql from 'graphql-tag'

const MarkAllAsRead = gql`
  mutation MarkAllSiteNotificaitonsAsRead ($siteId: Int!) {
    markAllSiteNotificaitonsAsRead(input: { 
      siteId: $siteId
    }) 
    {
      success
      error
      notifications {
        id
        name
        data
        readAt
        createdAt
        updatedAt
        actionTakenAt
      }
    }
  }
`;

const MarkAllAsDone = gql`
  mutation MarkAllSiteNotificaitonsAsDone ($siteId: Int!) {
    markAllSiteNotificaitonsAsDone(input: { 
      siteId: $siteId
    }) 
    {
      success
      error
      notifications {
        id
        name
        data
        readAt
        createdAt
        updatedAt
        actionTakenAt
      }
    }
  }
`;

const MarkAsDone = gql`
  mutation MarkSiteNotificaitonAsDone ($id: Int!) {
    markSiteNotificaitonAsDone(input: { 
      id: $id
    }) 
    {
      success
      error
      notification {
        id
        name
        data
        readAt
        createdAt
        updatedAt
        actionTakenAt
      }
    }
  }
`;

const MarkAsRead = gql`
  mutation MarkSiteNotificaitonAsRead ($id: Int!) {
    markSiteNotificaitonAsRead(input: { 
      id: $id
    }) 
    {
      success
      error
      notification {
        id
        name
        data
        readAt
        createdAt
        updatedAt
        actionTakenAt
      }
    }
  }
`;

const MarkAsClose = gql`
  mutation MarkSiteNotificaitonAsClose($id: Int, $name: String, $siteId: Int!) {
    markSiteNotificaitonAsClose(input: { 
      id: $id
      name: $name
      siteId: $siteId
    }) 
    {
      success
      error
      notification {
        id
        name
        data
        readAt
        createdAt
        updatedAt
        actionTakenAt
      }
    }
  }
`;

export { 
  MarkAllAsDone, MarkAllAsRead, MarkAsDone, MarkAsRead, MarkAsClose
}
