

const TopLeftIcon = (props) => {
  const { className, active } = props
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8335 5.83333L14.1668 14.1667" stroke="currentColor" strokeWidth={active ? '2' : '1.25'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.3335 5.83333L5.8335 5.83333L5.8335 13.3333" stroke="currentColor" strokeWidth={active ? '2' : '1.25'} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TopLeftIcon