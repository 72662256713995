import { IconBell, IconX, IconUsers, IconCircleCheck, IconAlertCircle } from "@tabler/icons"
import Proptypes from "prop-types"

export const Alert = ({ show, closeCallback, information, textStyles, icon, color, showClose, className, textStyle }) => {

  const variant = {
    color: {
      primary: ["bg-primary-lighter border-primary", "bg-primary"],
      success: ["bg-success-light border-success", "bg-success"],
      danger: ["bg-danger-light border-danger", "bg-danger"]
    },
    icon: {
      bell: <IconBell size={18} stroke={1.25} color={"#FFFFFF"} />,
      check: <IconCircleCheck size={18} stroke={1.25} color={"#FFFFFF"} />,
      alert: <IconAlertCircle size={18} stroke={1.25} color={"#FFFFFF"} />,
      users: <IconUsers size={18} stroke={1.25} color={"#FFFFFF"} />
    }
  }

  const colorStyles = variant.color[color][0]
  const notificationIcon = variant.icon[icon]
  const notificationBackground = variant.color[color][1]

  return(
    <>
      {
        show &&
        <div className={`relative min-h-[48px] border flex justify-center rounded-[8px] items-center ${colorStyles} ${className}`}>
          {
            icon &&
            <div className={`${notificationBackground} h-[32px] w-[32px] flex justify-center items-center rounded-[8px] absolute translate-y-[-50%] top-[50%] left-[8px]`}>
              {notificationIcon}
            </div>
          }
          <div style={textStyle} className={`font-rubik text-secondary ${textStyles} w-full ${icon ? 'pl-[56px]' : 'pl-3'} break-words ${showClose ? 'pr-[56px]' : 'pr-[20px]'}`}>
            {information}
          </div>
          {
            showClose && 
            <div className={"absolute translate-y-[-50%] top-[50%] right-[20px] cursor-pointer"} onClick={closeCallback}>
              <IconX stroke={1.25} color={"#2E384D"} size={16}/>
            </div>
          }
        </div>
      }
    </>
  )
}

Alert.propTypes = {
  show: Proptypes.bool,
  closeCallback: Proptypes.func,
  information: Proptypes.string || Proptypes.element,
  icon: Proptypes.oneOf(["bell", "check", "alert"]),
  color: Proptypes.oneOf(["primary", "success", "danger"]),
  showClose: Proptypes.bool,
  textStyles: Proptypes.string
};

Alert.defaultProps = {
  show: true,
  closeCallback: () => console.log(""),
  information: 'This is an alert!',
  icon: "bell",
  color: "primary",
  showClose: true,
  textStyles: 'text-lg'
}