const Forms = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="310" height="272" viewBox="0 0 310 272" fill="none">
      <circle opacity="0.3" cx="156" cy="135" r="135" fill="#E6EEFF"/>
      <g clip-path="url(#clip0_117_1312)">
          <path d="M132.8 55.6334C136.924 55.6334 140.267 52.2904 140.267 48.1667C140.267 44.043 136.924 40.7 132.8 40.7C128.676 40.7 125.333 44.043 125.333 48.1667C125.333 52.2904 128.676 55.6334 132.8 55.6334Z" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M121.6 74.3V70.5666C121.6 68.5864 122.387 66.6872 123.787 65.2869C125.187 63.8866 127.086 63.1 129.067 63.1H136.533C138.514 63.1 140.413 63.8866 141.813 65.2869C143.213 66.6872 144 68.5864 144 70.5666V74.3" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g clip-path="url(#clip1_117_1312)">
          <path d="M178.4 55.6334C182.524 55.6334 185.867 52.2904 185.867 48.1667C185.867 44.043 182.524 40.7 178.4 40.7C174.276 40.7 170.933 44.043 170.933 48.1667C170.933 52.2904 174.276 55.6334 178.4 55.6334Z" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M167.2 74.3V70.5666C167.2 68.5864 167.987 66.6872 169.387 65.2869C170.787 63.8866 172.686 63.1 174.667 63.1H182.133C184.114 63.1 186.013 63.8866 187.413 65.2869C188.813 66.6872 189.6 68.5864 189.6 70.5666V74.3" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g clip-path="url(#clip2_117_1312)">
          <path d="M156 99.6334C160.124 99.6334 163.467 96.2904 163.467 92.1667C163.467 88.043 160.124 84.7 156 84.7C151.876 84.7 148.533 88.043 148.533 92.1667C148.533 96.2904 151.876 99.6334 156 99.6334Z" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M144.8 118.3V114.567C144.8 112.586 145.587 110.687 146.987 109.287C148.387 107.887 150.286 107.1 152.267 107.1H159.733C161.714 107.1 163.613 107.887 165.013 109.287C166.413 110.687 167.2 112.586 167.2 114.567V118.3" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g clip-path="url(#clip3_117_1312)">
          <path d="M201.6 99.6334C205.724 99.6334 209.067 96.2904 209.067 92.1667C209.067 88.043 205.724 84.7 201.6 84.7C197.476 84.7 194.133 88.043 194.133 92.1667C194.133 96.2904 197.476 99.6334 201.6 99.6334Z" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M190.4 118.3V114.567C190.4 112.586 191.187 110.687 192.587 109.287C193.987 107.887 195.886 107.1 197.867 107.1H205.333C207.314 107.1 209.213 107.887 210.613 109.287C212.013 110.687 212.8 112.586 212.8 114.567V118.3" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g clip-path="url(#clip4_117_1312)">
          <path d="M110.4 99.6334C114.524 99.6334 117.867 96.2904 117.867 92.1667C117.867 88.043 114.524 84.7 110.4 84.7C106.276 84.7 102.933 88.043 102.933 92.1667C102.933 96.2904 106.276 99.6334 110.4 99.6334Z" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M99.2 118.3V114.567C99.2 112.586 99.9866 110.687 101.387 109.287C102.787 107.887 104.686 107.1 106.667 107.1H114.133C116.114 107.1 118.013 107.887 119.413 109.287C120.813 110.687 121.6 112.586 121.6 114.567V118.3" stroke="#0157FF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path d="M155 136V136C155 145.921 148.082 154.499 138.386 156.6L80.1485 169.218C71.308 171.133 65 178.954 65 188V188" stroke="#ABB5BE" stroke-width="1.30126" stroke-dasharray="5.21 5.21"/>
      <path d="M155 149L155 181" stroke="#ABB5BE" stroke-width="2.3" stroke-dasharray="5.21 5.21"/>
      <path d="M155 136V136C155 145.921 161.918 154.499 171.614 156.6L229.852 169.218C238.692 171.133 245 178.954 245 188V188" stroke="#ABB5BE" stroke-width="1.30126" stroke-dasharray="5.21 5.21"/>
      <g filter="url(#filter0_d_117_1312)">
          <circle cx="59" cy="208" r="32" fill="white"/>
      </g>
      <path d="M67 207C66.7554 205.24 65.9391 203.61 64.6766 202.359C63.4142 201.109 61.7758 200.309 60.0137 200.082C58.2516 199.854 56.4636 200.212 54.9252 201.101C53.3868 201.99 52.1832 203.36 51.5 205M51 201V205H55" stroke="#0157FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M51 209C51.2446 210.76 52.0609 212.39 53.3234 213.641C54.5858 214.891 56.2242 215.691 57.9863 215.918C59.7484 216.146 61.5364 215.788 63.0748 214.899C64.6132 214.01 65.8167 212.64 66.5 211M67 215V211H63" stroke="#0157FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <g filter="url(#filter1_d_117_1312)">
          <rect x="118.578" y="183.27" width="73.6393" height="62.7298" rx="1.24962" fill="white"/>
          <rect x="121" y="191" width="68" height="16.5664" rx="2" fill="#FAFAFD"/>
          <rect x="167.575" y="211.248" width="21.4247" height="13.3451" rx="2" fill="#FAFAFD"/>
          <rect x="167.575" y="228.274" width="21.4247" height="13.3451" rx="2" fill="#FAFAFD"/>
          <rect x="121" y="211.248" width="15.8356" height="3.68142" rx="1.84071" fill="#FAFAFD"/>
          <rect x="121" y="228.274" width="15.8356" height="3.68142" rx="1.84071" fill="#FAFAFD"/>
          <rect x="121" y="216.31" width="44.0612" height="2.30088" rx="1.15044" fill="#FAFAFD"/>
          <rect x="121" y="233.336" width="44.0612" height="2.30088" rx="1.15044" fill="#FAFAFD"/>
          <rect x="121" y="219.991" width="37.726" height="2.30088" rx="1.15044" fill="#FAFAFD"/>
          <rect x="121" y="237.018" width="37.726" height="2.30088" rx="1.15044" fill="#FAFAFD"/>
          <rect x="121" y="240.699" width="20.9589" height="2.30088" rx="1.15044" fill="#FAFAFD"/>
          <rect x="128.233" y="197.004" width="54.5339" height="41.017" rx="1.16525" fill="#DBE6FC" stroke="#C3D6FE" stroke-width="0.466102"/>
          <rect x="144.012" y="225.713" width="22.9747" height="4.82361" rx="2.41181" fill="#0057FF"/>
          <rect x="132.525" y="217.674" width="45.9494" height="4.82361" rx="0.932203" fill="white"/>
          <rect x="145.057" y="212.85" width="20.8861" height="1.39831" rx="0.699153" fill="white"/>
          <rect x="139.488" y="208.348" width="32.0253" height="1.86441" rx="0.932203" fill="white"/>
          <rect x="147.842" y="204.489" width="15.3165" height="1.39831" rx="0.699153" fill="white"/>
          <path d="M118.578 184.52C118.578 183.83 119.137 183.27 119.827 183.27H190.967C191.657 183.27 192.217 183.83 192.217 184.52V188.725H118.578V184.52Z" fill="#0057FF"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M126.76 185.998C126.76 186.5 126.353 186.907 125.851 186.907C125.349 186.907 124.942 186.5 124.942 185.998C124.942 185.495 125.349 185.088 125.851 185.088C126.353 185.088 126.76 185.495 126.76 185.998ZM123.578 185.998C123.578 186.5 123.171 186.907 122.669 186.907C122.167 186.907 121.76 186.5 121.76 185.998C121.76 185.496 122.167 185.088 122.669 185.088C123.171 185.088 123.578 185.496 123.578 185.998ZM129.032 186.907C129.535 186.907 129.942 186.5 129.942 185.998C129.942 185.495 129.535 185.088 129.032 185.088C128.53 185.088 128.123 185.495 128.123 185.998C128.123 186.5 128.53 186.907 129.032 186.907Z" fill="#D9DEE5"/>
      </g>
      <g filter="url(#filter2_d_117_1312)">
          <rect x="224" y="183.27" width="73.6393" height="62.7298" rx="1.24962" fill="white"/>
          <rect x="226.998" y="195.248" width="31.711" height="19.7524" rx="2" fill="#FAFAFD"/>
          <g clip-path="url(#clip5_117_1312)">
            <path d="M243.282 203.268H243.288" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M244.269 201.295H239.335C238.518 201.295 237.855 201.958 237.855 202.775V207.709C237.855 208.526 238.518 209.189 239.335 209.189H244.269C245.087 209.189 245.749 208.526 245.749 207.709V202.775C245.749 201.958 245.087 201.295 244.269 201.295Z" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M237.855 206.722L239.829 204.748C240.054 204.532 240.309 204.418 240.569 204.418C240.829 204.418 241.084 204.532 241.309 204.748L243.776 207.215" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M242.789 206.229L243.282 205.735C243.507 205.519 243.763 205.405 244.022 205.405C244.282 205.405 244.538 205.519 244.763 205.735L245.749 206.722" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <rect x="226.998" y="219" width="31.711" height="19.7524" rx="2" fill="#FAFAFD"/>
          <g clip-path="url(#clip6_117_1312)">
            <path d="M243.282 227.021H243.288" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M244.269 225.047H239.335C238.518 225.047 237.855 225.71 237.855 226.527V231.461C237.855 232.279 238.518 232.941 239.335 232.941H244.269C245.087 232.941 245.749 232.279 245.749 231.461V226.527C245.749 225.71 245.087 225.047 244.269 225.047Z" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M237.855 230.474L239.829 228.501C240.054 228.284 240.309 228.17 240.569 228.17C240.829 228.17 241.084 228.284 241.309 228.501L243.776 230.968" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M242.789 229.981L243.282 229.488C243.507 229.271 243.763 229.157 244.022 229.157C244.282 229.157 244.538 229.271 244.763 229.488L245.749 230.474" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <rect x="262.044" y="195.248" width="31.711" height="19.7524" rx="2" fill="#FAFAFD"/>
          <g clip-path="url(#clip7_117_1312)">
            <path d="M278.329 203.268H278.334" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M279.315 201.295H274.382C273.564 201.295 272.902 201.958 272.902 202.775V207.709C272.902 208.526 273.564 209.189 274.382 209.189H279.315C280.133 209.189 280.796 208.526 280.796 207.709V202.775C280.796 201.958 280.133 201.295 279.315 201.295Z" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M272.902 206.722L274.875 204.748C275.1 204.532 275.355 204.418 275.615 204.418C275.875 204.418 276.13 204.532 276.355 204.748L278.822 207.215" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M277.835 206.229L278.329 205.735C278.554 205.519 278.809 205.405 279.069 205.405C279.329 205.405 279.584 205.519 279.809 205.735L280.796 206.722" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <rect x="262.044" y="219" width="31.711" height="19.7524" rx="2" fill="#FAFAFD"/>
          <g clip-path="url(#clip8_117_1312)">
            <path d="M278.329 227.021H278.334" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M279.315 225.047H274.382C273.564 225.047 272.902 225.71 272.902 226.527V231.461C272.902 232.279 273.564 232.941 274.382 232.941H279.315C280.133 232.941 280.796 232.279 280.796 231.461V226.527C280.796 225.71 280.133 225.047 279.315 225.047Z" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M272.902 230.474L274.875 228.501C275.1 228.284 275.355 228.17 275.615 228.17C275.875 228.17 276.13 228.284 276.355 228.501L278.822 230.968" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M277.835 229.981L278.329 229.488C278.554 229.271 278.809 229.157 279.069 229.157C279.329 229.157 279.584 229.271 279.809 229.488L280.796 230.474" stroke="#0157FF" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <path d="M224 184.52C224 183.83 224.559 183.27 225.25 183.27H296.39C297.08 183.27 297.639 183.83 297.639 184.52V188.725H224V184.52Z" fill="#0057FF"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M232.183 185.998C232.183 186.5 231.776 186.907 231.274 186.907C230.772 186.907 230.365 186.5 230.365 185.998C230.365 185.495 230.772 185.088 231.274 185.088C231.776 185.088 232.183 185.495 232.183 185.998ZM229 185.998C229 186.5 228.593 186.907 228.091 186.907C227.589 186.907 227.182 186.5 227.182 185.998C227.182 185.496 227.589 185.088 228.091 185.088C228.593 185.088 229 185.496 229 185.998ZM234.455 186.907C234.957 186.907 235.364 186.5 235.364 185.998C235.364 185.495 234.957 185.088 234.455 185.088C233.953 185.088 233.546 185.495 233.546 185.998C233.546 186.5 233.953 186.907 234.455 186.907Z" fill="#D9DEE5"/>
      </g>
      <defs>
          <filter id="filter0_d_117_1312" x="0.974895" y="155.18" width="116.05" height="116.05" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="5.20502"/>
            <feGaussianBlur stdDeviation="13.0126"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_117_1312"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_117_1312" result="shape"/>
          </filter>
          <filter id="filter1_d_117_1312" x="106.685" y="173.756" width="97.4251" height="86.5156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2.37859"/>
            <feGaussianBlur stdDeviation="5.94648"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_117_1312"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_117_1312" result="shape"/>
          </filter>
          <filter id="filter2_d_117_1312" x="212.107" y="173.756" width="97.4251" height="86.5156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2.37859"/>
            <feGaussianBlur stdDeviation="5.94648"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_117_1312"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_117_1312" result="shape"/>
          </filter>
          <clipPath id="clip0_117_1312">
            <rect width="44.8" height="44.8" fill="white" transform="translate(110.4 35.1001)"/>
          </clipPath>
          <clipPath id="clip1_117_1312">
            <rect width="44.8" height="44.8" fill="white" transform="translate(156 35.1001)"/>
          </clipPath>
          <clipPath id="clip2_117_1312">
            <rect width="44.8" height="44.8" fill="white" transform="translate(133.6 79.1001)"/>
          </clipPath>
          <clipPath id="clip3_117_1312">
            <rect width="44.8" height="44.8" fill="white" transform="translate(179.2 79.1001)"/>
          </clipPath>
          <clipPath id="clip4_117_1312">
            <rect width="44.8" height="44.8" fill="white" transform="translate(88 79.1001)"/>
          </clipPath>
          <clipPath id="clip5_117_1312">
            <rect width="11.8409" height="11.8409" fill="white" transform="translate(235.882 199.321)"/>
          </clipPath>
          <clipPath id="clip6_117_1312">
            <rect width="11.8409" height="11.8409" fill="white" transform="translate(235.882 223.074)"/>
          </clipPath>
          <clipPath id="clip7_117_1312">
            <rect width="11.8409" height="11.8409" fill="white" transform="translate(270.928 199.321)"/>
          </clipPath>
          <clipPath id="clip8_117_1312">
            <rect width="11.8409" height="11.8409" fill="white" transform="translate(270.928 223.074)"/>
          </clipPath>
      </defs>
    </svg>
  )
}

export default Forms