const PasswordPage = () => <svg style={{color:"#0057FF"}} width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.9601 0H2.0401C1.65831 0.00041927 1.29229 0.152268 1.02233 0.422229C0.752365 0.692191 0.600517 1.05822 0.600098 1.44V25.56C0.600517 25.9418 0.752365 26.3078 1.02233 26.5778C1.29229 26.8477 1.65831 26.9996 2.0401 27H29.9601C30.3419 26.9996 30.7079 26.8477 30.9779 26.5778C31.2478 26.3078 31.3997 25.9418 31.4001 25.56V1.44C31.3997 1.05822 31.2478 0.692191 30.9779 0.422229C30.7079 0.152268 30.3419 0.00041927 29.9601 0ZM3.0401 1.96H28.9601C29.0874 1.96011 29.2094 2.01072 29.2994 2.10072C29.3894 2.19071 29.44 2.31273 29.4401 2.44V5.84H2.5601V2.44C2.56021 2.31273 2.61082 2.19071 2.70081 2.10072C2.79081 2.01072 2.91283 1.96011 3.0401 1.96ZM28.9601 25.04H3.0401C2.91283 25.0399 2.79081 24.9893 2.70081 24.8993C2.61082 24.8093 2.56021 24.6873 2.5601 24.56V7.8H29.4401V24.56C29.44 24.6873 29.3894 24.8093 29.2994 24.8993C29.2094 24.9893 29.0874 25.0399 28.9601 25.04Z" fill="#0057FF"/>
<rect x="26" y="3" width="2" height="2" rx="1" fill="#0057FF"/>
<rect x="23" y="3" width="2" height="2" rx="1" fill="#0057FF"/>
<rect x="4" y="3" width="15" height="2" rx="1" fill="#0057FF"/>
<rect x="16" y="17" width="11" height="2" rx="1" fill="#0057FF"/>
<rect x="16" y="13" width="11" height="2" rx="1" fill="#0057FF"/>
<path d="M11.857 15.4286H6.14243C5.51121 15.4286 4.99951 15.9403 4.99951 16.5715V20.0002C4.99951 20.6314 5.51121 21.1431 6.14243 21.1431H11.857C12.4882 21.1431 12.9999 20.6314 12.9999 20.0002V16.5715C12.9999 15.9403 12.4882 15.4286 11.857 15.4286Z" stroke="#0057FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.99968 18.8573C9.31529 18.8573 9.57114 18.6015 9.57114 18.2859C9.57114 17.9702 9.31529 17.7144 8.99968 17.7144C8.68407 17.7144 8.42822 17.9702 8.42822 18.2859C8.42822 18.6015 8.68407 18.8573 8.99968 18.8573Z" stroke="#0057FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.71387 15.4285V13.1427C6.71387 12.5365 6.9547 11.9551 7.38337 11.5264C7.81205 11.0977 8.39346 10.8569 8.99971 10.8569C9.60595 10.8569 10.1874 11.0977 10.616 11.5264C11.0447 11.9551 11.2855 12.5365 11.2855 13.1427V15.4285" stroke="#0057FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export default PasswordPage