import PropTypes from "prop-types"

export const Activity = ({ className, icon, information, progress, title }) => {
  return (
    <div className={`relative p-[12px] text-sm leading-[14.22px] bg-gray-200 ${className}`}>
      <div className="flex justify-between font-medium">
        <div className="text-secondary">{title}</div>
        <div className="text-blue">{progress}</div>
      </div>
      <div className="font-light text-secondary mt-[8px] mb-[13px]">{information}</div>
      {
        icon && <div className="mb-[4px]">{icon}</div>
      }
    </div>
  );
}

Activity.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
  information: PropTypes.string,
  progress: PropTypes.string,
  title: PropTypes.string
};

Activity.defaultProps = {
  className: "",
  information: 'Oust the Tsars',
  propTypes: "20%",
  title: 'Onboarding Task name'
};