import { useQuery } from "@apollo/client"
import PropTypes from 'prop-types'

import { GET_PARTNER_BY_HOST_NAME } from "../../graphql/query/partner"

export const LogoImg = ({ onClick, logoKey }) => {
  const { data } = useQuery(GET_PARTNER_BY_HOST_NAME, {
    variables: {
      hostName: window.location.hostname
    }
  })
  const { getPartnerByHostName: { logo: { [logoKey]: { url = "" }  = {} } = {} } = {} } =  data || {}
  
  return <img className="lg:block h-8  cursor-pointer sm:w-auto" src={url} alt="Workflow" onClick={onClick}/>
}

LogoImg.propTypes = {
  logoKey: PropTypes.string
}

LogoImg.defaultProps = {
  logoKey: 'full_black',
  onClick: () => {}
}