
import PropTypes from 'prop-types';

export const Tab = ({ tabs, callBack, activeTab, tabLabelClassName }) => {

  const getRenderedTabs = (tabElements) => {
    return tabElements.map(({ icon, label, key }, index) => {
      const lowerCasedActiveTab = activeTab ? activeTab.toLowerCase() : '';
      const lowerCasedKey = key ? key.toLowerCase() : '';

      const isActive = lowerCasedActiveTab === lowerCasedKey;
      
      const tabLabel = <p className={`text-base cursor-pointer font-rubik hover:text-primary ${isActive ? 'font-medium text-primary' : 'font-normal text-secondary'}`}>
        {label || 'N/A'}
      </p>

      const tabDivStyle = `pl-[16px] pr-[16px] pb-[9px] pt-[8px] h-[32px] hover:text-primary
        ${ isActive && 'border-b-2 border-primary text-primary' }`;
      
      return (
        <div key={index}
          onClick={() => callBack(key)}
          className={tabDivStyle}>
          {
            icon ? 
            <div className='flex'>
              <div className='mr-[16.08px]'>{icon}</div>
              {tabLabel}
            </div> 
            :
            tabLabel
          }
        </div>
      );
    });
  };
  return (
    <div className={`flex w-full ${tabLabelClassName}`}>
      {
        getRenderedTabs(tabs)
      }
    </div>
  );
};

Tab.propTypes = {
  tabs: PropTypes.array,
  callBack: PropTypes.func,
  activeTab: PropTypes.string,
  tabLabelClassName: PropTypes.string
};

Tab.defaultProps = {
  tabs: [{
    label: 'TAB 1',
    key: 'tab1'
  },{
    label: 'TAB 2',
    key: 'tab2'
  },{
    label: 'TAB 3',
    key: 'tab3'
  }],
  activeTab: 'TAB 2',
  callBack: (e) => console.log('clicked tab is ', e)
};
