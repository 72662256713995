import ReactColorPicker from 'rc-color-picker'
import PropTypes from "prop-types"
import 'rc-color-picker/assets/index.css'

const ColorPicker = (props) => {
  const { value: val, onChange, className, showLabel } = props
  const value = val || '#ffffff'
  
  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  }

  const componentToHex = (c) => {
    const hex = c.toString(16)
    return hex.length === 1 ? "0" + hex : hex
  }

  const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
  }

  const getColor = (value) => {
    let color = value?.toLowerCase()
    if (color.indexOf("rgba") > -1) {
      let rgba = color.replace("rgba(", "").replace(")", "").split(",")
      color = rgbToHex(parseFloat(rgba[0]), parseFloat(rgba[1]), parseFloat(rgba[2]))
    }  
    return color
  }

  const getAlpha = (value) => {
    let alpha = 100
    const color = value.toLowerCase()
    if (color.indexOf("rgba") > -1) {
      let rgba = color.replace("rgba(", "").replace(")", "").split(",")
      alpha = parseFloat(rgba[3]) * 100
    }
    return alpha
  }

  const handleChange = (color) => {
    if(color?.color) {
      const hex = color.color
      const rgb = hexToRgb(hex)
      const alpha = color.alpha
      const rgba = "rgba("+rgb.r+","+rgb.g+","+rgb.b+","+ alpha/100 +")"
      const value = (alpha < 100) ? rgba : hex
      onChange && onChange(value)
    }
  }

  const pickerProps = {
    onChangeComplete: onChange,
    placement: "topLeft",
    alpha: getAlpha(value),
    onChange: handleChange,
    color: getColor(value),
    className
  }

  return (
    <div className="flex items-center">
      <ReactColorPicker {...pickerProps} />
      {showLabel && <div className="font-normal text-gray-900 text-sm ml-[8px]">{getColor(value).toUpperCase().replace('#','')}</div>}
    </div>
  )
}


ColorPicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  showLabel: PropTypes.bool
};

ColorPicker.defaultProps = {
  value: '#ffffff',
  name: 'bg_color',
  onChange: null,
  className: 'border-gray-400 rounded-[4px] border-[1px]',
  showLabel: true
};

export { ColorPicker }