import { useEffect } from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Picker from 'emoji-picker-react';

const EmojiPickerContainer = styled.div`
  position: fixed;
  z-index: 100;

  .emoji-search {
    height: 31px;
    font-size: 14px
  }
`

export const EmojiPicker = ({ onEmojiChange, onOutsideClick, preload, disableSkinTonePicker }) => {
  const handleOutsideClick = (e) => {
    if (!document.getElementById('emojiPicker')?.contains(e.target)) {
      onOutsideClick();
    }
  }
  
  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  return (
    <EmojiPickerContainer id='emojiPicker'>
      <Picker
        preload={preload}
        disableSkinTonePicker={disableSkinTonePicker}
        onEmojiClick={(event, emojiObject) => onEmojiChange(emojiObject)}
      />
    </EmojiPickerContainer>
  );
};

EmojiPicker.propTypes = {
  onEmojiChange: PropTypes.func,
  onOutsideClick: PropTypes.func,
  disableSkinTonePicker: PropTypes.bool,
  preload: PropTypes.bool
};

EmojiPicker.defaultProps = {
  onEmojiChange: () => {},
  onOutsideClick: () => {},
  disableSkinTonePicker: true,
  preload: true
};