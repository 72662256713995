import { IconBan, IconCrown, IconSettings, IconShoppingCart, TablerIcon } from '@tabler/icons'
import { UtilityModal, ModalBody } from '../components'

interface DataType {
  pagesLimit: number
  autoresponderLimit: number
  formsLimit: number
  journeyId: number
}

enum ERROR_TYPES {
  dripCampaignsDisabled = 'dripCampaignsDisabled',
  landingPagesDisabled = 'landingPagesDisabled',
  landingPagesLimitExceeded = 'landingPagesLimitExceeded',
  landingPagesAbTestDisabled = 'landingPagesAbTestDisabled',
  broadcastsDisabled = 'broadcastsDisabled',
  emailsDisabled = 'emailsDisabled',
  autorespondersLimitExceeded = 'autorespondersLimitExceeded',
  formsLimitExceeded = 'formsLimitExceeded',
  emailOwnershipDoesNotExist = 'emailOwnershipDoesNotExist',
  journeysLimitExceeded = 'journeysLimitExceeded',
  automationDisabled = 'automationDisabled',
  widgetsAbTestDisabled = 'widgetsAbTestDisabled'
}

type actions = "danger" | "default" | "primary" | "success" | "warning";

export interface ErrorTypeReturn {
  buttons: {
    label: string;
    reactIntlId: string
    action: string;
    onClick: () => void;
  }[];
  action: actions
  icon: TablerIcon;
  title: string;
  description: string;
  reactIntlTitleId?: string;
  reactIntlDescriptionId?: string;
}

export const ErrorModal = (alertConfig: ErrorTypeReturn) => 
  UtilityModal({
    id:"error-modal",
    body: props => 
      <ModalBody 
      {...props} 
      {...alertConfig} 
      icon={<alertConfig.icon size="20px" />} 
      />
})


const ErrorType = ({ type , data, history, siteId }:{ type: ERROR_TYPES, data?: DataType, history?: any, siteId?: number }): ErrorTypeReturn => {

  const upgradeAlert = {
    action: "primary" as actions,
    buttons: [
      { label: 'Upgrade', action: 'primary', onClick: () => window?.pricing?.show(), reactIntlId: "global.button.upgrade" },
    ],
    icon: IconCrown,
  }

  const contactAlert = {
    description: 'Your account may have been flagged. Please contact support to resolve this.',
    reactIntlDescriptionId: "errorType.contactAlert.description",
    action: "danger" as actions,
    icon: IconBan,
    buttons: [
      { label: 'Contact Support', action: 'primary', onClick: () => window.location.href = '/contact', reactIntlId: "errorType.contactSupport" },
    ]
  }

  switch(type) {
    case ERROR_TYPES.dripCampaignsDisabled:
      return {
        title: 'Drip Campaigns',
        reactIntlTitleId: "errorType.dripCampaigns.title",
        description: `Drip Campaigns are a premium feature. Please upgrade your account.`,
        reactIntlDescriptionId: "errorType.dripCampaigns.description",
        ...upgradeAlert,
        buttons: [
          { label: 'Upgrade', action: 'primary', onClick: () => window?.pricing?.show({ product: 'emails', minimumPlanLevelRequired: 2, highlightFeatures: 'drip_campaigns' }), reactIntlId: "global.button.upgrade" },
        ],
      }
    case ERROR_TYPES.landingPagesDisabled:
      return {
        title: `Your landing pages have been disabled.`,
        reactIntlTitleId: "errorType.landingPagesDisabled.title",
        ...contactAlert
      }
    case ERROR_TYPES.landingPagesLimitExceeded:
      const { pagesLimit } = data || {}
      return {
        title: `Landing page limit exceeded.`,
        reactIntlTitleId: "errorType.landingPagesLimitExceeded.title",
        description: `You can only create ${pagesLimit} landing ${pagesLimit && pagesLimit > 1 ? "pages" : "page"} in your current plan. Please upgrade for more landing pages.`,
        reactIntlDescriptionId: "errorType.landingPagesLimitExceeded.description",
        ...upgradeAlert
      }
    case ERROR_TYPES.landingPagesAbTestDisabled:
      return {
        title: `A/B Tests`,
        reactIntlTitleId: "errorType.landingPagesAbTestDisabled.title",
        description: `Landing Page Split tests are a premium feature. Please upgrade your account.`,
        reactIntlDescriptionId: "errorType.landingPagesAbTestDisabled.description",
        ...upgradeAlert,
        buttons: [
          { label: 'Upgrade', action: 'primary', onClick: () => window?.pricing?.show({minimumPlanLevelRequired: 2, highlightFeatures: 'ab_testing'}), reactIntlId: "global.button.upgrade" },
        ],
      }
    case ERROR_TYPES.broadcastsDisabled:
      return {
        title: `Your broadcast emails have been disabled.`,
        reactIntlTitleId: "errorType.broadcastsDisabled.title",
        ...contactAlert
      }
    case ERROR_TYPES.emailsDisabled:
      return {
        title: `Your emails have been disabled.`,
        reactIntlTitleId: "errorType.emailsDisabled.title",
        ...contactAlert
      }
    case ERROR_TYPES.autorespondersLimitExceeded:
      const { autoresponderLimit } = data || {}
      return {
        title: `Autoresponders limit exceeded.`,
        reactIntlTitleId: "errorType.autorespondersLimitExceeded.title",
        description: `You can only create ${autoresponderLimit} ${autoresponderLimit && autoresponderLimit > 1 ? "autoresponders" : "autoresponder"} in your current plan. Please upgrade for more autoresponders.`,
        reactIntlDescriptionId: "errorType.autorespondersLimitExceeded.description",
        ...upgradeAlert
      }
    case ERROR_TYPES.formsLimitExceeded:
      const { formsLimit } = data || {}
      return {
        title: `Popup forms limit exceeded.`,
        reactIntlTitleId: "errorType.formsLimitExceeded.title",
        description: `You can only create ${formsLimit} ${formsLimit && formsLimit > 1 ? "forms" : "form"} in your current plan. Please upgrade for more forms.`,
        reactIntlDescriptionId: "errorType.formsLimitExceeded.description",
        ...upgradeAlert
      }
    case ERROR_TYPES.emailOwnershipDoesNotExist:
      return {
        title: `Sender email address`,
        reactIntlTitleId: "errorType.emailOwnershipDoesNotExist.title",
        action: "primary",
        description: `A verified sender email address is required to start this flow. Would you like to add one now?`,
        reactIntlDescriptionId: "errorType.emailOwnershipDoesNotExist.description",
        buttons: [
          { label: 'Open Settings', action: 'primary', onClick: () => history?.replace({ pathname: `/sites/${siteId}/settings/domains-and-emails` }), reactIntlId: "errorType.openSettings" },
        ],
        icon: IconSettings,
      }
    case ERROR_TYPES.journeysLimitExceeded: 
      const { journeyId } = data || {}
      return {
        title: `Flow already exists`,
        reactIntlTitleId: "errorType.journeysLimitExceeded.title",
        action: "primary",
        description: `You already have a cart abandonment flow in this site. Would you like to open it?`,
        reactIntlDescriptionId: "errorType.journeysLimitExceeded.description",
        buttons: [
          { label: 'Open Flow', action: 'primary', onClick: () => window.location.href = `journeys/${journeyId && journeyId}`, reactIntlId: "errorType.openFlow" },
        ],
        icon: IconShoppingCart,
      }
    case ERROR_TYPES.automationDisabled:
      return {
        title: `Automations`,
        reactIntlTitleId: "errorType.automationDisabled.title",
        description: `Automations are a premium feature. Please upgrade your account.`,
        reactIntlDescriptionId: "errorType.automationDisabled.description",
        ...upgradeAlert,
        buttons: [
          { label: 'Upgrade', action: 'primary', onClick: () => window?.pricing?.show({ product: "emails", minimumPlanLevelRequired: 2, highlightFeatures: 'automation'}), reactIntlId: "global.button.upgrade" },
        ],
      }
    case ERROR_TYPES.widgetsAbTestDisabled:
      return {
        title: `A/B Tests`,
        reactIntlTitleId: "errorType.widgetsAbTestDisabled.title",
        description: `Form Split tests are a premium feature. Please upgrade your account.`,
        reactIntlDescriptionId: "errorType.widgetsAbTestDisabled.description",
        ...upgradeAlert,
        buttons: [
          { label: 'Upgrade', action: 'primary', onClick: () => window?.pricing?.show({minimumPlanLevelRequired: 2, highlightFeatures: 'ab_testing'}), reactIntlId: "global.button.upgrade" },
        ],
      }
  }
}

export { ErrorType }