
import { ColorPicker, RadioPicker, InputSelect, Input, Toggle, RadioPosition, Checkbox, SpacingInput, Tag, MediaLibraryUpload } from '../../components'
import DatePicker from "react-datepicker"
import { useIntl } from "react-intl"
import 'react-datepicker/dist/react-datepicker.css'
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/css/css'
import 'codemirror/addon/display/placeholder'
import 'codemirror/addon/display/autorefresh'
import { resolve } from '../../utils'
import ProIcon from '../../assets/svg/pro'
import HelperCard from '../HelperCard'
import { FormattedMessage } from 'react-intl'

const convertToSlug = value => value.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

const getInput = (type, props) => {
  const { isMulti, Component, slugify = false, intl, builderStep, stepSection, reactIntlId } = props
  switch(type) {
    case 'checkbox':
      const checkBoxProps = {
        ...props,
        checked: props?.value,
        onClick: () => props?.onChange(!props?.value)
      }
      return <Checkbox {...checkBoxProps} />
    case 'datepicker':
      const { value, dataAttribute, placeholderText, reactIntlPlaceholderId, ...rest } = props
      const datePickerProps = {
        ...rest,
        selected: props?.value === null ? null : new Date(props?.value)?.toString() === 'Invalid Date' ? new Date() : new Date(props?.value),
        onChange: date => props?.onChange(date),
        autoComplete: 'off',
        className: `w-full p-[5px] border-[1px] rounded-[5px] font-normal text-sm text-gray-900 bg-gray-100 ${props?.error && 'border-danger'}`,
        placeholderText: reactIntlPlaceholderId ? intl.formatMessage({ id: reactIntlPlaceholderId, defaultMessage: placeholderText }) : placeholderText
      }
      return <div {...(dataAttribute && {'data-attribute': `date-picker-${dataAttribute}`})}> <DatePicker {...datePickerProps} /> { props?.error && <div className="text-[10px] mt-[4px] text-danger">{props?.error}</div> } </div>
    case 'colorPicker':
      return <ColorPicker {...props} />
    case 'radio':
      return <RadioPicker {...props} />
    case 'radioPosition':
      return <RadioPosition {...props} />
    case 'select':
      const inputSelectProps = {
        ...props,
        value: isMulti ? props?.options?.filter(item => props?.value.includes(item.value)) : props?.options?.find(item => item.value === props?.value),
        onChange: (value) => isMulti ? props?.onChange(Array.isArray(value) ? value?.map(item => item?.value) : [value]?.map(item => item?.value)) : props?.onChange(value?.value)
      }
      return <InputSelect {...inputSelectProps} />
    case 'spacingInput':
      return <SpacingInput {...props} />
    case 'toggle':
      return <Toggle {...props} />
    case 'code':
      const codeMirrorProps = {
        ...props,
        //TODO move to state to prevent multiple calls
        onBeforeChange: (editor, data, value) => props?.onChange(value),
        onChange: (editor, data, value) => props?.onChange(value)
      }
      return <CodeMirror {...codeMirrorProps} />
    case 'custom':
      return Component ? <Component {...props} /> : <div>no input type found</div>
    case 'uploadMediaLibrary':
      return <MediaLibraryUpload {...props}/>
    default:
      const inputProps = {
        ...props,
        onChange: (e) => props?.onChange(slugify ? convertToSlug(e.target.value) : e.target.value)
      }
      return <Input {...inputProps} />
  }
}

const Section = (props) => {
  const { component, icon, heading, helpText, subHeading, input, className, subSections = [], subClassName, values, onChange, isPremium, helper, reactIntlId, builderStep, stepSection, intlValues = {}, reactIntlDescId } = props
  const intl = useIntl()

  const value = resolve(input?.name, values)
  const inputProps = {
    value,
    onChange: (value) => ((input?.name === 'delayInterval' ) && (value < 1)) ? null : onChange(input?.name,value),
    intl,
    builderStep, 
    stepSection,
    ...input
  }

  const renderInput = getInput(input?.type, inputProps, intl)

  return (
    <div className={ className || `flex flex-col`}>
      <div className="flex">
      {
        icon && <div className="mr-[8px]">{icon()}</div>
      }
      {
        (heading || subHeading) &&
        <div className="flex flex-col">
        {
          <div className="flex items-center text-sm mb-[2px]">
          {
            heading && <div className="font-medium text-secondary">
              {heading && typeof heading !== 'string'? heading:  reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={heading} /> : heading }
            </div>
          }
          {
            helper && Object.keys(helper).length > 0 && window.isMailmunch && <HelperCard {...helper} />
          }
          {
            isPremium && <Tag className="ml-[8px]" action={'danger'} icon={<ProIcon className="mr-[10px]" />} reactIntlId='global.tag.pro' label={'Pro'} /> 
          }
          </div>
        }
        {
          subHeading &&
          <div className={`font-normal text-gray-900 text-xs ${className?.includes('flex-row') ? '' : 'mb-[12px]'} leading-[14px]`}>
            { 
            typeof subHeading !== 'string' && subHeading ? subHeading :
            reactIntlDescId ? <FormattedMessage values={intlValues} id={reactIntlDescId} defaultMessage={subHeading} /> : subHeading }
          </div>
        }
        </div>
      }
      </div>
      {
        input && renderInput
      }
      {
        component
      }
      {
        helpText && 
        <div className="font-normal text-gray-800 text-xs mt-[8px] break-all">{helpText}</div>
      }
      {
        subSections?.length > 0 &&
        <div className={subClassName}>
        {
          subSections.map((item, index) => {
            const sectionProps = {
              values,
              onChange,
              builderStep,
              stepSection,
              ...item
            }

            return (
              <Section key={index} {...sectionProps} />
            )
          })
        }
        </div>
      }
    </div>
  )
}

export { Section, getInput }