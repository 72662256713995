import { openPopupWidget } from 'react-calendly'
import { Automations, Emails, Forms, GetToKnow, LandingPages } from '../assets/svg'
import NeedHelp from '../assets/images/NeedHelp.jpg'

export const introData = [
  {
    Image: GetToKnow,
    heading: `Let's get to know Mailmunch`,
    text: <div>You can use Mailmunch to quickly build your email list and engage with your contacts.</div>
  },
  {
    Image: Forms,
    heading: 'Popup Forms',
    text: 
      <div>
        Quickly start converting your website visitors to contacts using popup forms. 
        Get started with a library of <span className='font-medium text-secondary'>over 100 templates</span> designed to kickstart conversions.
      </div>
  },
  {
    Image: Emails,
    heading: 'Welcome Emails',
    text: 
      <div>
        Send automated emails to welcome new contacts as soon as they subscribe. 
        Deliver your <span className='font-medium text-secondary'>lead magnets </span> 
        and make them feel at home with your brand with a <span className='font-medium text-secondary'>custom onboarding experience.</span>
      </div>
  },
  {
    Image: Automations,
    heading: 'Automations',
    text: 
      <div>
        Create email flows that trigger automatically based on <span className='font-medium text-secondary'>user behavior</span> on your website. 
        Tailor your message for each contact and <span className='font-medium text-secondary'>supercharge conversions</span> 🚀
      </div>
  },
  {
    Action: () =>
      <div className='pt-[8px]'>
        {window.partnerLinks?.email_marketing && <a className="text-primary" rel="noreferrer" href={window.partnerLinks?.email_marketing} target="_blank">Discover more 🔎</a>}
      </div>,
    Image: LandingPages,
    heading: 'Landing Pages & much more',
    text: 
      <div>
        Design and publish <span className='font-medium text-secondary'>fully responsive </span> 
        landing pages in minutes. Or create a series of <span className='font-medium text-secondary'>drip emails</span> to welcome new contacts.
      </div>
  },
  {
    Action: ({ onClick }) => 
      <div className='pt-[8px] text-primary cursor-pointer' onClick={() => {
        onClick()
        openPopupWidget({ url: `https://calendly.com/mailmunch/hire-an-expert`})
      }}>
        Book a call 🗓
      </div>,
    Image: () => <img className="block" src={NeedHelp} alt="need help" />,
    heading: 'Need help getting started?',
    text: 
      <div>
        No problem! Get in touch with our <span className='font-medium text-secondary'>top rated </span> 
        support team and they will help you get set up.
      </div>

  }
]