import { Button } from "../.."
import { IconEdit, IconTrash } from "@tabler/icons"
import { FormattedMessage } from 'react-intl';
const ListIntegrations = (props) => {
  const { connectedIntegrations = [], deleteConnectedIntegration, handleUpdate } = props

  const handleDelete = ({ id }) => {
    deleteConnectedIntegration({
      variables: { id },
      update: (cache, { data }) => {
        const { deleteConnectedIntegration: { success } = {}} = data
        if(success) {
          cache.evict({
            id: cache.identify({
              __typename: 'ConnectedIntegration',
              id,
            }),
          });
        }
      }
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex mb-[8px] justify-between">
        <div><FormattedMessage id={`integrations.list.title`} defaultMessage="Integration" /></div>
        <div><FormattedMessage id={`integrations.list.account.title`} defaultMessage="Account" /></div>
        <div><FormattedMessage id={`integrations.list.actions.title`} defaultMessage="Actions" /></div>
      </div>
      {
        connectedIntegrations?.length > 0 &&
        <div className="px-[12px] py-[16px] border-[1px] border-gray-200">
          {
            connectedIntegrations.map(item => {
              const { id, integration } = item
              const { accountIdentifier } = integration || {}
              return (
                <div className="flex items-center justify-between">
                  <div className="max-w-[150px]"><img src={require(`./logos/${integration.name.toLowerCase()}.png`)?.default} /></div>
                  <div className="text-secondary break-all whitespace-nowrap overflow-hidden overflow-ellipsis font-sm2 w-max-[116px]">{accountIdentifier}</div>
                  <div className="flex">
                    <div onClick={() => handleUpdate({ integrationId: id, step: 'edit' })}>
                      <IconEdit className="mr-[8px] cursor-pointer" />
                    </div>
                    <div onClick={() => handleDelete({ id })}>
                      <IconTrash className="mr-[8px] cursor-pointer" />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      <Button label="ADD NEW INTEGRATION" className="mt-[16px]" onClick={() => handleUpdate({ step: 'new' })} />
    </div>
  )
}

export default ListIntegrations