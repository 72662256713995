import { IconMail } from "@tabler/icons"
import { useState } from "react"
import { Button, ModalBody, ToastNotification, UtilityModal } from "../../../../../components"
import { ReactComponent as ImpSvg } from '../../../assets/importantInfo.svg'
import CreatableSelect from 'react-select/creatable';
import { validateEmail } from "../../../../../utils";


export const CreateNewInvite = (props) => {
  const {values = {}, setValues = () => {}} = props;
  const [invitations, setInvitations] = useState([])
  const [inputChange, setInputChange] = useState('')
  const [loading, setLoading] = useState(false)
  return (
    <ModalBody
      {...props}
      title={`Invite Engineers`}
      action="primary"
      icon={<IconMail/>}
      handleClose={props.handleClose}
      closeOnEscape={false}
      closeIcon={false}
      cancelButton={false}
      buttons={[
        {
          label: "Send Invite",
          action: "primary",
          type: "default",
          disabled: loading || invitations.length === 0,
          action: "primary",
          onClick: async () => {
            setLoading(true)
            let emails_list = invitations.map(({ value }) => value)
            const response = await fetch(`//${window.appDomain}/emails/send_email_to_list?token=${window.token}`, {
              method: "POST",
              body: JSON.stringify({
                emails_list,
              }),
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }
            })
            
            const data = await response.json()
            if (data.status !== 'fail') {
              ToastNotification({
                id: new Date(),
                icon: "check",
                color: "success",
                body: "Invitations sent successfully",
              })
              !(Object.keys(values).length === 0) && setValues({...values, steps: {...values.steps, invitationCompleted: true}, goToNextStep: 'apiKeys', update: true})
            }else{
              setLoading(false)
              ToastNotification({
                id: new Date(),
                icon: "alert",
                color: "danger",
                body: "Something went wrong, please try again later.",
              })
            }
          },
        },
      ]}
    >
      <div className="text-[15px] text-[#8798AD] mb-[24px]">Enter email addresses of your engineering team</div>
      <CreatableSelect
      id="engineersinvites"
      isMulti
      isCreateable
      isClearable={false}
      isDisabled={loading}
      escapeClearsValue={false}
      value={invitations}
      formatCreateLabel={userInput => `Add "${userInput}"`}
      noOptionsMessage={() => "Member is already added"}
      menuIsOpen={!validateEmail(inputChange) ? true : false}
      isValidNewOption={(option) => !validateEmail(option)}
      placeholder="Enter Email Address"
      onChange={(email) => { setInvitations(email) }}
      onInputChange={(value) => { setInputChange(value) }}
    />
    </ModalBody>
  )
}


const GetStarted = (props) => {
  const {values, setValues} = props;

  const events = [
    {
      title: 'Completes registration',
      event: 'sign-up:complete'
    },
    {
      title: 'Completes onboarding',
      event: 'onboarding:success'
    },
    {
      title: 'Upgrades subscription',
      event: 'pricing:upgrade'
    },
  ]

  return (
    <div className="w-[545px] relative">
      <div className={'text-[40px] leading-[40px] text-[#2E384D] mb-[8px]'}>Let’s help you get started.</div>
      <div className="text-[18px] leading-[28px] font-thin text-secondary-light mb-[33px]">This is a standard set of events that most SaaS products like yours choose to start with.</div>
      <div className="bg-[#F9F9FD] p-[16px] border rounded-lg border-primary mb-[24px] font-light">You may need to involve your engineering team for this step. If you like, we can send them some helpful links to get them started.</div>
        <div className="font-medium leading-[32px]">When a user</div>
        {
          events.map(({title, event}, index) => {
            return (
              <div key={index} className="bg-[#F9F9FD] p-[16px] border rounded-lg flex justify-between mb-[3px] text-[16px]">
                <div className="text-[#2E384D] font-light">{title}</div>
                <div className="text-[#8990A0] font-mono">{event}</div>
              </div>
            )
          })
        }
        <div className="flex items-center uppercase mt-[40px]">
          <Button
            height={'45px'}
            width={'180px'}
            disabled={values.btnLoading}
            className="mr-[24px] bg-primary"
            label={'Yes, Send Please'}
            onClick={() => {
              UtilityModal({
              body: (props) => <CreateNewInvite {...props} values={values} setValues={setValues} />,
            })
            }}
          />
          <button disabled={values.btnLoading} className="text-[#8990A0]" onClick={()=> UtilityModal({ height: '526px', width: '800px', body: (bodyProps) => (<ImportantInfo props={bodyProps} values={values} setValues={setValues}/>) }) }>No, Thank You</button>
        </div>
    </div>
  )
}

const ImportantInfo = ({props, values, setValues}) => {
  return (
    <div className="flex flex-col justify-center items-center w-[530px] text-center mx-auto">
      <ImpSvg className="text-primary"/>
      <div className="text-[32px] leading-[38px] font-medium my-[25px]">Setup Pending</div>
      <div className="text-secondary-light text-[18px] leading-[21px] font-thin">Please note that you will not be able to start sending emails until your events have been setup.</div>
      <div className="flex mt-[40px]">
        <Button
          label={'Send Later'}
          isUppercase={false}
          action="transparent"
          className={'text-[#8990A0]'}
          disabled={values.btnLoading}
          onClick={() => {
            props.handleClose()
            setValues({...values, btnLoading: true, steps: {...values.steps, invitationCompleted: true}, goToNextStep: 'apiKeys', update: true})
          }}
          />
        <Button
          label={'Send Now'}
          onClick={() => props.handleClose()}
        />
      </div>
    </div>
  )
}

export default GetStarted