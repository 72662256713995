import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import Creatable from 'react-select/creatable';
import { FormattedMessage, useIntl } from "react-intl";
import { formatDataAttribute } from "../../utils";

const Option = (props) => {
  const dataAttribute = formatDataAttribute(props?.data?.value);
  return <components.Option {...props} innerProps={{ ...props.innerProps, 'data-attribute': `option-${dataAttribute}` }}>
  {props.children}
</components.Option>
}

export const InputSelect = ({
  label,
  placeholder,
  isMulti,
  isCreateable,
  onCreateOption,
  assistiveText,
  options,
  onChange,
  className,
  error,
  value,
  searchable,
  defaultValue,
  menuPlacement,
  menuPosition,
  size,
  fontSize,
  isDisabled,
  isLoading,
  globalClassName,
  dataAttribute,
  errorDataAttribute,
  height,
  onInputChange,
  menuIsOpen,
  isValidNewOption,
  components,
  reactIntlId,
  reactIntlPlaceholderId,
  borderRadius,
  indicatorsStyle,
  borderColor,
  groupClass,
  setValueByOption,
  noOptionsMessage,
  formatCreateLabel,
}) => {
  const isSmallDropDown = size === "small" || false;
  const intl = useIntl()
  const styles = {
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "#FFF",
      color: "#9ca3af",
      fontSize,
      value: "32px",
      borderRadius: borderRadius,
      ...(!isMulti && {
        height: isSmallDropDown ? "20px" : "100%"
      }),
      ...(height && {
        padding: "0 16px"
      })
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: error ? "red" : borderColor ,
      minHeight: isSmallDropDown ? "20px" : "32px",
      backgroundColor: "#eff0f6",
      color: "#9ca3af",
      fontSize: "14px",
      ...(!isMulti && { height: height || '32px' }),
      "&:hover": {
        borderColor: "#eff0f6",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize,
    }),
    menu: (provided, state) => ({
      ...provided,
      fontSize,
      width: isSmallDropDown ? "190px" : "auto",
      wordBreak:"break-word",
      minWidth: "100%",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#9ca3af",
      fontSize,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
      borderRadius:borderRadius
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "#eff0f6",
      borderRadius:borderRadius,
      height: height ? '40px' : (isSmallDropDown ? "20px" : "30px"),
      ...indicatorsStyle
    }),
  };

  const selectProps = {
    menuPosition,
    isDisabled,
    menuPlacement,
    styles,
    defaultValue,
    isMulti,
    options,
    className,
    placeholder: reactIntlPlaceholderId ? intl.formatMessage({ id: reactIntlPlaceholderId, defaultMessage: placeholder }) : placeholder,
    onChange,
    value,
    isSearchable: searchable,
    onCreateOption: (value) => { onChange({ value, label: value }); onCreateOption(value);},
    isLoading,
    onInputChange,
    menuIsOpen,
    isValidNewOption,
    components,
    theme:(theme) => ({
    ...theme,
    colors: {
    ...theme.colors,
      text: 'white',
      primary25: 'var(--color-primary-lighter)',
      primary75: 'var(--color-primary-lighter)',
      primary50: 'var(--color-primary-lighter)',
      primary: 'var(--color-primary)',
      neutral80: 'black',
      color: 'black',
    },
  }),
  ...(formatCreateLabel ? { formatCreateLabel } : {}),
  noOptionsMessage: noOptionsMessage || (() => "Options is empty"),
  }

  const selectedValue = React.useMemo(() => {
    if (!setValueByOption) return value;
    if (!options || !value) return null;
  
    const findValue = (opts) => {
      for (const option of opts) {
        if (Array.isArray(option)) {
          const found = findValue(option);
          if (found) return found;
        } else if (option.options) {
          const found = findValue(option.options);
          if (found) return found;
        } else if (value == option.value) {
          return option;
        }
      }
      return null;
    };
    return Array.isArray(value) ? value.map(v => options.find(o => o.value === v)) : (findValue(options) || value)
  }, [value, options]);

  return (
    <div className={groupClass}>
      {
        label && <div className="mb-[4px] text-sm text-gray-900 leading-[14.22px]">
          {
            reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={label}/> : label
          }
        </div>
      }
      
      <div {...(dataAttribute && { "data-attribute": `select-${dataAttribute}` })}>
        {
          isCreateable ? 
          <Creatable {...selectProps}/> :
          <Select {...selectProps} value={selectedValue}  defaultValue={selectedValue} components={{ Option }}/>
        }
      </div>

      {error && <div {...errorDataAttribute && {'data-attribute': `select-error-${errorDataAttribute}` } } className="text-[10px] mt-[4px] text-danger">{error}</div>}
      {assistiveText && (
        <div className="text-[10px] mt-[4px] text-secondary">
          {assistiveText}
        </div>
      )}
    </div>
  );
};

InputSelect.propTypes = {
  fontSize: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  formatCreateLabel: PropTypes.element | PropTypes.func,
  noOptionsMessage: PropTypes.element | PropTypes.func,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  assistiveText: PropTypes.string,
  globalClassName: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  error: PropTypes.string | PropTypes.element,
  size: PropTypes.string,
  searchable: PropTypes.bool,
  value:
    PropTypes.string | PropTypes.object | PropTypes.array | undefined | null,
  defaultValue:
    PropTypes.string | PropTypes.object | PropTypes.array | undefined | null,
  menuPosition: PropTypes.string,
  dataAttribute: PropTypes.string,
  errorDataAttribute: PropTypes.string,
  height: PropTypes.string,
  onInputChange: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  components: PropTypes.object,
  reactIntlId: PropTypes.string,
  reactIntlPlaceholderId: PropTypes.string,
  setValueByOption: PropTypes.bool
};

InputSelect.defaultProps = {
  fontSize: '14px',
  isDisabled: false,
  menuPlacement: "bottom",
  label: "",
  placeholder: "",
  isMulti: false,
  isCreateable: false,
  isLoading: false,
  className: "",
  assistiveText: "",
  globalClassName: "",
  onCreateOption: null,
  indicatorsStyle : {},
  options: [
    {
      label: "Option 1",
      value: "option-1",
    },
    {
      label: "Option 2",
      value: "option-2",
    },
    {
      label: "Option 3",
      value: "option-3",
    },
  ],
  error: "",
  // value: "",
  size: "default",
  searchable: true,
  onChange: (value) => console.log(value),
  menuPosition: "",
  height: "",
  reactIntlId: "",
  reactIntlPlaceholderId: "",
  borderRadius:  "4px 0px 0px 4px",
  borderColor: "#CBD4E6",
  groupClass:"",
  setValueByOption: false,
  formatCreateLabel: null
};
