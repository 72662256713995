import gql from 'graphql-tag'
import { LIST_FRAGMENT } from '../fragments'

const CREATE_LIST = gql`
${LIST_FRAGMENT}
  mutation createList($siteId: Int!, $name: String!, $doubleOptin: Boolean, $doubleOptinMessage: String, $locale: String, $type: String) {
    createList(input: { siteId: $siteId, name: $name, doubleOptin: $doubleOptin, doubleOptinMessage: $doubleOptinMessage, locale: $locale, type: $type }){
      success
      error
      list {
        ...list
      }
    }
  }
`;

const DELETE_LIST_AND_ASSIGN_NEW_LIST = gql`
  mutation deleteListAndAssignNewList($siteId: Int!, $listId: Int!, $newListId: Int!) {
    deleteListAndAssignNewList(input: { siteId: $siteId, listId: $listId, newListId: $newListId }){
      success
      error
    }
  }
`;

const UPDATE_LIST = gql`
${LIST_FRAGMENT}
  mutation updateList($id: Int!, $name: String, $doubleOptin: Boolean, $doubleOptinMessage: String, $locale: String, $type: String) {
    updateList(input: { id: $id, name: $name, doubleOptin: $doubleOptin, doubleOptinMessage: $doubleOptinMessage, locale: $locale, type: $type }){
      success
      error
      list {
        ...list
      }
    }
  }
`;

export { CREATE_LIST, DELETE_LIST_AND_ASSIGN_NEW_LIST, UPDATE_LIST } 