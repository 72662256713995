// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const PieGraph: React.FC<any> = ({ data /* see data tab */ }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.8}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        colors={['#001BCB','#0057FF','#85ADFF','#E6EEFF', ]}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        enableArcLabels={false}
        arcLabelsTextColor="#ffffff"
        // defs={[
        //     {
        //         id: 'dots',
        //         type: 'patternDots',
        //         background: 'inherit',
        //         color: 'rgba(255, 255, 255, 0.3)',
        //         size: 4,
        //         padding: 1,
        //         stagger: true
        //     },
        //     {
        //         id: 'lines',
        //         type: 'patternLines',
        //         background: 'inherit',
        //         color: 'rgba(255, 255, 255, 0.3)',
        //         rotation: -45,
        //         lineWidth: 6,
        //         spacing: 10
        //     }
        // ]}
        // fill={[
        //     {
        //         match: {
        //             id: 'ruby'
        //         },
        //         id: 'dots'
        //     },
        //     {
        //         match: {
        //             id: 'python'
        //         },
        //         id: 'dots'
        //     },
        //     {
        //         match: {
        //             id: 'lisp'
        //         },
        //         id: 'lines'
        //     },
        //     {
        //         match: {
        //             id: 'javascript'
        //         },
        //         id: 'lines'
        //     }
        // ]}
        legends={[
          {
              anchor: 'top-right',
              direction: 'row',
              justify: false,
              translateX: 90,
              translateY: -50,
              itemWidth: 78,
              itemHeight: 35,
              itemsSpacing: 2,
              symbolSize: 17,
              itemDirection: 'left-to-right'
          }
      ]}
    />
)


PieGraph.defaultProps = {
  data: [
    {
      "id": "stylus",
      "label": "stylus",
      "value": 269,
      "color": "hsl(250, 70%, 50%)"
    },
    {
      "id": "rust",
      "label": "rust",
      "value": 133,
      "color": "hsl(221, 70%, 50%)"
    },
    {
      "id": "css",
      "label": "css",
      "value": 271,
      "color": "hsl(106, 70%, 50%)"
    },
  ]
}