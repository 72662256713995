import React, { useRef, useState, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export interface TooltipProps {
  label: string
  action?: string
  top?: string
  left?: string
  right?: string
  fixed?: boolean
  position?: 'top' | 'left' | 'right'
  dataAttribute?: string
  reactIntlId?: string
  className?: string
}

interface stylesInterfece {
  [key:string]: {
    [key:string]:string
  }
}

export const Tooltip: React.FC<TooltipProps> = ({ children, label, action = "default", top, left, right, fixed, position='top', dataAttribute, reactIntlId, className }) => {

  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const [tooltipLeft, setTooltipLeft] = useState<string | null>(null)
  const [positionArrow, setPositionArrow] = useState<string | null>(null)

  useLayoutEffect(() => {
    if(fixed && tooltipRef.current){
        const { right } = tooltipRef.current?.getBoundingClientRect()
        if(right  >= document.body.offsetWidth){
          setTooltipLeft(`-${(right - document.body.offsetWidth).toFixed()}px`)
          setPositionArrow('right-[17px]')
        }
    }
  }, [fixed])
 
  const bodyStyles: stylesInterfece  = {
    top: {
      default: `text-white bg-black -translate-x-1/2 -translate-y-full ${left ? left : 'left-2/4'} ${top ? top : '-top-3'}`,
      danger: `text-danger bg-danger-light -translate-x-1/2 -translate-y-full ${left ? left : 'left-2/4'} ${top ? top : '-top-3'}` 
    },
    left: {
      default: `text-white bg-black -translate-x-9 ${left ? left : 'right-4'} ${top ? top : '-top-2'}`,
      danger: `text-danger bg-danger-light -translate-x-9 ${left ? left : 'right-4'} ${top ? top : '-top-2'}`
    },
    right: {
      default: `text-white bg-black  ${left ? left : 'left-[110%]'} ${top ? top : '-top-2'}`,
      danger: `text-danger bg-danger-light ${left ? left : 'left-[110%]'} ${top ? top : '-top-2'}`
    }
  }

  const arrowStyles: stylesInterfece = {
    top: {
      default: `after:bg-black ${positionArrow ? positionArrow : 'left-2/4'} after:-translate-x-2/4 top-full`,
      danger: `after:bg-danger-light ${positionArrow ? positionArrow : 'left-2/4'} after:-translate-x-2/4 top-full`
    },
    left: {
      default: `after:bg-black top-4 right-[-18px] -rotate-90 `,
      danger: `after:bg-danger-light top-4 right-[-18px] -rotate-90 ` 
    },
    right: {
      default: `after:bg-black top-[10px] left-[-6px] rotate-90 `,
      danger: `after:bg-danger-light top-4 right-[-18px] -rotate-90 ` 
    }
  }

  return (
    <div className={`relative text-left ${!fixed && 'group'}`}>
      {children}      
      <div 
        ref={tooltipRef}
        {...(dataAttribute && {"data-attribute": `tooltip-${dataAttribute}`})}
        {...(fixed && tooltipLeft && { style: { left: tooltipLeft } })}
        className={`min-w-[80px] flex items-center h-9 text-center ${!fixed ? 'hidden group-hover:block' : 'block'} rounded w-max py-2 px-4 z-50 ${!label ? 'bg-transparent' : bodyStyles[position][action]} font-normal text-sm absolute whitespace-no-wrap ${className}`}
      >
        {
          reactIntlId ?
          <FormattedMessage id={reactIntlId} defaultMessage={label} />
          :
          label
        }
        <i className={`absolute -ml-3 w-6 h-3 overflow-hidden after:absolute after:w-[6px] after:h-[6px] ${!label ? 'bg-transparent' : arrowStyles[position][action]} after:left-2/4 after:transform after:rotate-45 after:-translate-y-2/4`}></i>
      </div>
    </div>
  )
}