
import gql from 'graphql-tag'
import { CONNECTED_INTEGRATION_FRAGMENT } from '../fragments'

const CREATE_CONNECTED_INTEGRATION = gql`
${CONNECTED_INTEGRATION_FRAGMENT}
  mutation CreateConnectIntegration($input: CreateConnectedIntegrationInput!) {
    createConnectedIntegration(input: $input) {
      success
      error
      connectedIntegration {
        ...connectedIntegration
      }
    }
  } 
`

const UPDATE_CONNECTED_INTEGRATION = gql`
  mutation UpdateConnectIntegration($input: UpdateConnectedIntegrationInput!) {
    updateConnectedIntegration(input: $input) {
      success
      error
      connectedIntegration {
        id
        type
        integrableType
        active
      }
    }
  } 
`

const DELETE_CONNECTED_INTEGRATION = gql`
  mutation DeleteConnectedIntegration($id: Int!) {
    deleteConnectedIntegration(input: { id: $id }) {
      success
      error
    }
  }
`

const UPDATE_INTEGRATION = gql`
  mutation updateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      success
      error
    }
  }
`

const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($input: DeleteIntegrationInput!){
    deleteIntegration(input: $input){
      success
      error
    }
  }
`

export { 
  CREATE_CONNECTED_INTEGRATION, 
  DELETE_CONNECTED_INTEGRATION, 
  UPDATE_INTEGRATION, 
  UPDATE_CONNECTED_INTEGRATION, 
  DELETE_INTEGRATION 
}
