import { IconEdit } from "@tabler/icons"
import { Link } from "react-router-dom"

const FixesContainer = (props) => {
  const { fixes } = props

  const iconProps = {
    size: 16,
    color: 'red'
  }

  return (
    <div className="p-4 mb-[12px] border border-danger bg-danger-light rounded-[8px] flex justify-between w-full">
      <div className="flex flex-col">
        <div className="text-sm text-danger mb-[8px] font-medium">Fields Required</div>
        <div className="text-sm font-normal text-secondary">You must provide the following missing details before you can publish:</div>
        <div className="my-[6px]">
          {
            fixes?.map((item,index) => {
              return (
                <div key={index} className="flex items-center">
                  {item?.icon && item?.icon()}
                  <div className="mr-[8px] font-medium text-danger text-sm">{item?.label}</div>
                  <Link to={`${item?.url}`} className="cursor-pointer">
                    <IconEdit {...iconProps}/>
                  </Link>
                </div>
              )
            })
          }
        </div>
        <div className="text-sm font-normal text-secondary">Please click on the edit icon to navigate to the relevant screens</div>
      </div>
    </div>
  )
}

export { FixesContainer }