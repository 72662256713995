import { FC, useRef ,useEffect, MouseEvent, ReactNode } from 'react'
import { render, unmountComponentAtNode } from "react-dom";
import { ApolloProvider } from '@apollo/client'
import client from '../../graphql/client'
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '../../utils';
import { DEFAULT_TRANSLATION } from '../../constants/defaultTranslation';

interface InjectedProps {
  handleClose: () => void
}


declare global {
  interface Window {
    phraseTranslation?: {[k:string]: string}
  }
}
export interface UtilityModalProps {
  width?: any
  height?: any
  closeOnEscape?: boolean
  closeOnClickOutside?: boolean
  body: (props: InjectedProps) => ReactNode
  zIndex?: number
  id?: any
  className?: string
  dataAttribute?: string
  style?: any
  modelStyle?: any
}

 const Modal: FC<UtilityModalProps> = ({
   closeOnEscape = true,
   closeOnClickOutside = true,
   width,
   height,
   body,
   id,
   zIndex = 1000000,
   className = '',
   dataAttribute = "",
   style = {},
   modelStyle = {},
  }) => {

  // for storybook
  // if (!document.getElementById("mm-utility-modal")) {
  //   const newNode = document.createElement('div')
  //   newNode.setAttribute('id', 'mm-utility-modal')
  //   const rootNode = document.querySelector('#root')
  //   rootNode && rootNode.after(newNode)
  // }

  const overlayEl = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    // remove alert components
    const target = document.getElementById(id)
    if (target) {
      unmountComponentAtNode(target)
      target?.parentNode?.removeChild(target)
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    const isKeyCodeEscape = e.keyCode === 27
    if (closeOnEscape && isKeyCodeEscape) {
      handleClose()
    }
  }

  const handleClickOverlay = (e: MouseEvent<HTMLDivElement>) => {
    const isClickOutside = e.target === (overlayEl && overlayEl.current)
    if (closeOnClickOutside && isClickOutside) {
      handleClose()
    }
  }

  useEffect(() => {
    // add listener on mount
    document.addEventListener('keydown', handleKeyPress, false)
    // remove event on unmount
    return () => document.removeEventListener('keydown', handleKeyPress, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return  (
    <div 
      style={{ zIndex, ...modelStyle }} 
      className={`fixed inset-0 flex justify-center items-center opacity-0 bg-opacity-60 bg-black animate-modalFadeIn ${className}`} 
      ref={overlayEl} 
      onClick={handleClickOverlay}
      {...(dataAttribute && {'data-attribute': `utility-modal-${dataAttribute}`})}
    >
      <div style={{ width, height, ...style }} className={`bg-white w-[500px] h-auto rounded-lg`}>
        {body && body({ handleClose })}
        </div>
    </div>
  )
}


export const UtilityModal = ({ id= "mm-utility-modal", ...props } : UtilityModalProps) => {

  let div = document.getElementById(id)
  if(!div){
    div = document.createElement('div')
    div.id = id
    document.body.appendChild(div)
  }
  render(
    <IntlProvider locale={DEFAULT_TRANSLATION} messages={flattenMessages(window?.phraseTranslation)}>
      <ApolloProvider client={client}>
        <Modal id={id} {...props} />
      </ApolloProvider> 
    </IntlProvider>
    ,
    div
    )
}