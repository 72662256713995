import { FC, useEffect } from 'react'
import { IconActivity, IconCoffee, IconCoin, IconFiles, IconUser, IconX, IconMailForward } from '@tabler/icons';
import { render, unmountComponentAtNode } from "react-dom";
import { openPopupWidget } from 'react-calendly'
import { ReactComponent as DifySvg } from './Dify.svg'
import ClientsPng from './clients.png'
import { Button } from '../Button'

const features = [
  { className: "text-[#666666] text-lg flex items-center mb-4", description: "Collect more leads" ,icon: IconUser  },
  { className: "text-[#666666] text-lg flex items-center mb-4", description: "Send emails that convert" ,icon: IconMailForward  },
  { className: "text-[#666666] text-lg flex items-center mb-4", description: "Increase your revenue" ,icon: IconCoin  },
  { className: "text-[#666666] text-lg flex items-center mb-4", description: "Design assets to match your brand" ,icon: IconFiles  },
  { className: "text-[#666666] text-lg flex items-center mb-4", description: "Constantly monitor performance" ,icon: IconActivity  },
  { className: "text-[#666666] text-lg flex items-center mb-4", description: "Meet regularly for (virtual) coffee" ,icon: IconCoffee  },
]

interface DifyModalProps {
  id?: string
}

const DifyModalBody: FC<{ id: string }> = ({id}) => {


  useEffect(() => {
    window?.track_mixpanel('dify:full-screen:opened');
  }, [])

  const handleClose = () => {
    // remove alert components
    const target = document.getElementById(id)
    if (target) {
      unmountComponentAtNode(target)
      target?.parentNode?.removeChild(target)
      window?.track_mixpanel('dify:full-screen:ignore');
    }
  }

  const openCalendly = () => {
    openPopupWidget({ url: "https://calendly.com/mailmunch/hire-an-expert" })
    handleClose && handleClose()
    window?.track_mixpanel(`dify:full-screen:book-call`);
  }

  return (
    <div className="fixed inset-0 z-50 h-full w-full opacity-0 bg-white animate-modalFadeIn overflow-y-scroll">
      <IconX className="cursor-pointer absolute right-3 top-3" onClick={handleClose} />
      <div className="flex flex-col justify-center h-full items-center p-8">
        <div className="text-[#0155ff] text-[28px] mb-8">Our marketing experts kick serious a**</div>
        <DifySvg className="mb-8" />
        <span className="text-[#666666] text-lg mb-5 text-center">Running a business is hard work. That is why our digital marketing experts are on <br /> standby to create and manage your campaigns for you.</span>
        <span className="text-[#666666] text-lg text-center">Our experts will take the time to study your brand and understand your marketing <br /> goals. Then they will create killer campaigns that work for you.</span>
        <div className="flex w-[600px] justify-between mt-6">
          <div className="flex flex-col">
            {features.slice(0,3).map(feature => <span key={feature.description} className={feature.className}><feature.icon size="20px" color="#0155ff" className="mr-2" /> {feature.description} </span>)}
          </div>
          <div className="flex flex-col">
            {features.slice(3,).map(feature => <span key={feature.description} className={feature.className}><feature.icon size="20px" color="#0155ff" className="mr-2" /> {feature.description} </span>)}
          </div>
        </div>
        <span className="text-[#666666] text-lg mt-2 mb-3">Brands just like yours trust our experts to run their marketing campaigns.</span>
        <img width="600px" height="38px" src={ClientsPng} alt="clients" />
        <Button label="Book a call with an expert" action="primary" height={38} className="mt-6" onClick={openCalendly} />
        <span onClick={handleClose} className="text-[#0157FF] text-sm cursor-pointer mt-4">No thanks, I’m good for now</span>
      </div>
    </div>
  )
}


export const DifyModal = ({ id= "mm-dify-modal", ...props } : DifyModalProps) => {

  let div = document.getElementById(id)
  if(!div){
    div = document.createElement('div')
    div.id = id
    document.body.appendChild(div)
  }
  render(<DifyModalBody id={id} {...props} /> ,div)
}
