import PropTypes from "prop-types";
import { IconArrowLeft } from '@tabler/icons'
import BackButtonIcon from "./back.svg";

export const SectionHeader = (props) => {
  const { label, actions, backButton, backButtonAction, search, hasSearch, containerClass } = props;
  return (
    <div className={`flex w-full ${containerClass}`}>
      <div className="mr-auto mb-[32px]">
        <div className="flex items-center">
          {backButton && <IconArrowLeft size="24px" className="cursor-pointer mr-1 text-primary" onClick={backButtonAction}/>}
          {label}
        </div>
        <div className="bg-gray-800 rounded mix-blend-normal opacity-50 w-11 h-1"></div>
      </div>
      <div className="ml-auto flex">
        {actions.map((action, index) => {
          const className = `inline-block${index ? " ml-1" : ""}`;
          return (
            <div className={className} key={index}>
              {action}
            </div>
          );
        })}
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  actions: PropTypes.node,
  label: PropTypes.node,
  backButton: PropTypes.bool,
  backButtonAction: PropTypes.func,
  containerClass: PropTypes.string
};

SectionHeader.defaultProps = {
  actions: [],
  containerClass: "",
  label: <h3 className="text-xl font-light font-rubik">TEST HEADING</h3>,
  backButton: false,
};
