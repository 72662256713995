export const TEMPLATE_TAGS_MAPPING = {
    'Apparel & Fashion': {
        label: 'Apparel & Fashion',
        name: 'apparel_fashion'
    },
    'Consumer Goods': {
        label: 'Consumer Goods',
        name: 'consumer_goods'
    },
    'Cosmetics': {
        label: 'Cosmetics',
        name: "cosmetics"
    },
    'Food & Beverages': {
        label: 'Food & Drink',
        name:"food_drink"
    },
    'Health, Wellness and Fitness': {
        label: 'Health & Wellness',
        name: "health_wellness"
    },
    'Holidays': {
        label: 'Holidays',
        name: "holidays"
    },
    'Luxury Goods': {
        label: 'Luxury Goods',
        name: "luxury_goods"
    },
    'Marketing and Advertising': {
        label: 'Marketing',
        name: "marketing"
    },
    'Real Estate': {
        label: 'Real Estate',
        name: "real_estate"
    },
    'Retail': {
        label: "Retail",
        name: "retail"
    },
    'Leisure, Travel & Tourism': {
        label: 'Travel',
        name: "travel"
    },
    'Luxury Goods & Jewelry': {
        label: 'Luxury Goods',
        name: "luxury_goods"
    }
}