import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import TestimonialIcon from "./assets/testimonial"
import PersonImg from './assets/person.png'

export const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  let timeOutVar = null;

  useEffect(() => {
    timeOutVar = setTimeout(() => {
      if(currentIndex === slides.length - 1) {
        setCurrentIndex(0)
      }
      else {
        setCurrentIndex(currentIndex + 1)
      }

    }, 5000)

    return () => clearTimeout(timeOutVar)
  }, [currentIndex,timeOutVar])

  const Dots = ({ screen }) => {
    return(
      <div className={`absolute bottom-[45px] ${screen === 'desktop' ? 'left-[250px]' : 'left-[50%] translate-x-[-50%]'}`}>
        <div className="flex">
          {
            slides.map((val, index) => {
              return(
                <div
                  key={index}
                  className={`mr-[4px] h-[10px] w-[10px] rounded-[50%] ${index === currentIndex ? 'bg-blue-50' : 'bg-gray-800'}`}
                  onClick={() => {
                    if(timeOutVar) {
                      clearTimeout(timeOutVar)
                    }
                    setCurrentIndex(index);
                  }}
                >
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative w-full h-full overflow-hidden items-center justify-center hidden md:flex max-w-[1366px] mx-auto"
          onMouseEnter={() => {
            if(timeOutVar) {
              clearTimeout(timeOutVar)
            }
          }}
          onMouseLeave={() => {
            if(timeOutVar) {
              timeOutVar = setTimeout(() => {
                if(currentIndex === slides.length - 1) {
                  setCurrentIndex(0)
                }
                else {
                  setCurrentIndex(currentIndex + 1)
                }
          
              }, 5000)
            }
          }}
        >
          {
            slides.map((item, index) => {
              const { url, description, name, position } = item
              const isActive = currentIndex === index
              const transition = index * 100
              const minusTransition = currentIndex * 100

              return (
                <div key={index} style={{ transition: `all 400ms ease-in-out`, transform: `translateX(${transition-minusTransition}%)` }} className={`${isActive ? 'flex' : 'flex'} absolute px-[32px] w-full items-center justify-center`}>
                  <div className="min-w-[158px] min-h-[158px] flex relative">
                    <img className="w-[158px] h-[158px] rounded-[50%] border border-white" src={url || PersonImg} style={{boxShadow: "0px 4px 10px rgba(17, 17, 17, 0.2)"}}/>
                    <TestimonialIcon className="absolute bottom-0 right-0" />
                  </div>
                  <div className="flex flex-col ml-[56px] mt-[7px] mb-[10px] leading-[31.8px] max-w-[1080px] relative">
                    <div className="font-ptSerif text-3lg text-[#757575] mb-[20px]">{description}</div>
                    <div className="font-normal font-inter text-2lg text-[#757575] mb-[28px]">
                      <span className="text-[#111111] font-ptSerif">{name}</span><span className='font-ptSerif'> | {position}</span>
                    </div>
                  </div>
                </div>
              )
            })
          }
          <Dots screen="desktop"/>
      </div>

      <div className="relative w-full h-full overflow-hidden flex desktop-container">
        {
          slides.map((item, index) => {
            const { url, description, name, position } = item
            const isActive = currentIndex === index
            const transition = index * 100
            const minusTransition = currentIndex * 100

            return (
              <div key={index} style={{ transition: `all 400ms ease-in-out`, transform: `translateX(${transition-minusTransition}%)` }} className={`${isActive ? 'flex' : 'flex'} flex-col absolute w-full h-full px-[32px] py-[40px] items-center justify-center`}>
                <div className="flex relative">
                  <img className="w-[120px] h-[120px] rounded-[50%] border border-white" src={url || PersonImg} style={{boxShadow: "0px 4px 4px rgba(17, 17, 17, 0.04)"}}/>
                  <TestimonialIcon className="absolute bottom-0 right-0" />
                </div>
                <div className="mt-[40px] w-full leading-[31.8px] text-center">
                  <div className="font-ptSerif text-[20px] text-[#757575] mb-[40px]">{description}</div>
                </div>
                <div className="font-normal font-inter text-[16px] text-[#757575] mb-[40px] text-center">
                  <span className="text-[#111111] font-ptSerif">{name}</span><span className='font-ptSerif'> | {position}</span>
                </div>
              </div>
            )
          })
        }
        <Dots screen="mobile"/>
      </div>
    </>
  );
};

Slider.propTypes = {
  slides: PropTypes.array
};

Slider.defaultProps = {
  slides: []
};
