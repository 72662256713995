import { useQuery } from '@apollo/client'
import { CURRENT_USER } from '../../../graphql/query/me'
import Integration from "./Integration"
import { FormattedMessage } from 'react-intl';

const NewIntegration = (props) => {
  const { createConnectedIntegration, integrableId, integrableType, integrations, siteId } = props

  const { data: { me: { referral = "" } = {} } = {} } = useQuery(CURRENT_USER)

  const allIntegrations = [
    ...(referral !== "shopify"
      ? [{ name: "mailchimp", width: "700", height: "850" }]
      : []),
    ...(
      (referral !== "shopify-mailchimp") && (referral !== "wix-mailchimp") ? 
      [
        {name: `constantcontact${window.parameterizedPartnerName != "wordstream" ? "v3" : ""}`, width: "700", height: "550"},
        {name: "activecampaign", width: "700", height: "300"},
        {name: "infusionsoft", width: "700", height: "500"},
        {name: `aweber${window.parameterizedPartnerName != "wordstream" ? "v2" : ""}`, width: "700", height: "900"},
        {name: "getresponse", width: "700", height: "420"},
        {name: "campaignmonitor", width: "750", height: "400"},
        {name: "convertkit", width: "700", height: "420"},
        {name: "intercom", width: "600", height: "450"},
        {name: "mailerlite", width: "700", height: "420"},
        {name: "klaviyo", width: "700", height: "420"},
        {name: "salesforce", width: "500", height: "600"},
        {name: "sendlane", width: "700", height: "500"},
        {name: "zapier", width: "700", height: "700"},
        {name: "httppost", width: "700", height: "300"},
        {name: "jilt", width: "700", height: "750"},
        {name: "sendy", width: "700", height: "430"}
       ] : []
    )
  ]

  return (
    <>
      <div className="mb-[8px] text-2lg font-light text-secondary">
        <FormattedMessage id="integrations.new.title" defaultMessage="Integrate with another service"  />
      </div>
      <div className="mb-[24px] text-sm text-secondary">
        <FormattedMessage id={`integrations.new.${integrableType === "Document" ? 'landing_page' : 'form'}.description`} defaultMessage={`Choose your email service provider to integrate this ${integrableType === "Document" ? 'landing page' : 'form' } with.`}  />
      </div>
      <div className={`grid grid-cols-2 grid-space gap-x-[16px] gap-y-[16px] ${allIntegrations.length <= 2 ? 'overflow-visible' : 'overflow-scroll'}`} data-attribute="integrations-container">
      {
        allIntegrations.map((item,index) => {
          const integrationProps = {
            createConnectedIntegration,
            integrableId,
            integrableType,
            availableIntegrations: integrations.filter(integration => integration.name.toLowerCase() === item.name),
            siteId,
            ...item
          }
          return <Integration key={index} {...integrationProps}/>
        })
      }
      </div>
    </>
  )
}

export default NewIntegration