import PropTypes from 'prop-types';
import { HashLoader as HashLoaderComponent} from "react-spinners";
import { themePrimaryColor } from '../../graphql/client';

export const HashLoader = ({
  size
}) => {

  return(
    <HashLoaderComponent 
      color={themePrimaryColor()}
      size={size} 
    />
  )
}

HashLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType(["string", "number"])
};

HashLoader.defaultProps = {
  color: "#0057FF",
  size: 50
};