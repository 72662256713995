const formsExamples = [
  "I want to create a pop-up form that converts more visitors",
  "How do I use pop-up forms to grow my email list?",
  "What are some effective ways to personalize pop-up forms for different segments of my audience?",
  "What offers work best to capture subscribers for eCommerce stores ?",
  "What are the best ways to incentivize visitors to fill out a pop-up form?",
  "What are some design tips for creating visually appealing pop-up forms?",
  "How can I use A/B testing to optimize my pop-up form's conversion rate?",
  "What are some ways to reduce pop-up form abandonment?",
  "What are some good examples of pop-up forms with high conversion rates?",
];

const broadcastExamples = [
  "How do I create a broadcast email that stands out in my subscriber's inbox?",
  "What are some best practices for writing effective broadcast emails?",
  "Can you give me some examples of successful broadcast email campaigns?",
  "How do I write a compelling subject line for my broadcast emails?",
  "What are some good examples of attention-grabbing subject lines?",
  "How do I personalize my broadcast emails without sounding spammy?",
  "How do I create a sense of urgency in my broadcast emails?",
  "What are some ways to optimize my email send time for maximum open rates?",
  "How can I use email automation to improve my broadcast email campaigns?",
  "What are some common mistakes to avoid when sending broadcast emails?",
  "How do I avoid getting my broadcast emails marked as spam?",
  "What are some effective ways to increase my email open and click-through rates?",
  "How do I analyze the performance of my broadcast email campaigns?"
];

const welcomeEmailExamples = [
  "I want to create a welcome email that engages my subscribers",
  "How do I make sure my welcome email gets delivered to the inbox?",
  "What are some successful welcome email examples that I can use?",
  "How do I create a welcome email series that nurtures my new subscribers?",
  "What are some creative welcome email ideas to surprise and delight my subscribers?",
  "How can I use segmentation to personalize my welcome emails?",
  "How do I create a welcome email that sets the tone for my brand?",
  "What are some best practices for optimizing my welcome email open and click-through rates?",
  "How do I measure the success of my welcome email campaigns?",
  "What are some common mistakes to avoid when creating welcome emails?",
  "How can I use welcome emails to reduce my email list churn rate?",
  "What are some successful welcome email examples in my industry?",
  "How can I use email automation to improve my welcome email campaigns?"
];


const landingPageExamples = [
  "How do I design a landing page that converts visitors into customers?",
  "What elements should I include in my landing page to increase conversion rates?",
  "Can you give me some examples of successful landing pages in my industry?",
  "How do I optimize my landing page for SEO?",
  "What are some design tips for creating high-converting landing pages?",
  "How do I write effective copy for my landing page?",
  "How can I use A/B testing to improve my landing page's conversion rate?",
  "What are some common mistakes to avoid when creating landing pages?",
  "How do I make sure my landing page is mobile-responsive?",
  "What are some ways to increase my landing page's social proof?",
  "How can I use videos on my landing page to increase engagement?",
  "What are some successful landing page examples in my industry?"
];


const campaignListView = [
  "What metrics should I track to measure the success of my email campaigns?",
  "What are some ways to improve the open and click-through rates of my email campaigns?",
  "What are some ways to optimize my email campaign workflow?",
  "How do I use email automation to improve my email campaigns?",
  "What are some common mistakes to avoid when creating email campaigns?",
  "How can I use personalization to increase my email campaign's engagement?",
  "What are some ways to increase my email campaign's deliverability?"
];

const defaultExamples = [
  'I want to create a pop-up form that converts more visitors',
  'How do I use pop-up forms to grow my email list?',
  'What are some effective ways to personalize pop-up forms for different segments of my audience?',
  'What offers work best to capture subscribers for eCommerce stores?'
]

export { formsExamples, broadcastExamples, welcomeEmailExamples, landingPageExamples, campaignListView, defaultExamples }