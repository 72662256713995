import React from 'react'
export interface SkeletonProps {
  width: number | string
  height: number | string
  cn?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({ width, height, cn }) => {
  return <div style={{ width, height }} className={`animate-pulse rounded-[8px] bg-gray-300 ${cn}`} />
}

Skeleton.defaultProps = {
  width: 150,
  height: 40,
}