import gql from "graphql-tag"

const DELETE_CUSTOM_EMAIL_TEMPLATE = gql`
  mutation deleteUserEmailTemplate($input: DeleteCustomEmailTemplateInput!) {
    deleteCustomEmailTemplate(input: $input) {
      success
      error
    }
  }
`

export { DELETE_CUSTOM_EMAIL_TEMPLATE }