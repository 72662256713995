export const CURRENCIES = [{
    "value": "$,USD",
    "label": "USD ($)"
  },
  {
    "value": "€,EUR",
    "label": "EUR (€)"
  },
  {
    "value": "£,GBP",
    "label": "GBP (£)"
  },
  {
    "value": "$,AUD",
    "label": "AUD ($)"
  },
  {
    "value": "$,CAD",
    "label": "CAD ($)"
  },
  {
    "value": "¥,JPY",
    "label": "JPY (¥)"
  },
  {
    "value": "Br,BYR",
    "label": "BYR (Br)"
  },
  {
    "value": "د.إ,AED",
    "label": "AED (د.إ)"
  },
  {
    "value": "؋,AFN",
    "label": "AFN (؋)"
  },
  {
    "value": "L,ALL",
    "label": "ALL (L)"
  },
  {
    "value": "դր.,AMD",
    "label": "AMD (դր.)"
  },
  {
    "value": "ƒ,ANG",
    "label": "ANG (ƒ)"
  },
  {
    "value": "Kz,AOA",
    "label": "AOA (Kz)"
  },
  {
    "value": "$,ARS",
    "label": "ARS ($)"
  },
  {
    "value": "ƒ,AWG",
    "label": "AWG (ƒ)"
  },
  {
    "value": "₼,AZN",
    "label": "AZN (₼)"
  },
  {
    "value": "КМ,BAM",
    "label": "BAM (КМ)"
  },
  {
    "value": "$,BBD",
    "label": "BBD ($)"
  },
  {
    "value": "৳,BDT",
    "label": "BDT (৳)"
  },
  {
    "value": "лв.,BGN",
    "label": "BGN (лв.)"
  },
  {
    "value": "ب.د,BHD",
    "label": "BHD (ب.د)"
  },
  {
    "value": "Fr,BIF",
    "label": "BIF (Fr)"
  },
  {
    "value": "$,BMD",
    "label": "BMD ($)"
  },
  {
    "value": "$,BND",
    "label": "BND ($)"
  },
  {
    "value": "Bs.,BOB",
    "label": "BOB (Bs.)"
  },
  {
    "value": "R$,BRL",
    "label": "BRL (R$)"
  },
  {
    "value": "$,BSD",
    "label": "BSD ($)"
  },
  {
    "value": "Nu.,BTN",
    "label": "BTN (Nu.)"
  },
  {
    "value": "P,BWP",
    "label": "BWP (P)"
  },
  {
    "value": "Br,BYN",
    "label": "BYN (Br)"
  },
  {
    "value": "$,BZD",
    "label": "BZD ($)"
  },
  {
    "value": "Fr,CDF",
    "label": "CDF (Fr)"
  },
  {
    "value": "CHF,CHF",
    "label": "CHF (CHF)"
  },
  {
    "value": "UF,CLF",
    "label": "CLF (UF)"
  },
  {
    "value": "$,CLP",
    "label": "CLP ($)"
  },
  {
    "value": "¥,CNY",
    "label": "CNY (¥)"
  },
  {
    "value": "$,COP",
    "label": "COP ($)"
  },
  {
    "value": "₡,CRC",
    "label": "CRC (₡)"
  },
  {
    "value": "$,CUC",
    "label": "CUC ($)"
  },
  {
    "value": "$,CUP",
    "label": "CUP ($)"
  },
  {
    "value": "$,CVE",
    "label": "CVE ($)"
  },
  {
    "value": "Kč,CZK",
    "label": "CZK (Kč)"
  },
  {
    "value": "Fdj,DJF",
    "label": "DJF (Fdj)"
  },
  {
    "value": "kr.,DKK",
    "label": "DKK (kr.)"
  },
  {
    "value": "$,DOP",
    "label": "DOP ($)"
  },
  {
    "value": "د.ج,DZD",
    "label": "DZD (د.ج)"
  },
  {
    "value": "ج.م,EGP",
    "label": "EGP (ج.م)"
  },
  {
    "value": "Nfk,ERN",
    "label": "ERN (Nfk)"
  },
  {
    "value": "Br,ETB",
    "label": "ETB (Br)"
  },
  {
    "value": "$,FJD",
    "label": "FJD ($)"
  },
  {
    "value": "£,FKP",
    "label": "FKP (£)"
  },
  {
    "value": "ლ,GEL",
    "label": "GEL (ლ)"
  },
  {
    "value": "₵,GHS",
    "label": "GHS (₵)"
  },
  {
    "value": "£,GIP",
    "label": "GIP (£)"
  },
  {
    "value": "D,GMD",
    "label": "GMD (D)"
  },
  {
    "value": "Fr,GNF",
    "label": "GNF (Fr)"
  },
  {
    "value": "Q,GTQ",
    "label": "GTQ (Q)"
  },
  {
    "value": "$,GYD",
    "label": "GYD ($)"
  },
  {
    "value": "$,HKD",
    "label": "HKD ($)"
  },
  {
    "value": "L,HNL",
    "label": "HNL (L)"
  },
  {
    "value": "kn,HRK",
    "label": "HRK (kn)"
  },
  {
    "value": "G,HTG",
    "label": "HTG (G)"
  },
  {
    "value": "Ft,HUF",
    "label": "HUF (Ft)"
  },
  {
    "value": "Rp,IDR",
    "label": "IDR (Rp)"
  },
  {
    "value": "₪,ILS",
    "label": "ILS (₪)"
  },
  {
    "value": "₹,INR",
    "label": "INR (₹)"
  },
  {
    "value": "ع.د,IQD",
    "label": "IQD (ع.د)"
  },
  {
    "value": "﷼,IRR",
    "label": "IRR (﷼)"
  },
  {
    "value": "kr,ISK",
    "label": "ISK (kr)"
  },
  {
    "value": "$,JMD",
    "label": "JMD ($)"
  },
  {
    "value": "د.ا,JOD",
    "label": "JOD (د.ا)"
  },
  {
    "value": "KSh,KES",
    "label": "KES (KSh)"
  },
  {
    "value": "som,KGS",
    "label": "KGS (som)"
  },
  {
    "value": "៛,KHR",
    "label": "KHR (៛)"
  },
  {
    "value": "Fr,KMF",
    "label": "KMF (Fr)"
  },
  {
    "value": "₩,KPW",
    "label": "KPW (₩)"
  },
  {
    "value": "₩,KRW",
    "label": "KRW (₩)"
  },
  {
    "value": "د.ك,KWD",
    "label": "KWD (د.ك)"
  },
  {
    "value": "$,KYD",
    "label": "KYD ($)"
  },
  {
    "value": "₸,KZT",
    "label": "KZT (₸)"
  },
  {
    "value": "₭,LAK",
    "label": "LAK (₭)"
  },
  {
    "value": "ل.ل,LBP",
    "label": "LBP (ل.ل)"
  },
  {
    "value": "₨,LKR",
    "label": "LKR (₨)"
  },
  {
    "value": "$,LRD",
    "label": "LRD ($)"
  },
  {
    "value": "L,LSL",
    "label": "LSL (L)"
  },
  {
    "value": "ل.د,LYD",
    "label": "LYD (ل.د)"
  },
  {
    "value": "د.م.,MAD",
    "label": "MAD (د.م.)"
  },
  {
    "value": "L,MDL",
    "label": "MDL (L)"
  },
  {
    "value": "Ar,MGA",
    "label": "MGA (Ar)"
  },
  {
    "value": "ден,MKD",
    "label": "MKD (ден)"
  },
  {
    "value": "K,MMK",
    "label": "MMK (K)"
  },
  {
    "value": "₮,MNT",
    "label": "MNT (₮)"
  },
  {
    "value": "P,MOP",
    "label": "MOP (P)"
  },
  {
    "value": "UM,MRU",
    "label": "MRU (UM)"
  },
  {
    "value": "₨,MUR",
    "label": "MUR (₨)"
  },
  {
    "value": "MVR,MVR",
    "label": "MVR (MVR)"
  },
  {
    "value": "MK,MWK",
    "label": "MWK (MK)"
  },
  {
    "value": "$,MXN",
    "label": "MXN ($)"
  },
  {
    "value": "RM,MYR",
    "label": "MYR (RM)"
  },
  {
    "value": "MTn,MZN",
    "label": "MZN (MTn)"
  },
  {
    "value": "$,NAD",
    "label": "NAD ($)"
  },
  {
    "value": "₦,NGN",
    "label": "NGN (₦)"
  },
  {
    "value": "C$,NIO",
    "label": "NIO (C$)"
  },
  {
    "value": "kr,NOK",
    "label": "NOK (kr)"
  },
  {
    "value": "₨,NPR",
    "label": "NPR (₨)"
  },
  {
    "value": "$,NZD",
    "label": "NZD ($)"
  },
  {
    "value": "ر.ع.,OMR",
    "label": "OMR (ر.ع.)"
  },
  {
    "value": "B/.,PAB",
    "label": "PAB (B/.)"
  },
  {
    "value": "S/,PEN",
    "label": "PEN (S/)"
  },
  {
    "value": "K,PGK",
    "label": "PGK (K)"
  },
  {
    "value": "₱,PHP",
    "label": "PHP (₱)"
  },
  {
    "value": "₨,PKR",
    "label": "PKR (₨)"
  },
  {
    "value": "zł,PLN",
    "label": "PLN (zł)"
  },
  {
    "value": "₲,PYG",
    "label": "PYG (₲)"
  },
  {
    "value": "ر.ق,QAR",
    "label": "QAR (ر.ق)"
  },
  {
    "value": "Lei,RON",
    "label": "RON (Lei)"
  },
  {
    "value": "РСД,RSD",
    "label": "RSD (РСД)"
  },
  {
    "value": "₽,RUB",
    "label": "RUB (₽)"
  },
  {
    "value": "FRw,RWF",
    "label": "RWF (FRw)"
  },
  {
    "value": "ر.س,SAR",
    "label": "SAR (ر.س)"
  },
  {
    "value": "$,SBD",
    "label": "SBD ($)"
  },
  {
    "value": "₨,SCR",
    "label": "SCR (₨)"
  },
  {
    "value": "£,SDG",
    "label": "SDG (£)"
  },
  {
    "value": "kr,SEK",
    "label": "SEK (kr)"
  },
  {
    "value": "$,SGD",
    "label": "SGD ($)"
  },
  {
    "value": "£,SHP",
    "label": "SHP (£)"
  },
  {
    "value": "Sk,SKK",
    "label": "SKK (Sk)"
  },
  {
    "value": "Le,SLL",
    "label": "SLL (Le)"
  },
  {
    "value": "Sh,SOS",
    "label": "SOS (Sh)"
  },
  {
    "value": "$,SRD",
    "label": "SRD ($)"
  },
  {
    "value": "£,SSP",
    "label": "SSP (£)"
  },
  {
    "value": "Db,STD",
    "label": "STD (Db)"
  },
  {
    "value": "₡,SVC",
    "label": "SVC (₡)"
  },
  {
    "value": "£S,SYP",
    "label": "SYP (£S)"
  },
  {
    "value": "E,SZL",
    "label": "SZL (E)"
  },
  {
    "value": "฿,THB",
    "label": "THB (฿)"
  },
  {
    "value": "ЅМ,TJS",
    "label": "TJS (ЅМ)"
  },
  {
    "value": "T,TMT",
    "label": "TMT (T)"
  },
  {
    "value": "د.ت,TND",
    "label": "TND (د.ت)"
  },
  {
    "value": "T$,TOP",
    "label": "TOP (T$)"
  },
  {
    "value": "₺,TRY",
    "label": "TRY (₺)"
  },
  {
    "value": "$,TTD",
    "label": "TTD ($)"
  },
  {
    "value": "$,TWD",
    "label": "TWD ($)"
  },
  {
    "value": "Sh,TZS",
    "label": "TZS (Sh)"
  },
  {
    "value": "₴,UAH",
    "label": "UAH (₴)"
  },
  {
    "value": "USh,UGX",
    "label": "UGX (USh)"
  },
  {
    "value": "$,UYU",
    "label": "UYU ($)"
  },
  {
    "value": "so'm,UZS",
    "label": "UZS (so'm)"
  },
  {
    "value": "Bs,VES",
    "label": "VES (Bs)"
  },
  {
    "value": "₫,VND",
    "label": "VND (₫)"
  },
  {
    "value": "Vt,VUV",
    "label": "VUV (Vt)"
  },
  {
    "value": "T,WST",
    "label": "WST (T)"
  },
  {
    "value": "Fr,XAF",
    "label": "XAF (Fr)"
  },
  {
    "value": "oz t,XAG",
    "label": "XAG (oz t)"
  },
  {
    "value": "oz t,XAU",
    "label": "XAU (oz t)"
  },
  {
    "value": "$,XCD",
    "label": "XCD ($)"
  },
  {
    "value": "SDR,XDR",
    "label": "XDR (SDR)"
  },
  {
    "value": "Fr,XOF",
    "label": "XOF (Fr)"
  },
  {
    "value": "oz t,XPD",
    "label": "XPD (oz t)"
  },
  {
    "value": "Fr,XPF",
    "label": "XPF (Fr)"
  },
  {
    "value": "oz t,XPT",
    "label": "XPT (oz t)"
  },
  {
    "value": "﷼,YER",
    "label": "YER (﷼)"
  },
  {
    "value": "R,ZAR",
    "label": "ZAR (R)"
  },
  {
    "value": "ZK,ZMK",
    "label": "ZMK (ZK)"
  },
  {
    "value": "K,ZMW",
    "label": "ZMW (K)"
  },
  {
    "value": "₿,BCH",
    "label": "BCH (₿)"
  },
  {
    "value": "₿,BTC",
    "label": "BTC (₿)"
  },
  {
    "value": "£,JEP",
    "label": "JEP (£)"
  },
  {
    "value": "£,GGP",
    "label": "GGP (£)"
  },
  {
    "value": "£,IMP",
    "label": "IMP (£)"
  },
  {
    "value": "¥,CNH",
    "label": "CNH (¥)"
  },
  {
    "value": "KR,EEK",
    "label": "EEK (KR)"
  },
  {
    "value": "₵,GHS",
    "label": "GHS (₵)"
  },
  {
    "value": "Lt,LTL",
    "label": "LTL (Lt)"
  },
  {
    "value": "Ls,LVL",
    "label": "LVL (Ls)"
  },
  {
    "value": "UM,MRO",
    "label": "MRO (UM)"
  },
  {
    "value": "₤,MTL",
    "label": "MTL (₤)"
  },
  {
    "value": "m,TMM",
    "label": "TMM (m)"
  },
  {
    "value": "¥,JPY",
    "label": "JPY (¥)"
  },
  {
    "value": "$,ZWD",
    "label": "ZWD ($)"
  },
  {
    "value": "$,ZWL",
    "label": "ZWL ($)"
  },
  {
    "value": "$,ZWN",
    "label": "ZWN ($)"
  },
  {
    "value": "$,ZWR",
    "label": "ZWR ($)"
  },
  {
    "value": "Bs.F,VEF",
    "label": "VEF (Bs.F)"
  }
]