import { Fragment, useState, useEffect } from 'react'
import { Accordion } from '../../components'
import { Section } from '.'
import { FormattedMessage } from 'react-intl'

const SectionBar = (props) => {
  const { label, settings = [], updateValue, updateError, values, className, reactIntlId,reactIntValues, builderStep = '' } = props
  const [open, setOpen] = useState({ })

  useEffect(() => {
    // open first pannel by default
    if(Object.keys(open).length === 0 && settings.length > 0) {
      setOpen({ [settings[0].name]: true })
    }
  },[])

  return (
    <div className="flex flex-col py-[24px] px-[32px] w-[422px] shadow overflow-x-visible overflow-y-auto">
      <div className="font-light text-2lg text-secondary mb-[16px]">
        { reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={label} values={reactIntValues} /> : label }
      </div>
      {
        settings.map((item,index) => {
          const { name, label, sections, isPremium = false, isAccordion = true, dataAttribute, helper, reactIntlId, stepSection, reactIntValues } = item
          const isOpen = open[name] || false
          const accordionProps = {
            isOpen,
            onClick: () => {
              setOpen({ ...open, [name]: !isOpen })
            },
            label,
            isPremium,
            dataAttribute,
            helper,
            reactIntlId,
            builderStep,
            stepSection,
            reactIntValues,
          }

          const renderSections = sections.map((section, index) => {
            const sectionProps = {
              values,
              onChange: updateValue,
              onError: updateError,
              builderStep,
              stepSection,
              reactIntlId,
              ...section
            }
            return (
              <div key={index} data-attribute={dataAttribute}>
                <Section {...sectionProps}/>
                {
                  // divider if next section exists
                  index < sections.length - 1 &&
                  <div className="mx-[16px] bg-gray-400 h-[1px] my-[24px]"></div>
                }
              </div>
            )
          })

          return (
            <>
            {
              isAccordion ?
              <Accordion key={index} {...accordionProps}>{renderSections}</Accordion> :
              <div className={`px-[16px] pt-[24px] pb-[16px] bg-gray-100 ${className}`} data-attribute={dataAttribute}>
                { renderSections }
              </div>
            }
            </>
          )
        })
      }
    </div>
  )
}

export { SectionBar }