import { FC } from 'react'
import { Button } from '..'
import { ReactComponent as EmptyPreviewSvg} from '../../assets/svg/EmptyPreviewSvg.svg'
import { trialEndModalForMembers } from '../../utils'
import { hasPlatformFeature } from '../../utils'

export const TrailModal: FC<{handleClose: () => void, isSiteOwner: boolean}> = (props) => {
  return (
    <div className='w-full h-full bg-[#fff] rounded-lg flex flex-col justify-center items-center text-lg'>
      <EmptyPreviewSvg  className="text-primary"/>
      <div className='font-medium mb-2'>Your trial has expired</div>
      <p className='text-gray-800 text-center'>Your trial has expired. Your campaigns may be stopped. <br /> Please upgrade to re-enable your campaigns.</p>
      <div className='flex mt-6'>
        {
          hasPlatformFeature("account", "billing") &&
          <Button className="mr-2" action="primary" label="Upgrade Account" onClick={() => {
            window?.track_mixpanel('free-trial:modal:upgrade-button')
            props.isSiteOwner ? window.pricing?.show() : trialEndModalForMembers()}
          } />
        }
        {
          hasPlatformFeature("customer_support", "chat") &&
          <Button action="primary" type="outline" label="Contact Support" onClick={() => {
            window?.track_mixpanel('free-trial:modal:contact-support')
            window?.Intercom?.("show")}
          } />
        }
      </div>
    </div>
  )
}

