import { HashLoader } from '../HashLoader'

export const SuspenseFallback = () => {
  return(
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
      <div className="flex w-full h-full justify-center items-center">
        <HashLoader />
      </div>
    </div>
  )
} 