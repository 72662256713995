import { Link, useHistory, useParams } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { useQuery } from "@apollo/client";
import { prependHttps, hasPlatformFeature } from "../../utils";
import { Tooltip } from "../Tooltip";
import { IconChevronDown } from "@tabler/icons";
import { FormattedMessage } from "react-intl";

// Queries
import { FETCH_USER_SITES_WITHOUT_CONTACTS_DATA, FETCH_SITE } from "../../graphql/query/site";
import { CURRENT_USER } from "../../graphql/query/me";
import { Spinner } from "../Spinner";

// const Icon = ({ icon, data }) => {
//   return (
//     <div className="flex">
//       <div className="w-[20px] h-[20px] bg-gray-200 flex items-center justify-center rounded-[2px]">
//         {icon}
//       </div>
//       <div className="text-secondary text-sm leading-[14px] pl-[6px] pt-[4px]">
//         {data}
//       </div>
//     </div>
//   );
// };

const PreviewPlaceholder = ({domain, height, width}) => {
  const formattedDomain = prependHttps(domain)
  
  return (
    <div style={{height: `${height}px`, width: `${width}px`}} className={`border rounded-[4px] mr-[16px] flex flex-center`}>
      <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${formattedDomain}&sz=32`}/>
    </div>
  );
}

const SiteSelectorOption = ({ history }) => {
  const { data: { sites = [] } = {}, loading } = useQuery(FETCH_USER_SITES_WITHOUT_CONTACTS_DATA, { variables: { full: false } });
  return loading ? <div className="flex justify-center items-center p-4">
    <Spinner height={32} width={32} /> 
    </div>
  : sites?.map((site) => <Link key={site?.id}
      className="flex items-center justify-between px-[12px] border-b-[1px] hover:bg-gray-100 border-gray-400 py-[16px] cursor-pointer select-none"
      to={`/sites/${site.id}/campaigns`}
    >
      <div className="flex items-center">
        <PreviewPlaceholder height="24" width="24" domain={site.domain}/>
        <div>
          <div className="text-secondary text-lg w-[180px] whitespace-nowrap overflow-hidden overflow-ellipsis">
            {site.name}
          </div>
          <div className="text-secondary-light text-sm w-[180px] whitespace-nowrap overflow-hidden overflow-ellipsis">
            {site.domain}
          </div>
          {/* <div className="flex gap-[8px] mt-[2px]">
            <Tooltip label="Campaigns" className="mr-[8px]">
              <Icon
                icon={<IconCopy stroke="1.25" color="#9FA3AD" size={16} />}
                data={site.widgetsCount}
              />
            </Tooltip>

            <Tooltip label="Contacts">
              <Icon
                icon={<IconUsers stroke="1.25" color="#9FA3AD" size={16} />}
                data={site.contactsCount}
              />
            </Tooltip>
          </div> */}
        </div>
      </div>
    </Link>
  )}

const SiteSelector = () => {

  const history = useHistory();
  const siteId = parseInt(useParams()?.siteId);

  const { data: { site } = {}} = useQuery(FETCH_SITE, { skip: !siteId ,variables: { id: siteId }});
  const { data: { me: { entitlements: { billingGatewayName } = {} } = {} } = {} } = useQuery(CURRENT_USER);
  const isBillingGatewayBraintree = (billingGatewayName === 'braintree' || false);

  const { name, domain } = site || {}


  return (
    <Popover className="mt-[34px] relative">
      <Popover.Button className="w-full flex items-center pb-[20px] justify-between  pr-[20px] pl-[28px]">
        <div className="flex items-center">
          <PreviewPlaceholder height="24" width="24" domain={domain}/>
          <div className="text-secondary text-lg w-[80px] whitespace-nowrap overflow-hidden overflow-ellipsis mr-[8px]">
            {name}
          </div>
        </div>
        <div className="cursor-pointer">
          <IconChevronDown stroke={1.5} size={18}/>
        </div>
      </Popover.Button>
      <div className="absolute left-[8px] bottom-[0px] right-[8px] border-b border-gray-400"></div>
      <Popover.Panel
        className="w-[300px] rounded-[8px] bg-white absolute z-10 left-4"
        style={{ boxShadow: "0px 32px 64px rgba(17, 17, 17, 0.08)" }}
      >
        <div className="flex items-center justify-between px-[16px] pt-[12px] pb-[12px] border-b-[1px]">
          <div className="text-secondary text-lg font-normal">
            <FormattedMessage id='sites_selector.title' defaultMessage='All Sites' />
          </div>
          {isBillingGatewayBraintree && hasPlatformFeature('sites', 'add_new') &&
          <div
            className="text-primary text-sm cursor-pointer"
            onClick={() => history.push("/sites/new")}
          >
            <FormattedMessage id='sites_selector.create_new_site' defaultMessage='Create New' />
          </div>
          }
        </div>
        <div className="h-full max-h-[350px] overflow-y-scroll">
          <SiteSelectorOption history={history} />
        </div>
        {isBillingGatewayBraintree &&
        <div className="py-[16px] border-t-[1px]">
          <div
            className="text-primary text-sm text-center cursor-pointer"
            onClick={() => history.push("/sites")}
          >
            <FormattedMessage id='sites_selector.view_all_sites' defaultMessage='View All Sites' />
          </div>
        </div>
        }
      </Popover.Panel>
    </Popover>
  );
};

export default SiteSelector;
