import * as React from "react";

interface IconSortProps {
  upColor?: string;
  downColor?: string;
  size?: number;
}

const IconSort = ({ upColor = "#2E384D", downColor = "#2E384D", size = 16 }: IconSortProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_612_6765)">
      <path
        d="M2 6.00016L4.66667 3.3335M4.66667 3.3335L7.33333 6.00016M4.66667 3.3335V12.6668"
        stroke={upColor}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10.0002L11.3334 12.6668M11.3334 12.6668L8.66669 10.0002M11.3334 12.6668V3.3335"
        stroke={downColor}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_612_6765">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconSort;
