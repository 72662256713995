import { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_INTEGRATIONS } from '../../../graphql/query/integration'
import { CREATE_CONNECTED_INTEGRATION, DELETE_CONNECTED_INTEGRATION } from '../../../graphql/mutation/integration'
import NewIntegration from './NewIntegration'
import ListIntegrations from './ListIntegration'
import EditIntegration from './EditIntegration'

const IntegrateStep = (props) => {
  const { siteId, connectedIntegrations = [], integrableId, integrableType, refetchQueries } = props
  const { data: { integrations = [] } = {}} = useQuery(GET_INTEGRATIONS, { variables: { siteId }})
  const [deleteConnectedIntegration] = useMutation(DELETE_CONNECTED_INTEGRATION)
  const [createConnectedIntegration] = useMutation(CREATE_CONNECTED_INTEGRATION, { refetchQueries })
  const [values,setValue] = useState({})
  const [error, setError] = useState(null)
  const [integrationName, setIntegrationName] = useState(null)
  const { integrationId, step } = values

  const handleUpdate = (val) => {
    setValue({ ...values, ...val })
  }

  const listenForMessage = (event) => {
    if (typeof(event.origin) == 'undefined' || event.origin.indexOf(process.env.REACT_APP_RAILS) == -1) return

    const data = JSON.parse(event.data)
    if (data.type == 'integration_success') {
      if (data.integration_id) {
        createConnectedIntegration({ variables: { input: { integrationId: parseInt(data.integration_id), integrableId, integrableType }}}).then(({ data }) => {
          const { createConnectedIntegration } = data || {}
          const { connectedIntegration } = createConnectedIntegration || {}
          handleUpdate({ step: 'edit', integrationId: connectedIntegration?.id })
        })
      }
    }
    else if(data.type === 'change_account') {
      handleUpdate({ integrationId: null, step: 'list' })
    }
    else if (data.type == 'integration_error') {
      setError(data.error)
    }
  }

  useEffect(() => {
    if(connectedIntegrations.length > 0) {
      connectedIntegrations.map((connectedIntegration, index) => {
        if(connectedIntegration.integration?.id == props.integrableId){
          setIntegrationName(connectedIntegration.integration?.name)
        }
      })
      if(integrationId)
        handleUpdate({ step: 'edit' })
      else 
        handleUpdate({ step: 'list' })
    }
    else if(connectedIntegrations.length === 0) {
      handleUpdate({ step: 'new' })
    }
  },[connectedIntegrations])

  useEffect(() => {
    window.addEventListener("message", listenForMessage)
    return () => document.removeEventListener('message', listenForMessage)
  },[])

  const renderStep = () => {
    const newIntegrationProps = {
      createConnectedIntegration,
      integrableId,
      integrableType,
      integrations,
      siteId,
    }

    const listIntegrationProps = {
      connectedIntegrations,
      deleteConnectedIntegration,
      handleUpdate,
    }

    const editIntegrationProps = {
      integrationId,
      error,
      setError,
      integrationName,
      handleUpdate,
    }
    
    switch(step) { 
      case 'new': 
        return <NewIntegration {...newIntegrationProps} /> 
      case 'list':
        return <ListIntegrations {...listIntegrationProps}/>
      case 'edit':
        return <EditIntegration {...editIntegrationProps}/>
    }
  }

  return (
    <div className="flex flex-col py-[24px] px-[32px] w-[422px] shadow" data-attribute={`integrations-container-${integrableType}`}>
    {
      renderStep(step)
    }
    </div>
  )
}

export { IntegrateStep}