import { FC } from 'react'
import { IconBell, IconChecks } from '@tabler/icons'
import { Popup, Tag } from '..'
import { TrayNotification } from '../Notifications/Tray'
import { MutationFunction, useMutation } from '@apollo/client'
import { notificationType, getTime, NotificationType } from './types'
import { MarkAsDone, MarkAllAsDone, MarkAllAsRead } from '../../graphql/mutation/notification'
import { ReactComponent as  EmptyNotification } from './EmptyNotification.svg'

interface Notification {
  id: number
  name: string
  siteId: number
  data: any
  readAt?: Date
  redirectUrl?: string
  targets?: any
  notificationCreatedAt?: Date
  createdAt: Date
  updatedAt: Date
  actionTakenAt?: Date | null
  pinned: boolean | null
}

interface Notifications extends Notification, NotificationType {}

const handleClick = ({ actionTakenAt, id, redirectUrl, onClick, markAsDone, file_url }: any) => {
  if (!actionTakenAt) {
    id >= 0 && markAsDone({variables: { id } });
    // if (typeof mailmunchTrack != "undefined") {
    //   mailmunchTrack.track_mixpanel(`notifications:read`);
    // }
  }

  // This timeout is so mixpanel can save the above event
  setTimeout(function () {
    onClick({ file_url, redirectUrl: `${redirectUrl}?token=${window?.token}` });
  }, 300);
}


const RenderNotification: FC<{notifications: Notifications[], markAsDone: MutationFunction}> = ({ notifications, markAsDone }) => {

  return (
    <>
    {notifications
      .map(({ 
        actionTakenAt, 
        id, 
        redirectUrl, 
        onClick, 
        subTitle, 
        title, 
        notificationCreatedAt, 
        color,
        icon,
        data 
      },index: number)=> {
        return (
          <div key={index} className="h-full w-full">
            <TrayNotification
              icon={icon}
              actionTakenAt={actionTakenAt}
              onClick={() => handleClick({ ...data, actionTakenAt, id, redirectUrl, onClick, markAsDone })}
              color={color} 
              body={subTitle({ ...data })} 
              title={title({ ...data })} 
              date={getTime(notificationCreatedAt)} 
              />
          </div>
        )
      })}
    </>
  )
}


export const NotificationTray: FC<{ data: {notifications: Notification[]}, siteId: number }> = ({ data, siteId }) => {

  let notifications: Notifications[] = []

  if(data && data.notifications) {
    for(const notification of data.notifications) {
      for(const type of notificationType) {
        if(notification.name === type.name && notification.targets.includes('tray')) {
          notifications.push({ ...type, ...notification })
        }
      }
    }
  }

  const numNoActionTaken = notifications.filter((item) => !item.actionTakenAt).length 
  const pinnedNotifications = notifications.filter((item) => item.pinned)
  const regularNotifications = notifications.filter((item) => !item.pinned)

  const [markAsDone] = useMutation(MarkAsDone);
  const [markAllAsRead] = useMutation(MarkAllAsRead);
  const [markAllAsDone] = useMutation(MarkAllAsDone);

  let numUnread =
  (data &&
    data.notifications &&
    data.notifications.filter(item => !item.readAt && item.targets.includes('tray')).length) ||
  0;

  if (numUnread > 9) numUnread = 9;

  const notificationsRead = data?.notifications?.some(not => {
    if(!not.pinned){
      return not.readAt === null
    }
  })
 
  return (
    <Popup
      alignRight={true}
      className={`w-[440px] rounded-lg ${notifications.length < 1 && 'h-[280px]'}`}
      component={() =>
        <span className="flex items-center h-[32px] ml-[10px]">
            <Tag
              icon={<IconBell className="text-secondary-dark" size="24px" />}
              iconPosition="left"
              onClick={notificationsRead ? () => markAllAsRead({ variables: { siteId } }): undefined}
              label={numUnread.toString() || "0"}
              count={true}
              action="default"
              className="h-[30px] bg-warning-lighter"
            />
        </span>
      }
      content={
        () => <>
          <div className="flex items-center justify-between p-4">
            <div className="flex items-center justify-center">
              <h4 className="font-medium text-lg text-secondary">Notifications</h4>
              {
                notifications.length > 0 && 
                <div className="text-white w-[20px] h-[20px] bg-warning text-xs rounded-full font-normal flex items-center justify-center ml-1">
                  {numNoActionTaken}
                </div>
              }
            </div>
            {
              notifications.length > 0 && <div className="flex items-center justify-center cursor-pointer">
                <IconChecks size="16px" stroke="0.95" />
                <span onClick={() => {
                  window?.track_mixpanel('notifications:all-read')
                  markAllAsDone({ variables:{ siteId } })
                  }} className="text-primary text-sm font-normal ml-1">Read All</span>
              </div>
            }
          </div>
          {
          notifications.length > 0 ? <div className="w-full max-h-[520px] overflow-y-scroll rounded-br-lg rounded-bl-lg flex flex-col items-center">
            {pinnedNotifications?.length > 0 && <div className="bg-gray-200 h-[20px] text-xs text-gray-900 pt-[4px] pl-[16px] font-medium w-full">REQUIRES ATTENTION</div>}
             <RenderNotification notifications={pinnedNotifications} markAsDone={markAsDone} />
             {pinnedNotifications?.length > 0 && regularNotifications?.length > 0 &&  <div className="bg-gray-200 h-[20px] text-xs text-gray-900 pt-[4px] pl-[16px] font-medium w-full">OTHER</div>}
             <RenderNotification notifications={regularNotifications} markAsDone={markAsDone} />
          </div> 
          :
          <div className="flex flex-col items-center justify-center h-[200px] text-gray-800">
            <EmptyNotification className='text-primary' fill='currentColor' />
             No notifications yet.
          </div>
          }
          
        </>
      } />
  )
}
