import React, { Component } from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
  text-align: center;
  margin-top: ${(props) => `${props.marginTop || 0}px`};

  .spinner {
    display: inline-block;
    position: relative;
    width: ${(props) => `${props.size || 80}px`};
    height: ${(props) => `${props.size || 80}px`};
    margin: 0 auto;
  }

  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => `${props.size * 0.8 || 64}px`};
    height: ${(props) => `${props.size * 0.8 || 64}px`};
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #444 transparent transparent transparent;
  }

  .spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

class LoaderComponent extends Component {
  render() {
    const { size, marginTop } = this.props;

    return (
      <LoaderContainer size={size} marginTop={marginTop}>
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </LoaderContainer>
    );
  }
}

export default LoaderComponent;
