const Padding = () => {
  return (
    <svg width="54" height="24" viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="54" height="24" rx="4" fill="#EFF0F7"/>
      <rect x="4" y="4" width="46" height="16" rx="4" fill="#EFF0F7" stroke="#565E70" strokeDasharray="2 2"/>
    </svg>
  )
}

export default Padding

