import PropTypes from "prop-types";
import {
  IconJumpRope
} from '@tabler/icons';

export const Chip = ({
  onClick,
  isActive,
  Icon,
  label
}) => {
  return (
    <div
      onClick={onClick}
      className={`${isActive ? 'bg-primary text-white border-primary' : 'hover:cursor-pointer text-secondary border-gray-500'} w-full sm:w-max flex gap-x-[14.5px] sm:justify-between px-[16px] py-[11px] box-border rounded-[8px] border`}>
      <div className={`${isActive ? 'text-white' : 'text-secondary'}`}>{Icon}</div>
      <div className='mt-[2px] text-lg font-normal'>{label}</div>
    </div>
  );
};

Chip.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  Icon: PropTypes.element,
};

Chip.defaultProps = {
  onClick: undefined,
  label: 'LABEL',
  isActive: false,
  Icon: <IconJumpRope size={24} stroke={1.25} />
};
