import moment from "moment-timezone";
import { IconCalendarStats, IconSend, IconReport, IconX, IconBan, IconDatabaseImport, IconDatabaseExport, IconThumbUp, IconStar, TablerIcon } from '@tabler/icons'

const color = {
  success: "success",
  failed: "danger",
  blue: "primary",
};

type SubTitleType = { subject?: string, total_subscribers?: number, total_views?: number, integration_name?: string, active_list_name?: string, imported_contacts_count?: number }
export interface NotificationType {
  name: string;
  icon: TablerIcon;
  color: string;
  title: ({ stats } : { stats?: { opened?: number } }) => string;
  subTitle: ({ subject, imported_contacts_count, total_subscribers, total_views, integration_name, active_list_name }: SubTitleType) => string;
  onClick?: ({ redirectUrl, file_url } : { redirectUrl?: string, file_url?: string }) => void;
} 


export const getTime = (datetime: any) => {
  let time = moment.parseZone(datetime);
  return time.fromNow();
};

export const notificationType: NotificationType[] = [
  {
    name: "broadcast_failed",
    title: () => "Broadcast Email Failed",
    subTitle: ({ subject }) => `${subject}`,
    icon: IconX,
    color: color.failed,
  },
  {
    name: "broadcast_sent",
    title: () => "Broadcast Email Sent",
    subTitle: ({ subject }) => `${subject}`,
    icon: IconSend,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
    color: color.success,
  },
  {
    name: "broadcasts_disabled",
    title: () => "Broadcasts Disabled [Action Required]",
    subTitle: () => `We need to manually approve your account for sending broadcast emails. Please contact us to get approved.`,
    icon: IconBan,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_blank"),
    color: color.failed,
  },
  {
    name: "broadcast_stats",
    title: ({ stats }) => {
      const { opened } = stats || {}
      return `Email Opened ${opened ? (opened) : 0} Times`
    },
    subTitle: ({ subject = '' }) => `${subject}`,
    icon: IconReport,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
    color: color.success,
  },
  {
    name: "daily_summary",
    title: () => "Daily Summary",
    subTitle: ({ total_subscribers = 0, total_views = 0 }) =>
      `You got ${(total_views.toLocaleString())} views and ${(
        total_subscribers.toLocaleString()
      )} contacts today`,
    icon: IconCalendarStats,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
    color: color.success,
  },
  {
    name: "emails_disabled",
    title: () => "Emails Disabled [Action Required]",
    subTitle: () => `We need to manually approve your account for sending emails. Please contact us to get approved.`,
    icon: IconBan,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_blank"),
    color: color.failed,
  },
  {
    name: "export_failed",
    title: () => `Contacts Export Failed`,
    subTitle: () => `There was an issue while exporting contacts`,
    icon: IconX,
    color: color.failed,
  },
  {
    name: "analytics_export_failed",
    title: () => "Email Analytics Export Failed",
    subTitle: () => "There was an issue while exporting email analytics",
    icon: IconX,
    color: color.failed,
  },
  {
    name: "import_complete",
    title: () => `Contacts Imported Successfully`,
    subTitle: ({ imported_contacts_count = 0 }) => `${imported_contacts_count.toLocaleString()} contacts have been imported successfully`,
    icon: IconDatabaseImport,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
    color: color.success,
  },
  {
    name: "import_failed",
    title: () => `Contacts Import Failed`,
    subTitle: () => `There was an issue while importing contacts`,
    icon: IconX,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
    color: color.failed,
  },
  // {
  //   name: "integration_sync_failed",
  //   title: () => "Integration Error",
  //   subTitle: ({ integration_name, active_list_name }) => `Your ${integration_name} integration failed while adding subscribers to ${active_list_name}`,
  //   icon: IconX,
  //   onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
  //   color: color.failed,
  // },
  {
    name: "landing_pages_disabled",
    title: () => "Landing Pages Disabled [Action Required]",
    subTitle: () => `We need to manually approve your account for enabling Landing Pages. Please contact us to get approved.`,
    icon: IconBan,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_blank"),
    color: color.failed,
  },
  {
    name: "weekly_summary",
    title: () => "Weekly Summary",
    subTitle: ({ total_subscribers = 0, total_views = 0 }) =>
      `You got ${(total_views.toLocaleString())} views and ${(
        total_subscribers.toLocaleString()
      )} contacts last week`,
    icon: IconCalendarStats,
    onClick: ({ redirectUrl }) => window.open(redirectUrl, "_parent"),
    color: color.success,
  },
  {
    name: "export_complete",
    title: () => `Contacts Exported Successfully`,
    subTitle: () => `Your contacts have been exported successfully.`,
    icon: IconDatabaseExport,
    onClick: () => {},
    color: color.success,
  },
  {
    name: "analytics_export_complete",
    title: () => `Analytics Exported Successfully`,
    subTitle: () => `Your broadcast analytics have been exported successfully. Click here to download`,
    icon: IconDatabaseExport,
    onClick: ({ file_url }) => window.open(file_url, '_self'),
    color: color.success,
  },
  {
    name: "shopify_review",
    title: () => `Review ${window.partnerName}`,
    subTitle: () => "Take 30 seconds to give us a quick review on Shopify. It'd make us really happy :)",
    icon: IconStar,
    onClick: ({ redirectUrl }) => {
      window.open(redirectUrl, "_blank");
    },
    color: color.success,
  },
  {
    name: "wix_review",
    title: () => `Review ${window.partnerName}`,
    subTitle: () => "Take 30 seconds to give us a quick review on Wix. It'd make us really happy :)",
    icon: IconStar,
    onClick: ({ redirectUrl }) => {
      window.open(redirectUrl, "_blank");
    },
    color: color.success,
  },
  {
    name: "constantcontact_review",
    title: () => `Review ${window.partnerName} for Free SEO`,
    subTitle: () => "Take 30 seconds to improve your search rankings with a quick review on Constant Contact marketplace.",
    icon: IconStar, 
    onClick: ({ redirectUrl }) => {
      window.open(redirectUrl, "_blank");
    },
    color: color.success,
  },
];



export const floatingNotificationType = [
  {
    name: "broadcast_failed",
    title: () => "Broadcast Email Failed",
    body: ({ subject }: any) => `${subject}`,
    icon: IconX,
    autoClose: true,
    color: color.failed,
  },
  {
    name: "broadcast_sent",
    title: () => "Broadcast Email Sent",
    body: ({ subject }: any) => `${subject}`,
    icon: IconSend,
    action: {
      label: 'View Report',
      onClick: ({ redirectUrl }: {redirectUrl: string}) => window.open(redirectUrl, "_blank"),
    },
    color: color.success,
  },
  {
    name: "broadcast_stats",
    title: ({ stats = {}}) => {
      //@ts-ignore
      const { opened } = stats || {}
      return `Email Opened ${opened ? (opened) : 0} Times`
    },
    body: ({ subject = ''}) => `${subject}`,
    icon: IconReport,
    action: {
      label: 'View Report',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_blank"),
    },
    autoClose: true,
    color: color.success,
  },
  {
    name: "broadcasts_disabled",
    title: () => "Broadcasts Disabled [Action Required]",
    body: () => `We need to manually approve your account for sending broadcast emails. Please contact us to get approved.`,
    onClose: false,
    icon: IconBan,
    action: {
      label: 'Contact Us',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_blank")
    },
    autoClose: false,
    color: color.failed,
  },
  {
    name: "emails_disabled",
    title: () => "Emails Disabled [Action Required]",
    body: () => `We need to manually approve your account for sending emails. Please contact us to get approved.`,
    icon: IconBan,
    onClose: false,
    action: {
      label: 'Contact Us',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_blank")
    },
    autoClose: false,
    color: color.failed,
  },
  {
    name: "export_complete",
    title: () => `Contacts Exported Successfully`,
    body: () => `Your Contacts have been exported successfully.`,
    icon: IconDatabaseExport,
    autoClose: true,
    color: color.success,
  },
  {
    name: "analytics_export_complete",
    title: () => "Analytics Exported Successfully",
    body: () => "Your email analytics have been exported successfully. Click here to download",
    icon: IconDatabaseExport,
    autoClose: true,
    action: {
      label: 'Download CSV',
      onClick: ({ file_url }:{file_url: string}) =>  window.open(file_url, '_self')
    },
    color: color.success,
  },
  {
    name: "export_failed",
    title: () => `Contacts Export Failed`,
    body: () => `There was an issue while exporting contacts`,
    icon: IconX,
    autoClose: false,
    color: color.failed,
  },
  {
    name: "analytics_export_failed",
    title: () => "Email Analytics Export Failed",
    body: () => "There was an issue while exporting email analytics",
    icon: IconX,
    autoClose: false,
    color: color.failed,
  },
  {
    name: "follow_like",
    title: () => "Follow us for latest updates",
    body: () => "Like us on Facebook. Get email marketing tips, news and hacks.",
    icon: IconThumbUp,
    autoClose: false,
    onOpen: () => {
      // if (twttr && twttr.widgets) {
      //   twttr.widgets.load()
      // }
    },
    action: {
      customLabel: ({ redirectUrl }:{redirectUrl: string}) => 
        <>
        {/* <div style={{marginBottom: "10px"}}>
          <a className="twitter-follow-button" href={`https://twitter.com/${mailmunchPlatform.get_social_username('twitter')}`} data-size="large">
            Follow @{mailmunchPlatform.get_social_username('twitter')}
          </a>
        </div> */}
        
          <iframe
            id='facebook-like'
            // src={`https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Ffacebook.com%2F${mailmunchPlatform.get_social_username('facebook')}&layout=button_count&action=like&size=large`}
            style={{border: "none", overflow: "hidden", marginTop: 10}}
            height='30'
            width='271'
            scrolling="no"
            frameBorder="0"
          >
          </iframe>
        </>,
      onClick: () => window.open(`https://www.facebook.com/${window.parameterizedPartnerName}`, '_blank')
    },
    color: color.blue,
  },
  {
    name: "import_complete",
    title: () => `Contacts Imported Successfully`,
    body: ({ imported_contacts_count = 0 }) =>
      `${(
        imported_contacts_count.toLocaleString()
      )} contacts have been imported successfully`,
    icon: IconDatabaseImport,
    autoClose: true,
    action : {
      label: 'View Status',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_parent"),
    },
    color: color.success,
  },
  {
    name: "import_failed",
    title: () => `Contacts Import Failed`,
    body: () => `There was an issue while importing contacts`,
    icon: IconX,
    autoClose: true,
    action: {
      label: 'View Status',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_parent"),
    },
    color: color.failed,
  },
  // {
  //   name: "integration_sync_failed",
  //   title: () => "Integration Error",
  //   body: ({ integration_name, active_list_name }:{integration_name: string, active_list_name: string}) =>
  //     `Your ${integration_name} integration failed while adding subscribers to ${active_list_name}`,
  //   icon: IconX,
  //   action: { 
  //     label: 'Fix Now',
  //     onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_blank"),
  //   },
  //   autoClose: false,
  //   color: color.failed,
  // },
  {
    name: "landing_pages_disabled",
    title: () => "Landing Pages Disabled [Action Required]",
    body: () => `We need to manually approve your account for enabling Landing Pages. Please contact us to get approved.`,
    icon: IconBan,
    onClose: false,
    action: {
      label: 'Contact Us',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, "_blank")
    },
    autoClose: false,
    color: color.failed,
  },
  {
    name: "shopify_review",
    title: () => `Review ${window.partnerName}`,
    body: ({ }) =>
      "Take 30 seconds to give us a quick review on Shopify. It'd make us really happy :)",
    icon: IconStar,
    autoClose: false,
    onClose: false,
    action: {
      label: 'Leave us a review',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, '_blank'),
    },
    color: color.success,
  },
  {
    name: "wix_review",
    title: () => `Review ${window.partnerName}`,
    body: () => "Take 30 seconds to give us a quick review on Wix. It'd make us really happy :)",
    icon: IconStar,
    autoClose: false,
    onClose: false,
    action: {
      label: 'Leave us a review',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, '_blank'),
    },
    color: color.success,
  },
  {
    name: "constantcontact_review",
    title: () => `Review ${window.partnerName} for Free SEO`,
    body: () => "Take 30 seconds to improve your search rankings with a quick review on Constant Contact marketplace.",
    icon: IconStar,
    autoClose: false,
    onClose: false,
    action: {
      label: 'Leave us a review',
      onClick: ({ redirectUrl }:{redirectUrl: string}) => window.open(redirectUrl, '_blank'),
    },
    color: color.success,
  },
]