import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

const variants = {
  h1: 'text-[48px] font-light font-rubik text-secondary',
  h2: 'text-[34px] font-light font-rubik text-secondary',
  h3: 'text-[28px] font-light font-rubik text-secondary',
  h4: 'text-[15px] font-medium font-rubik text-secondary',
  p1: 'text-[15px] font-normal font-rubik text-secondary',
  p2: 'text-[15px] font-normal font-rubik text-gray-800',
  p3: 'text-[15px] font-normal font-rubik text-gray-700',
  p4: 'text-[15px] font-normal font-rubik text-white',
  p5: 'text-sm font-normal font-rubik text-secondary',
  p6: 'text-[10px] font-normal font-rubik text-gray-800',  
  c1: 'text-[13px] font-normal font-rubik text-gray-800',
  c2: 'text-sm font-medium font-rubik text-gray-800',
  pb1: 'text-[20px] font-light font-rubik text-secondary',
  pb2: 'text-[20px] font-light font-rubik text-gray-800',
  sm1: 'text-[13px] font-normal text-secondary'
}

export const Typography = (props) => {
  const { label, variant, className, onClick, dataAttribute, reactIntlId } = props;

  return (
    <div 
      className={`${variants[variant]} ${className}`} 
      onClick={onClick}
      {...(dataAttribute && {'data-attribute': `text-${dataAttribute}`})}
    >
      { reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={label} /> : label }
    </div>
  )
}

Typography.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['h1','h2','h3','h4','p1','p2','p3','p4','p5','p6','c1','c2','pb1','pb2'])
};

Typography.defaultProps = {
  label: "Heading",
  variant: "h1",
  cn: '',
  onClick: null,
};
