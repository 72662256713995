import { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'

import { Button } from "../"
import { introData } from "./data"
import { usePrevious } from "../../utils"

export const IntroPopup = ({ data, handleClose }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [translateX, setTranslateX] = useState(0)
  const containerRef = useRef(null)

  const prevActiveIndex = usePrevious(activeIndex)
  const translateXAmount = 500

  useEffect(() => {
    const amount = Math.abs(prevActiveIndex - activeIndex)
    if(amount > 1){
      if(prevActiveIndex < activeIndex){
        setTranslateX(translateX + (amount*translateXAmount))
      }
      else if(prevActiveIndex > activeIndex){
        setTranslateX(translateX - (amount*translateXAmount))
      }
    }
    else{
      if(prevActiveIndex < activeIndex){
        setTranslateX(translateX + translateXAmount)
      }
      else if(prevActiveIndex > activeIndex){
        setTranslateX(translateX - translateXAmount)
      }
    }
  }, [activeIndex])

  useEffect(() => {
    const { current: { children } } = containerRef
    animateInnerElements({ children: [...children] })
  }, [translateX])

  const animateInnerElements = ({ children })  => {
    for(let i = 0; i < children.length; i++){
      let child = [...children[i].children]
      const svg = child[0]
      svg.style.transform = `translateX(${-translateX}px)`
      svg.style.transition = `transform 700ms ease-out`
    }
  }

  const Dot = ({ onClick, index }) => {
    return(
      <div className={`w-[10px] h-[10px] ${index === activeIndex ? 'bg-primary' : 'bg-[#DDDDDD]'} rounded-full ml-[10px] cursor-pointer`} onClick={onClick}/>
    )
  }

  return(
    <div className="h-[560px] w-[500px] relative" style={{boxShadow: "0px 32px 110px rgba(0, 0, 0, 0.05)"}}>
      <div className="flex overflow-hidden w-[500px] h-full" ref={containerRef}>
        {
          data.map(({ Image }, index) => {
            return(
              <div key={index} className={`w-full flex relative items-center flex-col`}>
                <div className="flex w-[500px] relative opacity-100">
                  <div className={`m-auto ${index === 3 ? 'mt-[16px]' : 'mt-[32px]'}`}>
                    <Image />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      {
        data.map(({ Action, heading, text }, index) => {
          return(
            <>
              {
                <div key={index} className={`${index === activeIndex ? 'opacity-100 transition duration-500' : 'opacity-0 transition duration-500'} w-full absolute top-[300px] left-[0px] flex flex-col text-center items-center justify-center`}>
                  <div className="text-secondary font-medium text-3lg pt-[30px]">
                    {heading}
                  </div>
                  <div className="text-gray-800 text-lg w-[375px] pt-[16px]">
                    {text}
                    {Action && <Action onClick={handleClose}/>}
                  </div>
                </div>
              }
            </>
          )
        })
      }
      <div className="flex justify-center items-end absolute top-[507px] left-[50%] translate-x-[-50%]">
        <div className="flex">
          {
            data.map((item, index) => {
              return(
                <Dot activeIndex={activeIndex} index={index} onClick={() => setActiveIndex(index)}/>
              )
            })
          }
        </div>
      </div>
      {
        activeIndex > 0 && 
        <div className="absolute left-[32px] bottom-[32px]">
          <Button
            action={"default"}
            label={"Back"}
            onClick={() => {
              activeIndex > 0 && setActiveIndex(activeIndex - 1)
            }}
          />
        </div>
      }
      {
        activeIndex < (data.length - 1) && 
        <div className="absolute right-[32px] bottom-[32px]">
          <Button
            label={"Next"}
            onClick={() => {
              activeIndex < 5 && setActiveIndex(activeIndex + 1)
            }}
            hasShadow={true}
          />
        </div>
      }
      {
        activeIndex === (data.length - 1) && 
        <div className="absolute right-[32px] bottom-[32px]">
          <Button
            label={"Finish"}
            onClick={handleClose}
            hasShadow={true}
          />
        </div>
      }
    </div>
  )
}

IntroPopup.propTypes = {
  data: PropTypes.array,
  handleClose: PropTypes.func
}

IntroPopup.defaultProps = {
  data: introData
}