// @ts-nocheck
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import PropTypes from "prop-types"
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";

import { getShortDate } from "../../../utils"
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export const LineGraph = ({ data, axisLabel, curve, xScale, yScale, axisBottom, name = "" }) => {
  return (
    <ResponsiveLine
      data={data}
      // motionConfig={{damping:1}}
      enableGridX={false}
      enableGridY={true}
      animate={true}
      isInteractive={true}
      colors={"#0057FF"}
      margin={{ top: 5, right: 20, bottom: 45, left: 40}}
      enableArea={true}
      defs={[
        linearGradientDef("gradientA", [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0.5 },
        ])
      ]}
      fill={[{ match: "*", id: "gradientA" }]}
      xScale={xScale}
      yScale={yScale}
      lineWidth={3}
      yFormat=" >-.2f"
      curve={curve}
      axisTop={null}
      axisRight={null}
      axisBottom={axisBottom}
      axisLeft={{
        orient: "left",
        tickSize: 3,
        tickValues: "auto",
        tickPadding: 5,
        tickRotation: 0,
        legend: axisLabel?.y,
        legendOffset: -38,
        legendPosition: "middle",
      }}
      enableSlices="x"
      // pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={false}
      theme={{
        textColor: "#9AA1A9",
      }}
      enablePoints={false}
      sliceTooltip={(props) => (
        <div className="bg-white p-[10px] border-[2px] border-primary rounded-[4px] flex justify-center items-center">
          <span className="block relative w-[20px] h-[20px] bg-primary rounded-[2px] mr-[10px]"></span>
          <span>
            {getShortDate(props?.slice?.points[0]?.data?.x)} - {props?.slice?.points[0]?.data?.y} {name && `${name}`}
          </span>
          <br />
        </div>
      )}
    />
  );
};

LineGraph.propTypes = {
  axisLabel: PropTypes.object,
  curve: PropTypes.string,
  data: PropTypes.array,
  axisBottom: PropTypes.object,
  name: PropTypes.string
};

LineGraph.defaultProps = {
  xScale: { type: "point" },
  yScale: {
    type: "linear",
    // min: "auto",
    // max: "auto",
    // stacked: true,
    // reverse: false,
  },
  curve: "monotoneX",
  data: [
    {
      "id": "japan",
      "color": "hsl(165, 70%, 50%)",
      "data": [{
        x: "0",
        y: 10
      },
      {
        x: "1",
        y: 20
      },
      {
        x: "2",
        y: 5
      }]
    },
    {
      "id": "data",
      "color": "hsl(165, 70%, 50%)",
      "data": [{
        x: "0",
        y: 20
      },
      {
        x: "1",
        y: 17
      },
      {
        x: "2",
        y: 26
      }]
    },
  ],
  enableArea: false,
  axisBottom: {
    orient: 'bottom',
    tickSize: 12,
    tickPadding: 10,
    tickRotation: -20,
    legendOffset: 36,
    legendPosition: 'middle',
 }
}