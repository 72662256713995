import { Spinner } from '../Spinner'
export interface GridBoxProps {
  title: string 
  date?: string
  subtitle?: string
  loading?: boolean
  fallbackText?: string | boolean
  className?: string
  dataAttribute?: string
}

export const GridBox: React.FC<GridBoxProps> = ({ children, title="Subscribers", date="", subtitle="", loading = false, fallbackText, className, dataAttribute }) => {
  return (
    <div 
      style={{boxShadow:"0 2px 12px rgb(0 0 0 / 7%)"}} 
      className="bg-white rounded-lg h-full w-full py-4 flex flex-col "
      {...(dataAttribute && {'data-attribute': `gridbox-${dataAttribute}`})}
    >
      <div className="flex justify-between items-center">
        <div className="text-gray-900 text-lg font-medium pl-4">{title}</div>
        <div className="text-gray-800 text-[15px] leading-[17.78px] opacity-70 pr-4">{date}</div>
      </div>
      <span className="text-gray-800 text-[15px] leading-[17.78px] mb-4 pl-4">{subtitle}</span>
      {
        loading 
        ? 
        <div className="flex items-center justify-center h-full w-full">
          <Spinner width={48} height={48} />
        </div> 
        : 
        fallbackText 
        ? 
        <div className="flex items-center justify-center h-full w-full text-gray-800 text-lg">{fallbackText}</div>
        :
        <div className={className}>{children}</div>
      }
    </div>
  )
}