import gql from 'graphql-tag'

const UPDATE_AI_SUBJECTS = gql `
  mutation updateAiSubjects($input: updateAiSubjectsInput!) {
    updateAiSubjects(input: $input){
        success
        error
    }
  }
`;

const DELETE_AI_CHAT = gql `
  mutation deleteAiChat($id: Int!) {
    deleteAiChat(id: $id){
      success
      error
    }
  }
`;

export { UPDATE_AI_SUBJECTS, DELETE_AI_CHAT };