import { Dropdown } from "../.."
import { hasPlatformFeature } from "../../../utils"

const Integration = (props) => {
  const { availableIntegrations, createConnectedIntegration, height, integrableId, integrableType = 'Widget', name, siteId, width  } = props

  const openPopup = () => {
    const url = `${process.env.REACT_APP_RAILS}/sites/${siteId}/integrations/${name}/new?${integrableType.toLowerCase()}_id=${integrableId}&token=${window?.token}`
    console.log('url',url)
    window.open(url, "MailMunchIntegration", "top="+ (((window).innerHeight/2)-(height/2)) +", left="+ ((window.innerWidth/2)-(width/2)) +", width="+width+", height="+height)
  }

  const Component = (props) => {
    const { onClick } = props
    return (
      <div onClick={onClick} className="w-[171px] h-[48px] flex items-center justify-center rounded-[4px] border-[#E2E0DB] shadow cursor-pointer">
        <img src={require(`./logos/${name}.png`)?.default} />
      </div>
    )
  }

  const handleClick = (e) => {
    if(e.value === 'addNew')
      openPopup()
    else 
      createConnectedIntegration({ variables: { input: { integrationId: e.value, integrableId, integrableType }}})
  }

  return (
    availableIntegrations.length > 0 ? <Dropdown 
      component={Component}
      callback={handleClick}
      options={
        [
          ...availableIntegrations.map(item => {
          return { label: item?.accountIdentifier, value: item?.id }
          }),
          { label: 'Add new' , value: 'addNew' }
        ]
      }
    /> : 
    hasPlatformFeature('integrations', name ) && <Component onClick={openPopup} />
  )
}

export default Integration
