import PropTypes from "prop-types"

const ProgressBar = ({ progress, exportSub = true, className }) => {
  return (
    <div className={`${className}`}>
      <div className={`relative ${exportSub && 'pt-1'}`}>
        <div className={`overflow-hidden h-2 ${exportSub && 'mb-4'} text-xs flex rounded-[5px] bg-primary-lighter`}>
          <div style={{ width: progress, transition: 'width 0.5s' }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${progress === '100%' ? 'bg-success' : 'bg-primary'}`}></div>
        </div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.string,
  exportSub: PropTypes.bool,
  className: PropTypes.string
};

ProgressBar.defaultProps = {
  progress: '50%'
};

export { ProgressBar };