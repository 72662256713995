import { IconThumbDown, IconThumbUp } from "@tabler/icons";

export const FEEDBACK_BUTTONS = [
  {
    value: 'helpful',
    tooltipText: 'This was helpful',
    reactIntlId: 'tooltip.feedback.helpful',
    Icon: IconThumbUp
  },
  {
    value: 'not-helpful',
    tooltipText: 'This was not helpful',
    reactIntlId: 'tooltip.feedback.not_helpful',
    Icon: IconThumbDown
  }
];