import PropTypes from 'prop-types';
import { Skeleton } from '../';

export const TemplatesSkeleton = ({ number, previewHeight, previewWidth, textHeight, textWidth }) => {
  return(
    <div className='flex flex-wrap'>
      {
        [...Array(number)].map((item, index) => {
          return(
            <div className='mb-[24px] mr-[24px]' key={index}>
              <Skeleton width={previewWidth} height={previewHeight} className="mb-[40px]"/>
              <div className='mt-[8px]'></div>
              <Skeleton width={textWidth} height={textHeight} />
            </div>
          )
        })
      }
    </div>
  )
}

TemplatesSkeleton.propTypes = {
  number: PropTypes.number, 
  previewHeight: PropTypes.number, 
  previewWidth: PropTypes.number,
  textHeight: PropTypes.number, 
  textWidth: PropTypes.number
};

TemplatesSkeleton.defaultProps = {
  number: 18, 
  previewHeight: 206, 
  previewWidth: 244,
  textHeight: 30, 
  textWidth: 183
};