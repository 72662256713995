import gql from "graphql-tag";
import { LIST_FRAGMENT } from '../fragments'

const IS_LIST_NAME_AVAILABLE = gql`
	query isListNameAvailable ($siteId: Int!, $name: String!, $id: Int) {
		isListNameAvailable(siteId: $siteId, name: $name, id: $id){
      success
		}
	}
`;

const FETCH_LISTS = gql`
${LIST_FRAGMENT}
	query lists ($siteId: Int!) {
    lists(siteId: $siteId) {
      ...list
		}
	}
`;


export { IS_LIST_NAME_AVAILABLE, FETCH_LISTS };
