import gql from "graphql-tag";

const SIGN_IN = gql`
  mutation SignInJWT($email: String!, $password: String!) {
    signInJWT(input: { email: $email, password: $password }) {
      success
      error
      token
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation updateUserEmail($input: UpdateUserEmailInput!) {
    updateUserEmail(input: $input) {
      success
      error
      user {
        id
        email
        displayEmail
        unconfirmedEmail
      }
    }
  }
`;

export const DELETE_UNCONFIRMED_EMAIL = gql`
  mutation DeleteUnconfirmedUserEmail {
    deleteUnconfirmedEmail {
      success
      error
      user {
        id
        email
        displayEmail
        unconfirmedEmail
      }
    }
  }
`;

export const UPDATE_USER_TIMEZONE = gql`
  mutation UpdateUserTimezone($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      error
      user {
        id
        timeZone
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      error
    }
  }
`;


export const optOutUser = gql`
  mutation optInUser ($input: optInUserInput!) {
    optInUser(input: $input) 
    {
      success
      error
    }
  }
`;

export const CHECK_VERIFICATION_CODE = gql`
  mutation checkVerificationCode($input: verificationCodeInput) {
    checkVerificationCode(input: $input)
    {
      user {
        id
        email
        displayEmail
        unconfirmedEmail
      }
      success
      error
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendEmail($input: EmailChangeInput) {
    resendEmail(input: $input)
    {
      success
      error
    }
  }
`;


export default SIGN_IN;
