import { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography } from "../Typography";
import { IconChevronRight, IconCheck, IconLock } from "@tabler/icons";
import { Spinner, UtilityModal } from "..";
import { getStringValue } from "../../utils";

export const Steps = (props) => {
  const { dataAttributeMode, lastCurrentStep, type, steps, activeStepName, onChange, completedSteps, spinnerLoading, builder, isLocked } = props || {};
  let activeStep;
  steps.forEach((data, index) => {
    if (data.name === activeStepName) {
      activeStep = { ...data, index };
    }
  });

  const renderSteps = steps.map((item, index) => {
    const { name, label, icon, clickable, dataAttribute } = item || {};
    const reactIntlId = builder && `${builder}.${label.toLowerCase()}`
    return (
      <Fragment key={index}>
        {
          type === 'primary' ?
            showPrimaryStepDesign({ activeStep, label, name, onChange, spinnerLoading, dataAttribute, reactIntlId, isLocked })
            :
            showSecondaryStepDesign({ dataAttributeMode, clickable, lastCurrentStep, steps, activeStep, label, name, onChange, index, totalSteps: steps.length, icon, completedSteps })
        }
      </Fragment>
    )
  })

  return (
    <div className="flex items-center">
      {renderSteps}
    </div>
  )
};

const showPrimaryStepDesign = ({
  activeStep,
  label,
  onChange,
  name,
  spinnerLoading,
  dataAttribute = "",
  reactIntlId,
  isLocked
}) => {
  const isActive = (activeStep?.name === name) || false;
  const isStepLocked = isLocked && !isActive && ['schedule', 'review'].includes(name);
  const isAudienceStep = activeStep === 'audience';

  const handleStepChange = (name) => {
    if (['schedule', 'review'].includes(name) && isStepLocked) {
      UtilityModal({
        body: (props) => (
          <div className="p-4">
            <Typography
              label="This step is locked"
              variant="h4"
              className="text-center"
              reactIntlId="email_builder.header.locked_step.title"
            />
            <Typography
              label="You can proceed after the compliance team has approved this campaign."
              variant="p2"
              className="text-center"
              reactIntlId="email_builder.header.locked_step.description"
            />
          </div>
        ),
        dataAttribute: `${name}-step-locked`
      });
    } else {
      onChange(name);
    }
  }

  return (
    <>
      <div className={`flex items-center ${isActive ? 'mr-[14px]' : 'mr-[34px]'}`}>
        <Typography 
          label={label}
          reactIntlId={reactIntlId}
          variant={isActive ? 'h4' : 'p2'} 
          className={`${(!isActive && !spinnerLoading) ? 'cursor-pointer hover:text-primary' : 'pointer-events-none'}`} 
          // onClick={() =>  (isAudienceStep && isStepLocked) ? onChange(name) : handleLockStep(name)}
          onClick={() =>  handleStepChange(name)}
          {...(dataAttribute && { dataAttribute: `steps-${dataAttribute}` })}
        />
        {
          isActive &&
          (
            <>
            {spinnerLoading? <Spinner className="relative left-[12px]" height={13} width={14} /> : 
          <div className="flex items-center justify-center relative rounded-full bg-primary ml-[10px] w-[12px] h-[12px]">
            <IconCheck className="absolute w-[8px] text-white" />
          </div>
}
          </>
          )
        }
        {
          isStepLocked && 
          <div className="flex items-center justify-center relative rounded-full bg-warning ml-[10px] w-[12px] h-[12px]">
            <IconLock className="absolute w-[8px] text-white" />
          </div>
        }
      </div>
      <IconChevronRight className={`mr-[27px] ${isActive ? 'text-secondary' : 'text-gray-800'}`} />
    </>
  );
};

const showSecondaryStepDesign = ({
  activeStep,
  onChange,
  name,
  label,
  icon,
  index,
  lastCurrentStep,
  clickable,
  totalSteps,
  completedSteps,
  dataAttributeMode
}) => {
  const isActive = (activeStep.name === name) || false;
  const isStepCompleted = completedSteps.includes(name) || false;
  return (
    <div className='flex'>
      <div className='flex w-10 sm:w-[110px] items-center flex-col gap-y-[8px]'>
        <div 
          {...(dataAttributeMode && { 'data-attribute': `${dataAttributeMode}-${getStringValue(name)}-step` })}
          onClick={() => {
            (clickable && completedSteps.includes(name) || lastCurrentStep === name) && onChange(name);
          }} 
          className={`${isStepCompleted && !isActive ? 'bg-primary-lighter text-primary' : isActive || isStepCompleted ? 'bg-primary text-white' : 'bg-gray-500 text-gray-800'} rounded-[50px] w-[40px] h-[40px] p-[8px] ${(isStepCompleted && clickable) || ((isActive) || (lastCurrentStep === name)) ? 'hover:cursor-pointer' : 'hover:cursor-not-allowed'}`}>{isStepCompleted ? <IconCheck stroke={1.5} /> : icon}</div>
        <div className={`hidden sm:flex ${isActive ? 'font-medium' : 'font-normal text-gray-800'} text-lg`}>{label}</div>
      </div>
      {
        index < (totalSteps - 1) &&
        <hr className={`border-1 mt-[20px] ${totalSteps <= 4 ? 'w-[54px]' : 'w-8'} border-gray-500`}></hr>
      }
    </div>
  );
};


Steps.propTypes = {
  lastCurrentStep: PropTypes.string,
  completedSteps: PropTypes.array,
  type: PropTypes.string,
  steps: PropTypes.array,
  activeStep: PropTypes.string,
  onChange: PropTypes.func,
  completedSteps: PropTypes.array,
  dataAttributeMode: PropTypes.string
};

Steps.defaultProps = {
  completedSteps: [],
  type: 'primary',
  steps: ["Template", "Design", 'Information', 'Integrate'],
  activeStep: 'Template',
  onChange: undefined,
  dataAttributeMode: ''
};
