import gql from 'graphql-tag'

const FetchNotifications = gql`
  query notifications ($siteId: Int!) {
    notifications(siteId: $siteId) 
    {
      id
      name
      data
      readAt
      redirectUrl
      notificationCreatedAt
      createdAt
      updatedAt
      actionTakenAt
      targets
      pinned
    }
  }
`;

export { FetchNotifications }
