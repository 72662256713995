import PropTypes from 'prop-types'
import { IconCheck } from "@tabler/icons"
import { FormattedMessage } from 'react-intl'

export const Checkbox = (props) => {
  const { style, checked, onClick, label, className, Icon, size, disabled, assistiveText, checkIconStyles, tabIndex, ellipsis, labelMaxWidth, icon, iconText, dataAttribute, reactIntlId, reactIntlAssistiveTextId } = props
  // const disabledClassNames =  disabled ? "bg-gray pointer-events-none" : ""
  return (
    <div style={style} className={`flex justify-between items-center ${className} cursor-default ${disabled && 'pointer-events-none'}`} onClick={onClick} {...(dataAttribute && {'data-attribute': `checkbox-${dataAttribute}`})}>
      {
        Icon ? 
        <div className="flex flex-col items-center justify-center">
          <div className={`w-[78px] h-[50px] ${checked && 'text-primary'}`}>
            <Icon checked={checked}/>
          </div>
          {
            label &&
            <p className={`text-sm font-normal mt-[8px] text-gray-900`}>
              {
                reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={label}/> : label
              }
            </p>
          }
        </div>
        :
        <>
          <div className='flex items-center'>
            <div {...(tabIndex && {tabIndex})} className={`w-[13.33px] min-w-[13.33px] h-[13.33px] rounded-[1.67px] self-start relative ${checkIconStyles} ${checked ? `bg-primary ${disabled && "bg-gray-800 border-secondary-dark"}`: `${disabled ? 'bg-gray-400' : "bg-white" } border border-secondary-dark`}`}>
              {
                checked && <IconCheck className="absolute top-[0.5px] left-[0.25px]" stroke={3} size={12} color={"#FFFFFF"}/>
              }
            </div>
            {
              label &&
              <div>
                <div className={`${size === 'large' ? 'text-lg' : 'text-sm'} font-normal ml-[6px] text-secondary ${ellipsis && `overflow-ellipsis overflow-hidden whitespace-nowrap`}`}
                  {...(ellipsis && { style: { 'max-width': `${labelMaxWidth}px` } })}
                >
                  {
                    reactIntlId ? <FormattedMessage id={reactIntlId} defaultMessage={label}/> : label
                  }
                </div>
                {
                  assistiveText && <div className={`text-sm font-normal ml-[12px] text-gray-900`}>{
                    reactIntlAssistiveTextId ? <FormattedMessage id={reactIntlAssistiveTextId} defaultMessage={assistiveText}/> :
                    assistiveText
                  }</div>
                }
              </div>
            }
          </div>
          {
            icon && iconText &&
            <div className='flex self-end whitespace-nowrap leading-[100%] w-auto text-[16px] font-normal ml-[2px] bg-primary-lighter py-[4px] px-[8px] rounded'>
              {icon}
              <div className='ml-[6px] mt-[1px]'>{iconText}</div>
            </div>
          }
        </>
    }
    </div>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.element,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  size: PropTypes.string,
  assistiveText: PropTypes.string,
  disabled: PropTypes.bool,
  checkIconStyles: PropTypes.string,
  ellipsis: PropTypes.bool,
  labelMaxWidth: PropTypes.string,
  icon: PropTypes.node,
  iconText: PropTypes.string,
  style: PropTypes.object,
  reactIntlId: PropTypes.string,
  reactIntlAssistiveTextId: PropTypes.string
};

Checkbox.defaultProps = {
  style: {},
  checkIconStyles: 'mt-[3.5px]',
  size: '',
  className: '',
  checked: false,
  label: null,
  Icon: null,
  disabled: false,
  assistiveText: "",
  ellipsis: true,
  labelMaxWidth: '300',
  icon: null,
  iconText: '',
  onClick: () => console.log(""),
  reactIntlId: "",
  reactIntlAssistiveTextId: ""
}