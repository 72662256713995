import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IconLayout, IconChevronDown, IconChevronUp } from "@tabler/icons";
import SubMenuItem from "../SubMenuItem";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import Collapsible from 'react-collapsible';
import { formatDataAttribute, usePrevious } from "../../utils"

const updateUrlParams = (params, type) => {
  const searchParams = new URLSearchParams(params)
  searchParams.set('type', type)
  return searchParams.toString()
}

const VARIANTS = {
  small: {
    padding: 'pl-[10px] pr-[10px]',
    height: 'h-[36px]',
    labelFontSize: "text-[15px]",
    iconMargin: 'mr-[11px]'
  },
  default: {
    padding: 'pl-[20px] pr-[20px]',
    height: 'h-[42px]',
    labelFontSize: "text-[16px]",
    iconMargin: 'mr-[28px]'
  },
  large: {
    padding: 'pl-[13px] pr-[13px]',
    height: 'h-[56px]',
    labelFontSize: "text-sm",
    iconMargin: 'mr-[30px]'
  },
};

const MenuItem = (props) => {
  const { label = " ", icon, selected, selectedItem, variant, subMenu, href } = props;
  const [subMenuStatus, setSubMenuStatus] = useState(!selectedItem);
  const location = useLocation();
  const history = useHistory();
  const { siteId } = useParams()
  const { pathname, search } = location

  const dataAttribute = formatDataAttribute(label);

  const toggleSubMenuStatus = () => {
    setSubMenuStatus(!subMenuStatus);
  };

  const defaultClass = {
    container: `${VARIANTS[variant].height}  flex items-center hover:text-primary justify-between ${VARIANTS[variant].padding} cursor-pointer rounded-[4px] text-secondary ${
      selectedItem && "bg-primary-lighter text-primary"
    }`,
    icon: `${VARIANTS[variant].iconMargin}`,
    label: `${VARIANTS[variant].labelFontSize} font-normal font-rubik`,
  };

  const hoverClass = {
    container: "hover:bg-primary-lighter hover:text-primary",
    label: "hover:text-primary",
  };

  const previousSearchPath = usePrevious(search)

  useEffect(() => {
    if(pathname === `/sites/${siteId}/campaigns` && search === '' && previousSearchPath){
      history.push(`/sites/${siteId}/campaigns/${previousSearchPath}`)
    }
  }, [location])

  const isContactPage = href === `/sites/${siteId}/contacts`
  const isCampaignPage = href === `/sites/${siteId}/campaigns`

  return (
    <>
      <div {...((isContactPage || isCampaignPage) && {onClick: toggleSubMenuStatus})}>
        <Link to={href} data-attribute={`menu-${dataAttribute}`}>
          <div className={`${defaultClass.container} ${hoverClass.container} relative`}>
            <div className="flex items-center">
              {icon && <span className={`${defaultClass.icon}`}>{icon}</span>}
              {label && <span className={`${defaultClass.label}`}>{label}</span>}
            </div>
            {subMenu && (
              <div className="absolute top-[50%] translate-y-[-50%] right-[12px]">
                {!subMenuStatus ? (
                  <IconChevronUp size={18} stroke={1.5}/>
                ) : (
                  <IconChevronDown size={18} stroke={1.5}/>
                )}
              </div>
            )}
          </div>
        </Link>
      </div>
      <Collapsible open={!subMenuStatus} transitionTime={200}>
        {subMenu && (
          <div
            className={`relative border-l-2 border-gray-300 ml-[20px] ${
              location.pathname.includes("landing-page-test") &&
              "pointer-events-none opacity-50"
            }`}
          >
            <div className="relative left-[-2px] mt-[8px] mb-[8px]">
              {subMenu.map((subMenuItem, key) => (
                <Link
                  data-attribute={`submenu-${dataAttribute}-${formatDataAttribute(subMenuItem.label)}`}
                  key={key}
                  to={isContactPage ? subMenuItem.href : {
                    pathname: `${href}`,
                    search:
                      location?.search &&
                      updateUrlParams(location.search, subMenuItem.type),
                  }}
                >
                  <SubMenuItem {...subMenuItem} />
                </Link>
              ))}
            </div>
          </div>
        )}
      </Collapsible>
    </>
  );
};

MenuItem.propTypes = {
  variant: PropTypes.oneOf(["small", "default", "large"]),
  label: PropTypes.string,
  selected: PropTypes.bool,
  selectedItem: PropTypes.bool,
  selectedSubmenuItem: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  subMenu: PropTypes.arrayOf(PropTypes.object),
};

MenuItem.defaultProps = {
  variant: "default",
  icon: <IconLayout />,
  label: "Menu Item",
  onClick: undefined,
  selected: false,
};

export default MenuItem;
