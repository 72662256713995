import { IconAlertTriangle, IconArrowsDiagonal2, IconHistory, IconMessage, IconPlus, IconSend, IconThumbDown, IconThumbUp, IconTrash, IconUser, IconX, IconPlayerStop } from "@tabler/icons"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { FETCH_AI_CHATS, FETCH_AI_CHAT } from "../../graphql/query/aiSubject"
import { UPDATE_AI_SUBJECTS, DELETE_AI_CHAT } from "../../graphql/mutation/aiSubjects"
import { Dropdown } from "../Dropdown"
import { defaultExamples } from "./getExamples"
import { Tooltip } from "../Tooltip"

const dislikOptions = [
  {
    label: 'Hate',
    value: 'hate',
    className: 'text-white hover:bg-[#313065]'
  },
  {
    label: 'Self-harm',
    value: 'self-harm',
    className: 'text-white hover:bg-[#313065]'
  },
  {
    label: 'Sexual',
    value: 'sexual',
    className: 'text-white hover:bg-[#313065]'
  },
  {
    label: 'Violence',
    value: 'violence',
    className: 'text-white hover:bg-[#313065]'
  },
  {
    label: 'Other',
    value: 'other',
    className: 'text-white hover:bg-[#313065]'
  }
]

let cancelStream = false

const AIBody = (props) => {
  const [fullScreen, setFullScreen] = useState(false)
  const [example, setExample] = useState('')
  const [chat, setChat] = useState([])
  const [aiContentId, setAiContentId] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const siteId = Number(props.siteId)
  useEffect(() => {
    window.track_mixpanel?.('ai:chat:widget-opened');
  }, [])

  const icons = [
    {
      icon: <IconArrowsDiagonal2 width={14} height={14} />,
      label: 'Toggle full screen',
      onClick: () => setFullScreen(!fullScreen)
    },
    {
      icon: <IconPlus width={14} height={14} />,
      label: 'New chat',
      onClick: () => { setChat([]); setAiContentId() }
    }
  ]

  const { data: { aiChats = [] } = {}, client } = useQuery(FETCH_AI_CHATS, { variables: { siteId } })
  const onCompleted = (data) => {
    const { aiChat } = data;
    if (aiChat) {
      const { response, id } = aiChat;
      setAiContentId(id)
      setChat(response)
    }
  }
  const [fetchAiChat] = useLazyQuery(FETCH_AI_CHAT, { onCompleted });
  const [updateAiContent] = useMutation(UPDATE_AI_SUBJECTS)
  const [deleteAiChat, { loading: deleteLoading }] = useMutation(DELETE_AI_CHAT)

  const createAiChat = async (prompt) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_REST_API}/v1/openai/conversation`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.token}`
        },
        body: JSON.stringify({ prompt, siteId })
      });
      const { aiSubjectsId, title } = await res.json();
      client.cache?.writeQuery({
        query: FETCH_AI_CHATS,
        variables: { siteId },
        data: {
          aiChats: [
            { id: Number(aiSubjectsId), name: title, siteId, __typename: "AiChat" },
            ...aiChats,
          ]
        }
      })
      window.track_mixpanel?.('ai:chat:conversation-started');
      return aiSubjectsId;
    } catch (error) {
      console.log(error, 'error');
    }
  }

  const updateAiChat = async (res, index = null) => {
    try {
      await updateAiContent({
        variables: {
          input: {
            id: Number(aiContentId),
            siteId,
            response: res,
            ...(index !== null && { index })
          }
        }
      })
    } catch (error) {
      console.log(error, 'error');
    }
  }

  const handleSend = async (input, setInput) => {
    if (input.trim()) {
      let chatId = aiContentId;
      setError(false)
      setLoading(true)
      setInput('')
      setChat(prev => [...prev, { role: 'user', content: input }]);
      if (!aiContentId) {
        chatId = await createAiChat(input);
        setAiContentId(chatId);
      } else if (aiContentId) {
        await updateAiChat({ role: 'user', content: input })
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_REST_API}/v1/openai/chat`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`
          },
          body: JSON.stringify({ chatId })
        });
        if(response.status !== 200 || !response.ok) {
          setLoading(false)
          setError(true)
          return;
        } else {
          // eslint-disable-next-line no-undef
          const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
          let aiRes = '';
          while (true) {
            const { value, done } = await reader.read()
            if(cancelStream) {
              reader.cancel();
              cancelStream = false
              setLoading(false)
              return;
            }
            if (done) {
              setLoading(false)
              break;
            }
            aiRes += value;
            setChat(prev => {
              const lastIndex = prev.length - 1;
              if (prev[lastIndex]?.role === 'assistant') {
                return [
                  ...prev.slice(0, lastIndex), // Copy all items except the last one
                  { ...prev[lastIndex], content: aiRes } // Update the last item with a new value
                ];
              } else {
                return [...prev, { role: 'assistant', content: aiRes }]
              }
            });
          }
          return;
        }
      } catch (error) {
        console.log(error, 'error');
        setLoading(false)
        setError(true)
      }
    }
  }

  const handleFeedback = async (feedback, index) => {
    const newArray = chat.map((obj, i) => {
      if (i === index) {
        return { ...obj, feedback };
      } else {
        return obj;
      }
    });
    setChat(newArray);
    updateAiChat(newArray[index], index)
  }

  const lastChat = chat[chat.length - 1];

  return (
    <div className={`${fullScreen ? 'w-full h-full' : 'w-[500px] h-[620px] rounded-md'} chat-bg transition-all duration-1000`}>
      <div className=" flex justify-between items-center p-2">
        <div className="flex items-center">
          {icons.map(({ icon, onClick, label }, index) => (
            <Tooltip label={label}>
              <div key={index} onClick={!loading ? onClick : undefined} className={`p-[2px] border rounded-full mr-1 text-white chat-icon-bg ${loading ? 'pointer-events-none' : 'cursor-pointer'}`}>{icon}</div>
            </Tooltip>
          ))}
          <Tooltip label="Chat history">
            <Dropdown
              component={<div className={`p-[2px] border rounded-full mr-1 text-white chat-icon-bg ${loading ? 'pointer-events-none' : 'cursor-pointer'} mb-[-5px]`}><IconHistory width={14} height={14} /></div>}
              action='primary'
              callback={({ value }) => !loading && fetchAiChat({ variables: { id: Number(value) } })}
              width='300px'
              maxHeight={'max-h-[200px]'}
              backgroundColor='bg-[#151A44]'
              autoAdjustBottom={false}
              options={aiChats.length > 0 ? aiChats.map(({ id, name }) => ({
                value: id, 
                label: name.replace(/['"]+/g, '') , 
                className: 'text-white hover:bg-[#313065] capitalize', 
                icon: <IconMessage width={16} height={16} />, 
                ctaIcon: <IconTrash width={16} height={16} className='text-danger' />, 
                ctaIconClick: async () => {
                  if(deleteLoading) return;
                  await deleteAiChat({
                    variables: { id: Number(id) },
                    update: (cache, _) => cache.evict({ id: cache.identify({ id: Number(id), __typename: "AiChat" }) })
                  });
                  setChat([]);
                  setAiContentId(null);
                }
              })) : [{
                value: 'no data', label: 'No Chat history', className: 'text-white hover:bg-[#151A44] curosr-not-allowed cursor-default'
              }]}
            />
          </Tooltip>
        </div>
        <div className=" p-[2px] border rounded-full mr-1 text-white cursor-pointer chat-icon-bg" onClick={() => props.handleClose()}><IconX className=" text-white cursor-pointer" width={14} height={14} /></div>
      </div>
      <div className={` flex flex-col items-center justify-between mt-4 h-[90%] `}>
        {
          chat?.length > 0 ?
            <div className=" text-white text-xs w-full h-full overflow-scroll hide-scrollbar" id="scroller">
              {
                chat.map(({ role, content, feedback = '' }, index) => (
                  <>
                  <div className={`flex p-4 ${role === 'assistant' && 'bot-message-bg'}`}>
                    <div className=" flex w-[766px] mx-auto h-full">
                      <div className=" p-[2px] border rounded-full mr-4 h-full">
                        {
                          role === 'assistant' ?
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-robot" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M7 7h10a2 2 0 0 1 2 2v1l1 1v3l-1 1v3a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-3l-1 -1v-3l1 -1v-1a2 2 0 0 1 2 -2z"></path>
                              <path d="M10 16h4"></path>
                              <circle cx="8.5" cy="11.5" r=".5" fill="currentColor"></circle>
                              <circle cx="15.5" cy="11.5" r=".5" fill="currentColor"></circle>
                              <path d="M9 7l-1 -4"></path>
                              <path d="M15 7l1 -4"></path>
                            </svg>
                            : <IconUser width={20} height={20} />
                        }
                      </div>
                      <div style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }} className='mr-auto'>{content}</div>
                      {role === 'assistant' && <div className=" ml-4 flex items-start">
                        <div onClick={() => handleFeedback('helpful', index)} className={`p-[2px] rounded-full text-white cursor-pointer ${feedback === 'helpful' && 'bg-[#151A44]'}`}><IconThumbUp className="cursor-pointer" width={16} height={16} /></div>
                        <Dropdown
                          component={<div className={`p-[2px] rounded-full text-white cursor-pointer mb-[-5px] ${dislikOptions.some((item) => item.value === feedback) && 'bg-[#151A44]'}`}><IconThumbDown width={16} height={16} /></div>}
                          action='primary'
                          callback={({ value }) => handleFeedback(value, index)}
                          width='150px'
                          maxHeight={'max-h-[200px] text-white'}
                          backgroundColor='bg-[#151A44]'
                          autoAdjustBottom={false}
                          options={dislikOptions}
                          alignLeft={true}
                        />
                      </div>}
                    </div>
                  </div>
                  </>
                ))
              }
              { error && <div className=" p-2 mx-auto text-center cursor-pointer rounded w-[40%] bg-danger-light text-danger mt-4">Error while generating response</div> }
              <div id="anchor"></div>
            </div>
            :
            <div className=" text-center text-white w-[766px] max-w-[90%] h-full">
              <div className=" text-[32px] font-medium chat-text-bg">AI Assistant</div>
              <div className=" text-lg mb-8">Quick examples of what you can do</div>
              <div className={` mb-[40px] mx-auto overflow-scroll hide-scrollbar transition-all duration-1000 ${!fullScreen && 'h-[300px]'}`}>
                {
                  (props.examples || defaultExamples).map((example, index) => (
                    <div key={index}
                    className=" p-4 text-left border border-[#433E7A] rounded-lg mx-auto text-xs mb-4 cursor-pointer hover:bg-[#9692BF]" style={{ background: 'linear-gradient(155.14deg, rgba(255, 255, 255, 0.1) -2.13%, rgba(255, 255, 255, 0.1) 136.58%)', boxShadow: '0px 4px 49px rgba(0, 7, 72, 0.12)' }} 
                    onClick={() => {
                      setExample(example);
                      window.track_mixpanel?.('ai:chat:prompt-picked');
                    }}>
                      {example}
                    </div>
                  ))
                }
              </div>
            </div>
        }
        <div className=" w-[766px] max-w-[90%] pt-4">
          <div className="flex items-center justify-center relative">
            <TextArea loading={loading} role={lastChat?.role} handleSend={handleSend} example={example} />
          </div>
          <div className="flex justify-between items-center text-[#73759D] text-[10px] my-3">
            <div className="flex items-center"><span className="mr-2"><IconAlertTriangle width={20} height={20} /></span>AI responses can be inaccurate or misleading</div>
            <div>Shift + enter to break the line</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TextArea({ loading, handleSend, example, role }) {
  const [input, setInput] = useState('')

  useEffect(() => {
    setInput(example)
    document.getElementById('chat-textarea').focus();
  }, [example]);

  return (
    <div className="relative w-full">
      <textarea id="chat-textarea" autoFocus={true} className="p-4 h-[50px] pr-12 resize-none text-white bg-[#151A44] border border-[#433E7A] text-xs rounded-lg w-full" value={input.trimStart()} onChange={(e) => setInput(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && !loading && handleSend(input, setInput)} placeholder="Type something" />
      <span className={`absolute ${role === 'assistant' && loading ? 'right-[10px] top-[10px]' : 'right-4 top-4'} text-[#73759D] cursor-pointer`}>
        {
          role === 'assistant' && loading 
          ? 
          <div 
            className="flex items-center justify-center w-[88px] h-8 border border-[#433E7A] rounded-lg text-xs cursor-pointer hover:bg-[#9692BF]"
            style={{ background: 'linear-gradient(155.14deg, rgba(255, 255, 255, 0.1) -2.13%, rgba(255, 255, 255, 0.1) 136.58%)', boxShadow: '0px 4px 49px rgba(0, 7, 72, 0.12)' }}
            onClick={() => cancelStream=true}
            >
            <IconPlayerStop width={20} color="white" height={20} /> 
            <span className="text-xs text-white font-medium ml-1">STOP</span>
          </div>
          : 
          <IconSend width={20} height={20} onClick={() => !loading ? handleSend(input, setInput) : undefined} />
        }
      </span>
    </div>
  )
}

export default AIBody
