import { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { usePrevious } from "../../utils"
import BottomLeft from './icons/BottomLeft'
import BottomRight from './icons/BottomRight'
import TopLeft from './icons/TopLeft'
import TopRight from './icons/TopRight'

const Position = (props) => {
  const { position, value, onChange, options } = props
  const previousPosition = usePrevious(position)
  const types = {
    corner: {
      options: [
        { value: 'Top Left', Icon: TopLeft, className: "absolute top-[6px] left-[6px]", dataAttribute: "top-left" },
        { value: 'Top Right', Icon: TopRight, className: "absolute top-[6px] right-[6px]", dataAttribute: "top-right" },
        { value: 'Bottom Left', Icon: BottomLeft, className: "absolute bottom-[6px] left-[6px]", dataAttribute: "bottom-left" },
        { value: 'Bottom Right', Icon: BottomRight, className: "absolute bottom-[6px] right-[6px]", dataAttribute: "bottom-right" }
      ]
    },
    middle: {
      options: [
        { value: 'Top', Icon: TopLeft, className: "absolute rotate-45 top-0 left-[calc(50%-10px)]", dataAttribute: "top" },
        { value: 'Right', Icon: TopRight, className: "absolute rotate-45 top-[calc(50%-10px)] right-0", dataAttribute: "right" },
        { value: 'Bottom', Icon: BottomLeft, className: "absolute -rotate-45 bottom-0 left-[calc(50%-10px)]",  dataAttribute: "bottom" },
        { value: 'Left', Icon: BottomRight, className: "absolute rotate-[135deg] bottom-[calc(50%-10px)] left-0", dataAttribute: "left"  }
      ]
    },
    topBottom: {
      boxSize: 'w-[65px] h-[60px]',
      options:  [
        { value: 'Top', Icon: TopLeft, className: "absolute rotate-45 top-[5px] left-[calc(50%-10px)]", dataAttribute: "top" },
        { value: 'Bottom', Icon: BottomLeft, className: "absolute -rotate-45 bottom-[5px] left-[calc(50%-10px)]", dataAttribute: "bottom" },
      ]
    }
  }

  useLayoutEffect(() => {
    let defaultOpt = options || types[position]?.options;
    const defaultValueIndex = defaultOpt?.findIndex(item => item.value === value)
    if(defaultValueIndex === -1){
      const indexOfItem = types[previousPosition]?.options.findIndex(item => item.value === value)
      onChange(defaultOpt[indexOfItem]?.value)
    }
  }, [position])

  const { options: defaultOptions = [], boxSize } = types[position] || {}

  return (
    <div className={`${boxSize ? boxSize : 'w-[152px] h-[78px]'} relative border-gray-900 rounded-[4px] bg-white border`}>
      {
        (options || defaultOptions).map((item) => {
          const { Icon, icon, className, dataAttribute } = item
          const active = item?.value === value
          const IconComponent = Icon || icon && require(`./icons/${icon}`).default
          return (
            <div onClick={() => onChange(item?.value)} {...(dataAttribute && { "data-attribute": `radio-position-${dataAttribute}` })}>
              <IconComponent active={active} className={`${className} cursor-default ${active ? 'text-primary bg-primary-lighter' : 'text-gray-900'}`} />
            </div>
          )
        })
      }
    </div>
  )
}

Position.propTypes = {
  value: PropTypes.string,
  position: PropTypes.oneOf(['corner','middle']),
  onChange: PropTypes.func,
};


Position.defaultProps = {
  value: null,
  position: 'corner',
  options: null,
  onChange: (e) => console.log('radio position',e)
}


export default Position