import PropTypes from "prop-types"
import MarginIcon from './icons/Margin'
import PaddingIcon from './icons/Padding'


const Input = (props) => {
  const { value, className = '', onChange, dataAttribute } = props
  const handleChange = (e) => {
    const val = parseInt(e.target.value) || 0
    onChange && onChange(val)
  }
  return (
    <input 
      value={value}
      data-attribute={dataAttribute}
      //type="number"
      className={`w-[32px] h-[24px] rounded-[4px] border border-gray-400 text-sm text-gray-900 font-normal flex text-center ${className}`} 
      onChange={handleChange}
    />
  )
}

const SpacingInput = (props) => {
  const { value, action, onChange, dataAttribute } = props

  return (
    <div className={`w-[152px] h-[104px] flex flex-col bg-white rounded-[4px]`}>
      <div className="flex items-center justify-center my-[8px]">
        <Input value={value?.top} onChange={(top) => onChange({...value, top})} {...(dataAttribute && { dataAttribute: `spacing-top-${dataAttribute}` })} />
      </div>
      <div className="flex items-center justify-center">
        <Input value={value?.left} onChange={(left) => onChange({...value, left })} className="mx-[8px]" {...(dataAttribute && { dataAttribute: `spacing-left-${dataAttribute}` })}/>
        {
          action === 'margin' ? 
          <MarginIcon /> : <PaddingIcon />
        }
        <Input value={value?.right} onChange={(right) => onChange({...value, right })} className="mx-[8px]" {...(dataAttribute && { dataAttribute: `spacing-right-${dataAttribute}` })}/>
      </div>
      <div className="flex items-center justify-center my-[8px]">
        <Input value={value?.bottom} onChange={(bottom) => onChange({...value, bottom })} {...(dataAttribute && { dataAttribute: `spacing-bottom-${dataAttribute}` })}/>
      </div>
    </div>
  )
}

SpacingInput.propTypes = {
  action: PropTypes.oneOf(['margin','padding']),
  onChange: PropTypes.func,
};

SpacingInput.defaultProps = {
  action: 'margin',
  onChange: (e) => console.log('spacing changed',e)
};

export { SpacingInput } 