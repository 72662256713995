export const TIMEZONES = [{
    "label": "(GMT-12:00) International Date Line West",
    "value": "International Date Line West"
  },
  {
    "label": "(GMT-11:00) American Samoa",
    "value": "American Samoa"
  },
  {
    "label": "(GMT-11:00) Midway Island",
    "value": "Midway Island"
  },
  {
    "label": "(GMT-10:00) Hawaii",
    "value": "Hawaii"
  },
  {
    "label": "(GMT-09:00) Alaska",
    "value": "Alaska"
  },
  {
    "label": "(GMT-08:00) Pacific Time (US & Canada)",
    "value": "Pacific Time (US & Canada)"
  },
  {
    "label": "(GMT-08:00) Tijuana",
    "value": "Tijuana"
  },
  {
    "label": "(GMT-07:00) Arizona",
    "value": "Arizona"
  },
  {
    "label": "(GMT-07:00) Chihuahua",
    "value": "Chihuahua"
  },
  {
    "label": "(GMT-07:00) Mazatlan",
    "value": "Mazatlan"
  },
  {
    "label": "(GMT-07:00) Mountain Time (US & Canada)",
    "value": "Mountain Time (US & Canada)"
  },
  {
    "label": "(GMT-06:00) Central America",
    "value": "Central America"
  },
  {
    "label": "(GMT-06:00) Central Time (US & Canada)",
    "value": "Central Time (US & Canada)"
  },
  {
    "label": "(GMT-06:00) Guadalajara",
    "value": "Guadalajara"
  },
  {
    "label": "(GMT-06:00) Mexico City",
    "value": "Mexico City"
  },
  {
    "label": "(GMT-06:00) Monterrey",
    "value": "Monterrey"
  },
  {
    "label": "(GMT-06:00) Saskatchewan",
    "value": "Saskatchewan"
  },
  {
    "label": "(GMT-05:00) Bogota",
    "value": "Bogota"
  },
  {
    "label": "(GMT-05:00) Eastern Time (US & Canada)",
    "value": "Eastern Time (US & Canada)"
  },
  {
    "label": "(GMT-05:00) Indiana (East)",
    "value": "Indiana (East)"
  },
  {
    "label": "(GMT-05:00) Lima",
    "value": "Lima"
  },
  {
    "label": "(GMT-05:00) Quito",
    "value": "Quito"
  },
  {
    "label": "(GMT-04:00) Atlantic Time (Canada)",
    "value": "Atlantic Time (Canada)"
  },
  {
    "label": "(GMT-04:00) Caracas",
    "value": "Caracas"
  },
  {
    "label": "(GMT-04:00) Georgetown",
    "value": "Georgetown"
  },
  {
    "label": "(GMT-04:00) La Paz",
    "value": "La Paz"
  },
  {
    "label": "(GMT-04:00) Puerto Rico",
    "value": "Puerto Rico"
  },
  {
    "label": "(GMT-04:00) Santiago",
    "value": "Santiago"
  },
  {
    "label": "(GMT-03:30) Newfoundland",
    "value": "Newfoundland"
  },
  {
    "label": "(GMT-03:00) Brasilia",
    "value": "Brasilia"
  },
  {
    "label": "(GMT-03:00) Buenos Aires",
    "value": "Buenos Aires"
  },
  {
    "label": "(GMT-03:00) Greenland",
    "value": "Greenland"
  },
  {
    "label": "(GMT-03:00) Montevideo",
    "value": "Montevideo"
  },
  {
    "label": "(GMT-02:00) Mid-Atlantic",
    "value": "Mid-Atlantic"
  },
  {
    "label": "(GMT-01:00) Azores",
    "value": "Azores"
  },
  {
    "label": "(GMT-01:00) Cape Verde Is.",
    "value": "Cape Verde Is."
  },
  {
    "label": "(GMT+00:00) Edinburgh",
    "value": "Edinburgh"
  },
  {
    "label": "(GMT+00:00) Lisbon",
    "value": "Lisbon"
  },
  {
    "label": "(GMT+00:00) London",
    "value": "London"
  },
  {
    "label": "(GMT+00:00) Monrovia",
    "value": "Monrovia"
  },
  {
    "label": "(GMT+00:00) UTC",
    "value": "UTC"
  },
  {
    "label": "(GMT+01:00) Amsterdam",
    "value": "Amsterdam"
  },
  {
    "label": "(GMT+01:00) Belgrade",
    "value": "Belgrade"
  },
  {
    "label": "(GMT+01:00) Berlin",
    "value": "Berlin"
  },
  {
    "label": "(GMT+01:00) Bern",
    "value": "Bern"
  },
  {
    "label": "(GMT+01:00) Bratislava",
    "value": "Bratislava"
  },
  {
    "label": "(GMT+01:00) Brussels",
    "value": "Brussels"
  },
  {
    "label": "(GMT+01:00) Budapest",
    "value": "Budapest"
  },
  {
    "label": "(GMT+01:00) Casablanca",
    "value": "Casablanca"
  },
  {
    "label": "(GMT+01:00) Copenhagen",
    "value": "Copenhagen"
  },
  {
    "label": "(GMT+01:00) Dublin",
    "value": "Dublin"
  },
  {
    "label": "(GMT+01:00) Ljubljana",
    "value": "Ljubljana"
  },
  {
    "label": "(GMT+01:00) Madrid",
    "value": "Madrid"
  },
  {
    "label": "(GMT+01:00) Paris",
    "value": "Paris"
  },
  {
    "label": "(GMT+01:00) Prague",
    "value": "Prague"
  },
  {
    "label": "(GMT+01:00) Rome",
    "value": "Rome"
  },
  {
    "label": "(GMT+01:00) Sarajevo",
    "value": "Sarajevo"
  },
  {
    "label": "(GMT+01:00) Skopje",
    "value": "Skopje"
  },
  {
    "label": "(GMT+01:00) Stockholm",
    "value": "Stockholm"
  },
  {
    "label": "(GMT+01:00) Vienna",
    "value": "Vienna"
  },
  {
    "label": "(GMT+01:00) Warsaw",
    "value": "Warsaw"
  },
  {
    "label": "(GMT+01:00) West Central Africa",
    "value": "West Central Africa"
  },
  {
    "label": "(GMT+01:00) Zagreb",
    "value": "Zagreb"
  },
  {
    "label": "(GMT+01:00) Zurich",
    "value": "Zurich"
  },
  {
    "label": "(GMT+02:00) Athens",
    "value": "Athens"
  },
  {
    "label": "(GMT+02:00) Bucharest",
    "value": "Bucharest"
  },
  {
    "label": "(GMT+02:00) Cairo",
    "value": "Cairo"
  },
  {
    "label": "(GMT+02:00) Harare",
    "value": "Harare"
  },
  {
    "label": "(GMT+02:00) Helsinki",
    "value": "Helsinki"
  },
  {
    "label": "(GMT+02:00) Jerusalem",
    "value": "Jerusalem"
  },
  {
    "label": "(GMT+02:00) Kaliningrad",
    "value": "Kaliningrad"
  },
  {
    "label": "(GMT+02:00) Kyiv",
    "value": "Kyiv"
  },
  {
    "label": "(GMT+02:00) Pretoria",
    "value": "Pretoria"
  },
  {
    "label": "(GMT+02:00) Riga",
    "value": "Riga"
  },
  {
    "label": "(GMT+02:00) Sofia",
    "value": "Sofia"
  },
  {
    "label": "(GMT+02:00) Tallinn",
    "value": "Tallinn"
  },
  {
    "label": "(GMT+02:00) Vilnius",
    "value": "Vilnius"
  },
  {
    "label": "(GMT+03:00) Baghdad",
    "value": "Baghdad"
  },
  {
    "label": "(GMT+03:00) Istanbul",
    "value": "Istanbul"
  },
  {
    "label": "(GMT+03:00) Kuwait",
    "value": "Kuwait"
  },
  {
    "label": "(GMT+03:00) Minsk",
    "value": "Minsk"
  },
  {
    "label": "(GMT+03:00) Moscow",
    "value": "Moscow"
  },
  {
    "label": "(GMT+03:00) Nairobi",
    "value": "Nairobi"
  },
  {
    "label": "(GMT+03:00) Riyadh",
    "value": "Riyadh"
  },
  {
    "label": "(GMT+03:00) St. Petersburg",
    "value": "St. Petersburg"
  },
  {
    "label": "(GMT+03:00) Volgograd",
    "value": "Volgograd"
  },
  {
    "label": "(GMT+03:30) Tehran",
    "value": "Tehran"
  },
  {
    "label": "(GMT+04:00) Abu Dhabi",
    "value": "Abu Dhabi"
  },
  {
    "label": "(GMT+04:00) Baku",
    "value": "Baku"
  },
  {
    "label": "(GMT+04:00) Muscat",
    "value": "Muscat"
  },
  {
    "label": "(GMT+04:00) Samara",
    "value": "Samara"
  },
  {
    "label": "(GMT+04:00) Tbilisi",
    "value": "Tbilisi"
  },
  {
    "label": "(GMT+04:00) Yerevan",
    "value": "Yerevan"
  },
  {
    "label": "(GMT+04:30) Kabul",
    "value": "Kabul"
  },
  {
    "label": "(GMT+05:00) Ekaterinburg",
    "value": "Ekaterinburg"
  },
  {
    "label": "(GMT+05:00) Islamabad",
    "value": "Islamabad"
  },
  {
    "label": "(GMT+05:00) Karachi",
    "value": "Karachi"
  },
  {
    "label": "(GMT+05:00) Tashkent",
    "value": "Tashkent"
  },
  {
    "label": "(GMT+05:30) Chennai",
    "value": "Chennai"
  },
  {
    "label": "(GMT+05:30) Kolkata",
    "value": "Kolkata"
  },
  {
    "label": "(GMT+05:30) Mumbai",
    "value": "Mumbai"
  },
  {
    "label": "(GMT+05:30) New Delhi",
    "value": "New Delhi"
  },
  {
    "label": "(GMT+05:30) Sri Jayawardenepura",
    "value": "Sri Jayawardenepura"
  },
  {
    "label": "(GMT+05:45) Kathmandu",
    "value": "Kathmandu"
  },
  {
    "label": "(GMT+06:00) Almaty",
    "value": "Almaty"
  },
  {
    "label": "(GMT+06:00) Astana",
    "value": "Astana"
  },
  {
    "label": "(GMT+06:00) Dhaka",
    "value": "Dhaka"
  },
  {
    "label": "(GMT+06:00) Urumqi",
    "value": "Urumqi"
  },
  {
    "label": "(GMT+06:30) Rangoon",
    "value": "Rangoon"
  },
  {
    "label": "(GMT+07:00) Bangkok",
    "value": "Bangkok"
  },
  {
    "label": "(GMT+07:00) Hanoi",
    "value": "Hanoi"
  },
  {
    "label": "(GMT+07:00) Jakarta",
    "value": "Jakarta"
  },
  {
    "label": "(GMT+07:00) Krasnoyarsk",
    "value": "Krasnoyarsk"
  },
  {
    "label": "(GMT+07:00) Novosibirsk",
    "value": "Novosibirsk"
  },
  {
    "label": "(GMT+08:00) Beijing",
    "value": "Beijing"
  },
  {
    "label": "(GMT+08:00) Chongqing",
    "value": "Chongqing"
  },
  {
    "label": "(GMT+08:00) Hong Kong",
    "value": "Hong Kong"
  },
  {
    "label": "(GMT+08:00) Irkutsk",
    "value": "Irkutsk"
  },
  {
    "label": "(GMT+08:00) Kuala Lumpur",
    "value": "Kuala Lumpur"
  },
  {
    "label": "(GMT+08:00) Perth",
    "value": "Perth"
  },
  {
    "label": "(GMT+08:00) Singapore",
    "value": "Singapore"
  },
  {
    "label": "(GMT+08:00) Taipei",
    "value": "Taipei"
  },
  {
    "label": "(GMT+08:00) Ulaanbaatar",
    "value": "Ulaanbaatar"
  },
  {
    "label": "(GMT+09:00) Osaka",
    "value": "Osaka"
  },
  {
    "label": "(GMT+09:00) Sapporo",
    "value": "Sapporo"
  },
  {
    "label": "(GMT+09:00) Seoul",
    "value": "Seoul"
  },
  {
    "label": "(GMT+09:00) Tokyo",
    "value": "Tokyo"
  },
  {
    "label": "(GMT+09:00) Yakutsk",
    "value": "Yakutsk"
  },
  {
    "label": "(GMT+09:30) Adelaide",
    "value": "Adelaide"
  },
  {
    "label": "(GMT+09:30) Darwin",
    "value": "Darwin"
  },
  {
    "label": "(GMT+10:00) Brisbane",
    "value": "Brisbane"
  },
  {
    "label": "(GMT+10:00) Canberra",
    "value": "Canberra"
  },
  {
    "label": "(GMT+10:00) Guam",
    "value": "Guam"
  },
  {
    "label": "(GMT+10:00) Hobart",
    "value": "Hobart"
  },
  {
    "label": "(GMT+10:00) Melbourne",
    "value": "Melbourne"
  },
  {
    "label": "(GMT+10:00) Port Moresby",
    "value": "Port Moresby"
  },
  {
    "label": "(GMT+10:00) Sydney",
    "value": "Sydney"
  },
  {
    "label": "(GMT+10:00) Vladivostok",
    "value": "Vladivostok"
  },
  {
    "label": "(GMT+11:00) Magadan",
    "value": "Magadan"
  },
  {
    "label": "(GMT+11:00) New Caledonia",
    "value": "New Caledonia"
  },
  {
    "label": "(GMT+11:00) Solomon Is.",
    "value": "Solomon Is."
  },
  {
    "label": "(GMT+11:00) Srednekolymsk",
    "value": "Srednekolymsk"
  },
  {
    "label": "(GMT+12:00) Auckland",
    "value": "Auckland"
  },
  {
    "label": "(GMT+12:00) Fiji",
    "value": "Fiji"
  },
  {
    "label": "(GMT+12:00) Kamchatka",
    "value": "Kamchatka"
  },
  {
    "label": "(GMT+12:00) Marshall Is.",
    "value": "Marshall Is."
  },
  {
    "label": "(GMT+12:00) Wellington",
    "value": "Wellington"
  },
  {
    "label": "(GMT+12:45) Chatham Is.",
    "value": "Chatham Is."
  },
  {
    "label": "(GMT+13:00) Nuku'alofa",
    "value": "Nuku'alofa"
  },
  {
    "label": "(GMT+13:00) Samoa",
    "value": "Samoa"
  },
  {
    "label": "(GMT+13:00) Tokelau Is.",
    "value": "Tokelau Is."
  }
]