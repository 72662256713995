import { ReactComponent as ShopifyLogo } from '../assets/logos/shopify.svg';
import { ReactComponent as WixLogo } from '../assets/logos/wix.svg';
import { ReactComponent as WordpressLogo } from '../assets/logos/wordpress.svg';
import { ReactComponent as WeeblyLogo } from '../assets/logos/weebly.svg';
import { ReactComponent as OtherLogo } from '../assets/logos/other.svg';
import { ReactComponent as EmailIcon } from '../assets/logos/email.svg';
import { ReactComponent as LandingPageIcon } from '../assets/logos/landingPage.svg';
import { ReactComponent as FormsIcon } from '../assets/logos/forms.svg';
import { ReactComponent as ImportIcon } from '../assets/logos/import.svg';
import { ReactComponent as FormsHover } from '../assets/logos/formsHover.svg';
import { ReactComponent as LandingPage } from '../assets/logos/landing_page.svg';
import { ReactComponent as EmailHover } from '../assets/logos/emailHover.svg';
import { ReactComponent as Importer } from '../assets/logos/importer.svg';

import WixStep1 from "../assets/platformInstructions/wix-step1.png";
import WixStep2 from "../assets/platformInstructions/wix-step2.png";

import WordpressStep1 from "../assets/platformInstructions/wordpress-step1.png";
import WordpressStep2 from "../assets/platformInstructions/wordpress-step2.png";
import WordpressStep3 from "../assets/platformInstructions/wordpress-step3.png";

import {
  IconJumpRope,
  IconBuildingStore,
  IconShirt,
  IconToolsKitchen,
  IconTag,
  IconGlassFull,
  IconBrush,
  IconCircleDashed,
  IconCaravan,
  IconDiamond,
  IconBuildingCommunity,
  IconPlane
} from '@tabler/icons';
import { hasPlatformFeature } from '../../../utils';

export const demoContent = [
  {
    redirectLink: 'campaigns?status=all&type=widget&sort=updated_at&wordpress=widgets',
    title: 'Forms & Popup',
    desciption: 'Use smart popups, bars or embedded forms to get new contacts.',
    icon: {
      default: <FormsIcon />,
      hover: <FormsHover />
    }
  },
  {
    redirectLink: 'campaigns?status=all&type=landing_page&sort=updated_at&wordpress=landing_pages',
    title: 'Landing Page',
    desciption: 'Easily create beautiful pages that encourage signups and enable retargeting.',
    icon: {
      default: <LandingPageIcon />,
      hover: <LandingPage />
    }
  },
  {
    redirectLink: 'campaigns?status=all&type=broadcast_email&sort=updated_at&wordpress=broadcasts',
    title: 'Email Marketing',
    desciption: 'Build, launch, and measure email broadcasts and autoresponders.',
    icon: {
      default: <EmailIcon />,
      hover: <EmailHover />
    }
  },
  ...((hasPlatformFeature("subscribers", "import_subscribers")) ?
  [{
    redirectLink: 'importers',
    title: 'Import Contacts',
    desciption: 'Migrating from another service?',
    icon: {
      default: <ImportIcon />,
      hover: <Importer />
    }
  }] : []
  )
];

export const signUpReasons = [
  'I want to get started with email marketing',
  'I want to switch from my current software',
  'I’m just curious'
];

export const shopifySignUpReasonListOne = [
  'Less than $100K',
  '$100K to $500K',
  '$500K to $1M'
];

export const shopifySignUpReasonListTwo = [
  '$1M to $5M',
  '$5M to $20M',
  'More than $20M'
];

export const goalOptionsList = [
  'Growing my email list',
  'Sending more emails to my contacts',
  'Automating my email marketing',
  'Other'
];

export const industries = [
  {
    label: "Apparel & Fashion",
    value: 'Apparel & Fashion',
    Icon: <IconShirt size={24} stroke={1.25} />
  },
  {
    label: "Consumer Goods",
    value: 'Consumer Goods',
    Icon: <IconToolsKitchen size={24} stroke={1.25} />
  },
  {
    label: "Cosmetics",
    value: 'Cosmetics',
    Icon: <IconBrush size={24} stroke={1.25} />
  },
  {
    label: "Food & Beverages",
    value: 'Food & Beverages',
    Icon: <IconGlassFull size={24} stroke={1.25} />
  },
  {
    label: "Health, Wellness and Fitness",
    value: 'Health, Wellness and Fitness',
    Icon: <IconJumpRope size={24} stroke={1.25} />
  },
  {
    label: "Holidays",
    value: 'Holidays',
    Icon: <IconCaravan size={24} stroke={1.25} />
  },
  {
    label: "Luxury Goods & Jewelry",
    value: "Luxury Goods & Jewelry",
    Icon: <IconDiamond size={24} stroke={1.25} />
  },
  {
    label: "Marketing and Advertising",
    value: 'Marketing and Advertising',
    Icon: <IconTag size={24} stroke={1.25} />
  },
  {
    label: "Real Estate",
    value: "Real Estate",
    Icon: <IconBuildingCommunity size={24} stroke={1.25} />
  },
  {
    label: "Retail",
    value: 'Retail',
    Icon: <IconBuildingStore size={24} stroke={1.25} />
  },
  {
    label: "Leisure, Travel & Tourism",
    value: "Leisure, Travel & Tourism",
    Icon: <IconPlane size={24} stroke={1.25} />
  },
  {
    label: "Others",
    value: 'Others',
    Icon: <IconCircleDashed size={24} stroke={1.25} />
  },
  {
    label: "Accounting",
    value: "Accounting"
  },
  {
    label: "Airlines/Aviation",
    value: "Airlines/Aviation"
  },
  {
    label: "Alternative Dispute Resolution",
    value: "Alternative Dispute Resolution"
  },
  {
    label: "Alternative Medicine",
    value: "Alternative Medicine"
  },
  {
    label: "Animation",
    value: "Animation"
  },
  {
    label: "Architecture & Planning",
    value: "Architecture & Planning"
  },
  {
    label: "Arts and Crafts",
    value: 'Arts and Crafts'
  },
  {
    label: "Automotive",
    value: "Automotive"
  },
  {
    label: "Aviation & Aerospace",
    value: "Aviation & Aerospace"
  },
  {
    label: "Banking",
    value: "Banking"
  },
  {
    label: "Biotechnology",
    value: "Biotechnology"
  },
  {
    label: "Broadcast Media",
    value: "Broadcast Media"
  },
  {
    label: "Building Materials",
    value: "Building Materials"
  },
  {
    label: "Business Supplies and Equipment",
    value: "Business Supplies and Equipment"
  },
  {
    label: "Capital Markets",
    value: "Capital Markets"
  },
  {
    label: "Chemicals",
    value: "Chemicals"
  },
  {
    label: "Civic & Social Organization",
    value: "Civic & Social Organization"
  },
  {
    label: "Civil Engineering",
    value: "Civil Engineering"
  },
  {
    label: "Commercial Real Estate",
    value: "Commercial Real Estate"
  },
  {
    label: "Computer & Network Security",
    value: "Computer & Network Security"
  },
  {
    label: "Computer Games",
    value: "Computer Games"
  },
  {
    label: "Computer Hardware",
    value: "Computer Hardware"
  },
  {
    label: "Computer Networking",
    value: "Computer Networking"
  },
  {
    label: "Computer Software",
    value: "Computer Software"
  },
  {
    label: "Construction",
    value: "Construction"
  },
  {
    label: "Consumer Electronics",
    value: "Consumer Electronics"
  },
  {
    label: "Consumer Services",
    value: "Consumer Services"
  },
  {
    label: "Dairy",
    value: "Dairy"
  },
  {
    label: "Defense & Space",
    value: "Defense & Space"
  },
  {
    label: "Design",
    value: "Design"
  },
  {
    label: "E-Learning",
    value: 'E-Learning'
  },
  {
    label: "Education Management",
    value: "Education Management"
  },
  {
    label: "Electrical/Electronic Manufacturing",
    value: "Electrical/Electronic Manufacturing"
  },
  {
    label: "Entertainment",
    value: "Entertainment"
  },
  {
    label: "Environmental Services",
    value: "Environmental Services"
  },
  {
    label: "Events Services",
    value: "Events Services"
  },
  {
    label: "Executive Office",
    value: "Executive Office"
  },
  {
    label: "Facilities Services",
    value: "Facilities Services"
  },
  {
    label: "Farming",
    value: "Farming"
  },
  {
    label: "Financial Services",
    value: "Financial Services"
  },
  {
    label: "Fine Art",
    value: "Fine Art"
  },
  {
    label: "Fishery",
    value: "Fishery"
  },
  {
    label: "Food Production",
    value: "Food Production"
  },
  {
    label: "Fund-Raising",
    value: "Fund-Raising"
  },
  {
    label: "Furniture",
    value: "Furniture"
  },
  {
    label: "Gambling & Casinos",
    value: "Gambling & Casinos"
  },
  {
    label: "Glass, Ceramics & Concrete",
    value: "Glass, Ceramics & Concrete"
  },
  {
    label: "Government Administration",
    value: "Government Administration"
  },
  {
    label: "Government Relations",
    value: "Government Relations"
  },
  {
    label: "Graphic Design",
    value: "Graphic Design"
  },
  {
    label: "Higher Education",
    value: "Higher Education"
  },
  {
    label: "Hospital & Health Care",
    value: "Hospital & Health Care"
  },
  {
    label: "Hospitality",
    value: "Hospitality"
  },
  {
    label: "Human Resources",
    value: "Human Resources"
  },
  {
    label: "Import and Export",
    value: "Import and Export"
  },
  {
    label: "Individual & Family Services",
    value: "Individual & Family Services"
  },
  {
    label: "Industrial Automation",
    value: "Industrial Automation"
  },
  {
    label: "Information Services",
    value: "Information Services"
  },
  {
    label: "Information Technology and Services",
    value: "Information Technology and Services"
  },
  {
    label: "Insurance",
    value: "Insurance"
  },
  {
    label: "International Affairs",
    value: "International Affairs"
  },
  {
    label: "International Trade and Development",
    value: "International Trade and Development"
  },
  {
    label: "Internet",
    value: "Internet"
  },
  {
    label: "Investment Banking",
    value: "Investment Banking"
  },
  {
    label: "Investment Management",
    value: "Investment Management"
  },
  {
    label: "Judiciary",
    value: "Judiciary"
  },
  {
    label: "Law Enforcement",
    value: "Law Enforcement"
  },
  {
    label: "Law Practice",
    value: "Law Practice"
  },
  {
    label: "Legal Services",
    value: "Legal Services"
  },
  {
    label: "Legislative Office",
    value: "Legislative Office"
  },
  {
    label: "Libraries",
    value: "Libraries"
  },
  {
    label: "Logistics and Supply Chain",
    value: "Logistics and Supply Chain"
  },
  {
    label: "Machinery",
    value: "Machinery"
  },
  {
    label: "Management Consulting",
    value: "Management Consulting"
  },
  {
    label: "Maritime",
    value: "Maritime"
  },
  {
    label: "Market Research",
    value: "Market Research"
  },
  {
    label: "Mechanical or Industrial Engineering",
    value: "Mechanical or Industrial Engineering"
  },
  {
    label: "Media Production",
    value: "Media Production"
  },
  {
    label: "Medical Devices",
    value: "Medical Devices"
  },
  {
    label: "Medical Practice",
    value: "Medical Practice"
  },
  {
    label: "Mental Health Care",
    value: "Mental Health Care"
  },
  {
    label: "Military",
    value: "Military"
  },
  {
    label: "Mining & Metals",
    value: "Mining & Metals"
  },
  {
    label: "Motion Pictures and Film",
    value: "Motion Pictures and Film"
  },
  {
    label: "Museums and Institutions",
    value: "Museums and Institutions"
  },
  {
    label: "Music",
    value: "Music"
  },
  {
    label: "Nanotechnology",
    value: "Nanotechnology"
  },
  {
    label: "Newspapers",
    value: "Newspapers"
  },
  {
    label: "Non-Profit Organization Management",
    value: "Non-Profit Organization Management"
  },
  {
    label: "Oil & Energy",
    value: "Oil & Energy"
  },
  {
    label: "Online Media",
    value: "Online Media"
  },
  {
    label: "Outsourcing/Offshoring",
    value: "Outsourcing/Offshoring"
  },
  {
    label: "Package/Freight Delivery",
    value: "Package/Freight Delivery"
  },
  {
    label: "Packaging and Containers",
    value: "Packaging and Containers"
  },
  {
    label: "Paper & Forest Products",
    value: "Paper & Forest Products"
  },
  {
    label: "Performing Arts",
    value: "Performing Arts"
  },
  {
    label: "Pharmaceuticals",
    value: "Pharmaceuticals"
  },
  {
    label: "Philanthropy",
    value: "Philanthropy"
  },
  {
    label: "Photography",
    value: "Photography"
  },
  {
    label: "Plastics",
    value: "Plastics"
  },
  {
    label: "Political Organization",
    value: "Political Organization"
  },
  {
    label: "Primary/Secondary Education",
    value: "Primary/Secondary Education"
  },
  {
    label: "Printing",
    value: "Printing"
  },
  {
    label: "Professional Training & Coaching",
    value: "Professional Training & Coaching"
  },
  {
    label: "Program Development",
    value: "Program Development"
  },
  {
    label: "Public Policy",
    value: "Public Policy"
  },
  {
    label: "Public Relations and Communications",
    value: "Public Relations and Communications"
  },
  {
    label: "Public Safety",
    value: "Public Safety"
  },
  {
    label: "Publishing",
    value: "Publishing"
  },
  {
    label: "Railroad Manufacture",
    value: "Railroad Manufacture"
  },
  {
    label: "Ranching",
    value: "Ranching"
  },
  {
    label: "Recreational Facilities and Services",
    value: "Recreational Facilities and Services"
  },
  {
    label: "Religious Institutions",
    value: "Religious Institutions"
  },
  {
    label: "Renewables & Environment",
    value: "Renewables & Environment"
  },
  {
    label: "Research",
    value: "Research"
  },
  {
    label: "Restaurants",
    value: "Restaurants"
  },
  {
    label: "Security and Investigations",
    value: "Security and Investigations"
  },
  {
    label: "Semiconductors",
    value: "Semiconductors"
  },
  {
    label: "Shipbuilding",
    value: "Shipbuilding"
  },
  {
    label: "Sporting Goods",
    value: "Sporting Goods"
  },
  {
    label: "Sports",
    value: "Sports"
  },
  {
    label: "Staffing and Recruiting",
    value: "Staffing and Recruiting"
  },
  {
    label: "Supermarkets",
    value: "Supermarkets"
  },
  {
    label: "Telecommunications",
    value: "Telecommunications"
  },
  {
    label: "Textiles",
    value: "Textiles"
  },
  {
    label: "Think Tanks",
    value: "Think Tanks"
  },
  {
    label: "Tobacco",
    value: "Tobacco"
  },
  {
    label: "Translation and Localization",
    value: "Translation and Localization"
  },
  {
    label: "Transportation/Trucking/Railroad",
    value: "Transportation/Trucking/Railroad"
  },
  {
    label: "Utilities",
    value: "Utilities"
  },
  {
    label: "Venture Capital & Private Equity",
    value: "Venture Capital & Private Equity"
  },
  {
    label: "Veterinary",
    value: "Veterinary"
  },
  {
    label: "Warehousing",
    value: "Warehousing"
  },
  {
    label: "Wholesale",
    value: "Wholesale"
  },
  {
    label: "Wine and Spirits",
    value: "Wine and Spirits"
  },
  {
    label: "Wireless",
    value: "Wireless"
  },
  {
    label: "Writing and Editing",
    value: "Writing and Editing"
  }
]

export const onboardingPlatforms = [
  {
    platform: 'shopify',
    Logo: ShopifyLogo,
    redirectLink: 'https://apps.shopify.com/mailmunch?auth=1'
  },
  {
    platform: 'wix',
    Logo: WixLogo,
    marketplaceLink: 'https://www.wix.com/app-market/mailmunch-forms',
    instructions: [
      {
        step: 'Go to the Mailmunch App listing on the Wix App Marketplace. Click on Add To Site.',
        img: WixStep1
      },
      {
        step: 'Select your store and then authorize by clicking Allow and Add.',
        img: WixStep2
      }
    ]
  },
  {
    platform: 'wordpress',
    Logo: WordpressLogo,
    marketplaceLink: 'https://wordpress.org/plugins/mailmunch/',
    instructions: [
      {
        step: 'Login to the WordPress Dashboard.',
        img: WordpressStep1
      },
      {
        step: 'On the Dashboard of your WordPress admin, scroll down the left-hand sidebar and click on Plugins',
        img: WordpressStep2
      },
      {
        step: 'From the Installed Plugins page, click on Add New at the top of the page. Alternatively, click on Add New from the dropdown menu under Plugins on the sidebar.',
        img: WordpressStep3
      }
    ]
  },
  {
    platform: 'weebly',
    Logo: WeeblyLogo,
    redirectLink: 'https://www.weebly.com/app-center/mailmunch'
  },
  {
    platform: 'other',
    Logo: OtherLogo
  }
];

export const languages = [
  { value: "ab", label: "Abkhaz", nativeName: "аҧсуа" },
  { value: "aa", label: "Afar", nativeName: "Afaraf" },
  { value: "af", label: "Afrikaans", nativeName: "Afrikaans" },
  { value: "ak", label: "Akan", nativeName: "Akan" },
  { value: "sq", label: "Albanian", nativeName: "Shqip" },
  { value: "am", label: "Amharic", nativeName: "አማርኛ" },
  { value: "ar", label: "Arabic", nativeName: "العربية" },
  { value: "an", label: "Aragonese", nativeName: "Aragonés" },
  { value: "hy", label: "Armenian", nativeName: "Հայերեն" },
  { value: "as", label: "Assamese", nativeName: "অসমীয়া" },
  { value: "av", label: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
  { value: "ae", label: "Avestan", nativeName: "avesta" },
  { value: "ay", label: "Aymara", nativeName: "aymar aru" },
  { value: "az", label: "Azerbaijani", nativeName: "azərbaycan dili" },
  { value: "bm", label: "Bambara", nativeName: "bamanankan" },
  { value: "ba", label: "Bashkir", nativeName: "башҡорт теле" },
  { value: "eu", label: "Basque", nativeName: "euskara, euskera" },
  { value: "be", label: "Belarusian", nativeName: "Беларуская" },
  { value: "bn", label: "Bengali", nativeName: "বাংলা" },
  { value: "bh", label: "Bihari", nativeName: "भोजपुरी" },
  { value: "bi", label: "Bislama", nativeName: "Bislama" },
  { value: "bs", label: "Bosnian", nativeName: "bosanski jezik" },
  { value: "br", label: "Breton", nativeName: "brezhoneg" },
  { value: "bg", label: "Bulgarian", nativeName: "български език" },
  { value: "my", label: "Burmese", nativeName: "ဗမာစာ" },
  { value: "ca", label: "Catalan; Valencian", nativeName: "Català" },
  { value: "ch", label: "Chamorro", nativeName: "Chamoru" },
  { value: "ce", label: "Chechen", nativeName: "нохчийн мотт" },
  { value: "ny", label: "Chichewa; Chewa; Nyanja", nativeName: "chiCheŵa, chinyanja" },
  { value: "zh", label: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
  { value: "cv", label: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { value: "kw", label: "Cornish", nativeName: "Kernewek" },
  { value: "co", label: "Corsican", nativeName: "corsu, lingua corsa" },
  { value: "cr", label: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { value: "hr", label: "Croatian", nativeName: "hrvatski" },
  { value: "cs", label: "Czech", nativeName: "česky, čeština" },
  { value: "da", label: "Danish", nativeName: "dansk" },
  { value: "dv", label: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
  { value: "nl", label: "Dutch", nativeName: "Nederlands, Vlaams" },
  { value: "en", label: "English", nativeName: "English" },
  { value: "eo", label: "Esperanto", nativeName: "Esperanto" },
  { value: "et", label: "Estonian", nativeName: "eesti, eesti keel" },
  { value: "ee", label: "Ewe", nativeName: "Eʋegbe" },
  { value: "fo", label: "Faroese", nativeName: "føroyskt" },
  { value: "fj", label: "Fijian", nativeName: "vosa Vakaviti" },
  { value: "fi", label: "Finnish", nativeName: "suomi, suomen kieli" },
  { value: "fr", label: "French", nativeName: "français, langue française" },
  { value: "ff", label: "Fula; Fulah; Pulaar; Pular", nativeName: "Fulfulde, Pulaar, Pular" },
  { value: "gl", label: "Galician", nativeName: "Galego" },
  { value: "ka", label: "Georgian", nativeName: "ქართული" },
  { value: "de", label: "German", nativeName: "Deutsch" },
  { value: "el", label: "Greek, Modern", nativeName: "Ελληνικά" },
  { value: "gn", label: "Guaraní", nativeName: "Avañeẽ" },
  { value: "gu", label: "Gujarati", nativeName: "ગુજરાતી" },
  { value: "ht", label: "Haitian; Haitian Creole", nativeName: "Kreyòl ayisyen" },
  { value: "ha", label: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { value: "he", label: "Hebrew (modern)", nativeName: "עברית" },
  { value: "hz", label: "Herero", nativeName: "Otjiherero" },
  { value: "hi", label: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { value: "ho", label: "Hiri Motu", nativeName: "Hiri Motu" },
  { value: "hu", label: "Hungarian", nativeName: "Magyar" },
  { value: "ia", label: "Interlingua", nativeName: "Interlingua" },
  { value: "id", label: "Indonesian", nativeName: "Bahasa Indonesia" },
  { value: "ie", label: "Interlingue", nativeName: "Originally called Occidental; then Interlingue after WWII" },
  { value: "ga", label: "Irish", nativeName: "Gaeilge" },
  { value: "ig", label: "Igbo", nativeName: "Asụsụ Igbo" },
  { value: "ik", label: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { value: "io", label: "Ido", nativeName: "Ido" },
  { value: "is", label: "Icelandic", nativeName: "Íslenska" },
  { value: "it", label: "Italian", nativeName: "Italiano" },
  { value: "iu", label: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  { value: "ja", label: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)" },
  { value: "jv", label: "Javanese", nativeName: "basa Jawa" },
  { value: "kl", label: "Kalaallisut, Greenlandic", nativeName: "kalaallisut, kalaallit oqaasii" },
  { value: "kn", label: "Kannada", nativeName: "ಕನ್ನಡ" },
  { value: "kr", label: "Kanuri", nativeName: "Kanuri" },
  { value: "ks", label: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { value: "kk", label: "Kazakh", nativeName: "Қазақ тілі" },
  { value: "km", label: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { value: "ki", label: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { value: "rw", label: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { value: "ky", label: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { value: "kv", label: "Komi", nativeName: "коми кыв" },
  { value: "kg", label: "Kongo", nativeName: "KiKongo" },
  { value: "ko", label: "Korean", nativeName: "한국어 (韓國語), 조선말 (朝鮮語)" },
  { value: "ku", label: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { value: "kj", label: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
  { value: "la", label: "Latin", nativeName: "latine, lingua latina" },
  { value: "lb", label: "Luxembourgish, Letzeburgesch", nativeName: "Lëtzebuergesch" },
  { value: "lg", label: "Luganda", nativeName: "Luganda" },
  { value: "li", label: "Limburgish, Limburgan, Limburger", nativeName: "Limburgs" },
  { value: "ln", label: "Lingala", nativeName: "Lingála" },
  { value: "lo", label: "Lao", nativeName: "ພາສາລາວ" },
  { value: "lt", label: "Lithuanian", nativeName: "lietuvių kalba" },
  { value: "lu", label: "Luba-Katanga", nativeName: "" },
  { value: "lv", label: "Latvian", nativeName: "latviešu valoda" },
  { value: "gv", label: "Manx", nativeName: "Gaelg, Gailck" },
  { value: "mk", label: "Macedonian", nativeName: "македонски јазик" },
  { value: "mg", label: "Malagasy", nativeName: "Malagasy fiteny" },
  { value: "ms", label: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
  { value: "ml", label: "Malayalam", nativeName: "മലയാളം" },
  { value: "mt", label: "Maltese", nativeName: "Malti" },
  { value: "mi", label: "Māori", nativeName: "te reo Māori" },
  { value: "mr", label: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { value: "mh", label: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { value: "mn", label: "Mongolian", nativeName: "монгол" },
  { value: "na", label: "Nauru", nativeName: "Ekakairũ Naoero" },
  { value: "nv", label: "Navajo, Navaho", nativeName: "Diné bizaad, Dinékʼehǰí" },
  { value: "nb", label: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { value: "nd", label: "North Ndebele", nativeName: "isiNdebele" },
  { value: "ne", label: "Nepali", nativeName: "नेपाली" },
  { value: "ng", label: "Ndonga", nativeName: "Owambo" },
  { value: "nn", label: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
  { value: "no", label: "Norwegian", nativeName: "Norsk" },
  { value: "ii", label: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { value: "nr", label: "South Ndebele", nativeName: "isiNdebele" },
  { value: "oc", label: "Occitan", nativeName: "Occitan" },
  { value: "oj", label: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  { value: "cu", label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", nativeName: "ѩзыкъ словѣньскъ" },
  { value: "om", label: "Oromo", nativeName: "Afaan Oromoo" },
  { value: "or", label: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { value: "os", label: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
  { value: "pa", label: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
  { value: "pi", label: "Pāli", nativeName: "पाऴि" },
  { value: "fa", label: "Persian", nativeName: "فارسی" },
  { value: "pl", label: "Polish", nativeName: "polski" },
  { value: "ps", label: "Pashto, Pushto", nativeName: "پښتو" },
  { value: "pt", label: "Portuguese", nativeName: "Português" },
  { value: "qu", label: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { value: "rm", label: "Romansh", nativeName: "rumantsch grischun" },
  { value: "rn", label: "Kirundi", nativeName: "kiRundi" },
  { value: "ro", label: "Romanian, Moldavian, Moldovan", nativeName: "română" },
  { value: "ru", label: "Russian", nativeName: "русский язык" },
  { value: "sa", label: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { value: "sc", label: "Sardinian", nativeName: "sardu" },
  { value: "sd", label: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { value: "se", label: "Northern Sami", nativeName: "Davvisámegiella" },
  { value: "sm", label: "Samoan", nativeName: "gagana faa Samoa" },
  { value: "sg", label: "Sango", nativeName: "yângâ tî sängö" },
  { value: "sr", label: "Serbian", nativeName: "српски језик" },
  { value: "gd", label: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
  { value: "sn", label: "Shona", nativeName: "chiShona" },
  { value: "si", label: "Sinhala, Sinhalese", nativeName: "සිංහල" },
  { value: "sk", label: "Slovak", nativeName: "slovenčina" },
  { value: "sl", label: "Slovene", nativeName: "slovenščina" },
  { value: "so", label: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { value: "st", label: "Southern Sotho", nativeName: "Sesotho" },
  { value: "es", label: "Spanish; Castilian", nativeName: "español, castellano" },
  { value: "su", label: "Sundanese", nativeName: "Basa Sunda" },
  { value: "sw", label: "Swahili", nativeName: "Kiswahili" },
  { value: "ss", label: "Swati", nativeName: "SiSwati" },
  { value: "sv", label: "Swedish", nativeName: "svenska" },
  { value: "ta", label: "Tamil", nativeName: "தமிழ்" },
  { value: "te", label: "Telugu", nativeName: "తెలుగు" },
  { value: "tg", label: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { value: "th", label: "Thai", nativeName: "ไทย" },
  { value: "ti", label: "Tigrinya", nativeName: "ትግርኛ" },
  { value: "bo", label: "Tibetan Standard, Tibetan, Central", nativeName: "བོད་ཡིག" },
  { value: "tk", label: "Turkmen", nativeName: "Türkmen, Түркмен" },
  { value: "tl", label: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { value: "tn", label: "Tswana", nativeName: "Setswana" },
  { value: "to", label: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
  { value: "tr", label: "Turkish", nativeName: "Türkçe" },
  { value: "ts", label: "Tsonga", nativeName: "Xitsonga" },
  { value: "tt", label: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
  { value: "tw", label: "Twi", nativeName: "Twi" },
  { value: "ty", label: "Tahitian", nativeName: "Reo Tahiti" },
  { value: "ug", label: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
  { value: "uk", label: "Ukrainian", nativeName: "українська" },
  { value: "ur", label: "Urdu", nativeName: "اردو" },
  { value: "uz", label: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { value: "ve", label: "Venda", nativeName: "Tshivenḓa" },
  { value: "vi", label: "Vietnamese", nativeName: "Tiếng Việt" },
  { value: "vo", label: "Volapük", nativeName: "Volapük" },
  { value: "wa", label: "Walloon", nativeName: "Walon" },
  { value: "cy", label: "Welsh", nativeName: "Cymraeg" },
  { value: "wo", label: "Wolof", nativeName: "Wollof" },
  { value: "fy", label: "Western Frisian", nativeName: "Frysk" },
  { value: "xh", label: "Xhosa", nativeName: "isiXhosa" },
  { value: "yi", label: "Yiddish", nativeName: "ייִדיש" },
  { value: "yo", label: "Yoruba", nativeName: "Yorùbá" },
  { value: "za", label: "Zhuang, Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" }
];

export const countries = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'AndorrA', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bonaire, Saba, Sint Eustatius', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Cote D\'Ivoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kosovo', value: 'XK' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Lao People\'S Democratic Republic', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' }
]

