
import gql from "graphql-tag"

const GET_INTEGRATIONS = gql`
  query Integrations($siteId: Int!) {
    integrations(siteId: $siteId) {
      id
      name
      type
      active
      data
      settings
      accountIdentifier
      lists
      connectedIntegrations {
        id
        type
        integrableType
        active
      }
    }
  }
`;

const GET_SHOPIFY_INTEGRATION = gql`
  query shopifyIntegration($siteId: Int!) {
    shopifyIntegration(siteId: $siteId) {
      id
      type
      active
      listId
      settings
    }
  }
`;


const GET_WIX_INTEGRATION = gql`
  query wixIntegration($siteId: Int!) {
    wixIntegration(siteId: $siteId) {
      id
      type
      active
      listId
      settings
    }
  }
`;

const GET_WEALTHBOX_INTEGRATION = gql`
  query wealthboxIntegration($siteId: Int!) {
    wealthboxIntegration(siteId: $siteId) {
      id
      type
      active
      listId
      settings
    }
  }
`;

export { GET_INTEGRATIONS, GET_SHOPIFY_INTEGRATION, GET_WIX_INTEGRATION, GET_WEALTHBOX_INTEGRATION }