import { useState, useEffect, useRef } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { IconBell, IconCircleCheck, IconAlertCircle } from "@tabler/icons"
import { randomId } from '../../../utils'
import Proptypes from "prop-types"
import { IntlProvider } from 'react-intl';
import { DEFAULT_TRANSLATION } from '../../../constants/defaultTranslation'

import "./index.css"

let readyQueue = []
let processedQueue = []

let Notifications = []
let callbackQueue = []
let forceCloseQue = []

let prev = Date.now()
let div = null

let intervalId = null

const initInterval = () => setInterval(() => {
  // delete notifications every 1000 ms
  if((Date.now() - prev) > 1000) {
    if(callbackQueue.length > 0) {
      const cb = callbackQueue.splice(0,1)
      cb[0]()
    }
  }
  if(!document.hidden && div && readyQueue.length > 0) {
    const item = readyQueue.splice(0,1)
    processedQueue.push(item[0])
    Notifications.push(item[0])
    render(Notifications.map((item, index) => <Notification key={item.id} {...item} />), div)
  }
}, 500)

/* 
  action: {
    onClick,
    label
  }
*/

const Notification = (props) => {
  const { 
    icon,
    customIcon = null,
    color = 'primary',
    body,
    autoClose = true,
    holdTime = 4000,
    action,
    onOpen,
    onClose,
    id = Date.now()
  } = props

  const notificationContainer = useRef(null);
  const [isOpen,setIsOpen] = useState(true)

  useEffect(() => {
    onOpen && onOpen()
    forceCloseQue.push(handleClose)
    if(autoClose && isOpen) {
      setTimeout(() =>
        callbackQueue.push(handleClose)
      ,holdTime)
    }
  },[])

  const handleClose = () => {
    // remove alert components
    if (isOpen && notificationContainer && notificationContainer.current) {
      // set time stamp to prevent another automatic handle close
      prev = Date.now()
      // add exit animation
      notificationContainer.current.style.animationName= 'fadeOutUp'
      notificationContainer.current.style.animationDuration = '0.5s'
      notificationContainer.current.style.animationFillMode = 'forwards'
  
      // remove current from global array
      const index = Notifications.findIndex((item) => item.id === id)
      Notifications.splice(index, 1)

      // move siblings up animation
      let nextSibling = notificationContainer.current.nextElementSibling

      while(nextSibling) {
        nextSibling.style.setProperty('--height',`-${notificationContainer.current.clientHeight}px`);
        nextSibling.style.animationName='slideInUp'
        nextSibling.style.animationDuration = '0.5s'
        nextSibling.style.animationFillMode = 'forwards'
        nextSibling = nextSibling.nextElementSibling
      }
      // remove after animation
      setTimeout(()=> {
        // reset animations so they can be applied again
        if(notificationContainer.current){
          let nextSibling = notificationContainer.current.nextElementSibling
          while(nextSibling) {
            nextSibling.style.animationName = 'none'
            nextSibling = nextSibling.nextElementSibling
          }
          // hide current div so next sibling can take its position
          if(notificationContainer.current){
            notificationContainer.current.style.display = 'none'
          }
          setIsOpen(false)
          // unmount if no notifications left in array
          if(Notifications.length === 0) {
            const target = document.getElementById('mm-toast')
            if (target) {
              clearInterval(intervalId)
              intervalId = null
              unmountComponentAtNode(target)
              target.parentNode.removeChild(target)
            }
          }
        }
      }, 500)
    }
  }
  

  // const handleOnClick = () => {
  //   action && action.onClick()
  //   handleClose()
  // }

  const variant = {
    color: {
      primary: "bg-primary",
      success: "bg-success",
      danger: "bg-danger"
    },
    icon: {
      bell: <IconBell size={18} stroke={1.25} color={"#FFFFFF"} />,
      check: <IconCircleCheck size={18} stroke={1.25} color={"#FFFFFF"} />,
      alert: <IconAlertCircle size={18} stroke={1.25} color={"#FFFFFF"} />,
    }
  }

/* 
  action: {
    onClick,
    label
  }
*/

  const colorStyles = variant.color[color]
  const notificationIcon = variant.icon[icon]

  return (
    <>
    {
      isOpen && 
      <IntlProvider locale={DEFAULT_TRANSLATION} messages={window.phraseTranslation}>
        <div className="w-full flex flex-col items-center space-y-4 sm:items-center mm-toast-anim" ref={notificationContainer}>
          <div className="relative bg-white shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden rounded-[29px]">
            <div className={`py-[8px] px-[9px]  relative font-rubik text-secondary`}>
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <span className={`relative block ${colorStyles} h-[32px] w-[32px] rounded-[50%] flex justify-center items-center`}>
                    {customIcon ? customIcon : notificationIcon }
                  </span>
                </div>
                <div className="flex justify-center items-center w-full pt-[10px] pl-[12px] pr-[15px]">
                  <p className="text-sm opacity-80 leading-[14.22px] flex-1">
                    {body}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IntlProvider>
    }
    </>
  )
}

const createElements = (props) => {

  readyQueue.push(props)

  //force close previous toasts upon new toast
  for(const cb of forceCloseQue) {
    cb()
  }

  div = document.getElementById('mm-toast')
  if (!div) {
    div = document.createElement('div')
    div.id = 'mm-toast'
    props?.dataAttribute && div.setAttribute('data-attribute', `toast-${props.dataAttribute}`)
    if(props.zIndex !== -1) div.style.zIndex = props.zIndex;
    document.body.appendChild(div)
  }
}

export const ToastNotification = (props) => {
  // only create if notification was not processed before
  const processedIndex = processedQueue.findIndex((item) => item.id === props.id)
  const readyQueueIndex = readyQueue.findIndex((item) => item.id === props.id)
  
  if(processedIndex == -1 && readyQueueIndex == -1) {
    
    if(!intervalId) {
      intervalId = initInterval()
    }
    createElements(props)
  }
}

ToastNotification.propTypes = {
  body: Proptypes.string,
  icon: Proptypes.oneOf(["bell", "check", "alert"]),
  color: Proptypes.oneOf(["primary", "success", "danger"]),
  customIcon: Proptypes.element,
  body: Proptypes.string,
  autoClose: Proptypes.bool,
  holdTime: Proptypes.number,
  zIndex: Proptypes.number,
  action: Proptypes.func,
  onOpen: Proptypes.func,
  onClose: Proptypes.func,
  id: Proptypes.string,
  dataAttribute: Proptypes.string
};

Notification.defaultProps = {
  body: 'Something went wrong',
  icon: "bell",
  color: "primary",
  autoClose: true,
  holdTime: 4000,
  action: () => console.log("MM Toast Action"),
  onOpen: () => console.log("MM Toast OnOpen"),
  onClose: () => console.log("MM Toast OnClose"),
  id: randomId(),
  zIndex: -1,
}