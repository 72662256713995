import PropTypes from "prop-types";
import { IconX } from '@tabler/icons';
import { useEffect, useRef } from "react";

export const Drawer = (props) => {
  const { width, body, placement, footer, headerActionButton } = props;
  const drawerRef = useRef();

  useEffect(() => {
    document.addEventListener('click',(e) => handleClickOutside(e, drawerRef, props));

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  return (
    <div id='drawerContainer' ref={drawerRef} style={{ width }} className={`fixed filter drop-shadow-2xl rounded-[8px] h-full z-10 top-0 ${placement}-0 bg-white overflow-x-hidden	duration-500`}>
      <div className='min-w-max h-full'>
        {renderHeader(props)}

        <div style={{ height: `calc(100% - ${headerActionButton ? '48px' : '39px'} - ${footer ? '64px' : '0px'})` }}>
          {body}
        </div>

        {footer ? renderFooter(props) : ''}
      </div>
    </div>
  );
};

const handleClickOutside = (e, drawerRef, props) => {
  const root = document.getElementById('root');
  if ((document.getElementById('drawerContainer') && 
      !document.getElementById('drawerContainer').contains(e.target) && root?.contains(e.target)) && 
      drawerRef.current && drawerRef.current.clientWidth !== 0 &&
      props.closeOnOutside
    ) {
    props.closeDrawer();
  }
};

const renderHeader = (props) => {
  const { closeDrawer, title, closeIcon, headerActionButton } = props;
  return (
    <div className='relative z-50 flex pl-[20px] pr-[16px] pt-[16px]'>
      <h3 className='flex flex-col justify-center h-max mr-auto font-rubik text-lg font-medium text-secondary'>
        {title}
      </h3>
      {
        headerActionButton ? 
        headerActionButton :
        (
          closeIcon && 
          <div className='hover: cursor-pointer' onClick={() => closeDrawer()}>
            <IconX size='20' />
          </div>
        )
      }
    </div>
  );
};

const renderFooter = (props) => {
  const { footer, width } = props; 
  return (
    <div style={{ maxWidth: (width === '0px' || (!width)) ? '' : width }} className={`filter drop-shadow-md h-[64px] p-[16px] rounded-[8px] overflow-x-scroll`}>
      {footer}
    </div>
  );
};

Drawer.propTypes = {
  width: PropTypes.string, 
  body: PropTypes.node, 
  placement: PropTypes.string, 
  title: PropTypes.node,
  footer: PropTypes.node,
  closeDrawer: PropTypes.func,
  closeOnOutside: PropTypes.bool,
  closeIcon: PropTypes.bool,
  headerActionButton: PropTypes.node
};

Drawer.defaultProps = {
  closeIcon: true,
  closeOnOutside: true,
  width: '300px', 
  body: undefined, 
  placement: 'left', 
  title: undefined,
  footer: undefined,
  closeDrawer: () => console.log('CLOSED CALLED'),
  headerActionButton: null
};