import { FC } from "react";

interface GridLayoutProps {
  cols?: number;
  rows?:number
  elements: { body: JSX.Element; width: number; height:number }[];
}

//left to right pattern
export const GridLayout: FC<GridLayoutProps> = ({ elements, cols = 4, rows = 4 }) => {
  const mapReduce = elements.map(elem => elem.height * elem.width ).reduce((previousValue, currentValue) => previousValue + currentValue)
  const h = Math.ceil(mapReduce / cols)
  return (
    <div
      className="grid grid-flow-row w-full h-full"
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        // gridTemplateRows: `repeat(${h}, 1fr)`,
        gridAutoRows:"176px",
        gridGap: "24px",
        padding: "24px 0px"
      }}
    >
      {elements.map((element, index) => (
        <div
          key={index}
          style={{
            gridColumn: `span ${element.width} / span ${element.width}`,
            gridRow: `span ${element.height} / span ${element.height}`
          }}
        >
          {element.body}
        </div>
      ))}
    </div>
  );
};