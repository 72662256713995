import { useMutation } from "@apollo/client"
import { IconAlertCircle } from "@tabler/icons";
import { ModalBody } from "../../Modal";
import { FLAGS_CDN_URL } from "../../../constants/defaultTranslation";
import { UPDATE_USER } from "../../../graphql/mutation/user";

export const ConfirmationModal = (props) => {
  const [updateUser] = useMutation(UPDATE_USER)
  const [, langCode = ''] = props?.appLocale?.split('-')
  return (
    <ModalBody
      {...props}
      icon={<IconAlertCircle />}
      action="warning"
      title={
        <div className='flex gap-x-[10px] mt-[10px]'>
          <div>Switch your language to {props.label || 'Test File'}</div>
          { langCode && <img width={22} height={22} className='rounded-[2px]' src={`${FLAGS_CDN_URL}/${langCode.toLowerCase()}.svg`} /> }
        </div>
      }
      description="Confirming this will refresh your page and you might lose your unsaved changes !"
      buttons={[
        {
          action: "warning",
          label: "Confirm",
          onClick: async () => {
            await updateUser({
              variables: {
                input: {
                  id: props.userId,
                  data: { appLocale: props.appLocale }
                }
              }
            })
            window.location.reload();
          }
        },
      ]}
    />
  );
};