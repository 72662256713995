import React from "react";

const AvatarIcon = ({ className = "" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" r="16" fill="#F1F1F1" />
      <mask
        id="mask0_521_3719"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <circle cx="16" cy="16" r="16" fill="#F1F1F1" />
      </mask>
      <g mask="url(#mask0_521_3719)">
        <circle cx="15.9998" cy="12.3455" r="5.34545" fill="#C4C4C4" />
        <path
          d="M16.0004 19.4727C8.61982 19.4727 2.63672 27.6496 2.63672 37.7364H29.364C29.364 27.6496 23.3809 19.4727 16.0004 19.4727Z"
          fill="#C4C4C4"
        />
      </g>
    </svg>
  );
};

export default AvatarIcon;
