import PropTypes from "prop-types"
import { useDropzone } from "react-dropzone";
import { readString } from 'react-papaparse'
import { ReactComponent as UploadIcon } from './uploadIcon.svg'
import { Spinner } from "../Spinner"
import { FormattedMessage } from 'react-intl';

const parseOptions = {
  skipEmptyLines: true
};


export const Upload = ({ className, file, height, icon, loading, prompt, setFile, width, dataAttribute }) => {

  const { getRootProps } = useDropzone({
    accept: ".csv",
    onDrop: (files) => {
      if(files.length === 0){
        alert("File type must be CSV")
        return
      }
      parseFile(files[0])
    }
  });

  const parseFile = (file) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      const text = e.target.result;
      const processedData = readString(text, parseOptions)
      setFile({
        data: processedData.data,
        name: file.name,
        content: file
      })
    }
    reader.readAsText(file);
  }

  return(
    <label htmlFor="file-upload" {...getRootProps({ className: "dropzone cursor-pointer" })} 
      {...(dataAttribute && { 'data-attribute': `file-upload-${dataAttribute}` })}
    >
      <div className={`flex flex-col border-2 border-dashed border-primary-lighter rounded-[16px] bg-bg-gray-100 justify-center items-center ${width} ${height} ${className}`}>
        {
          loading ?
          <>
            <Spinner height={30} width={30}/> 
            <div className="text-sm text-secondary leading-[14.22px] mt-[15px]">Uploading...</div>
          </>
          :
          <>
            <div className="flex justify-center">{icon}</div>
            {/* <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={(e) => setFile(e.target.files[0])}/>  */}
            <input 
              id="file-upload"
              name="file-upload" 
              type="file" 
              accept=".csv"
              className="sr-only" 
              onChange={(e) => parseFile(e.target.files[0])}
            />
            <div className="text-secondary text-sm leading-[14.22px] text-center pt-[5px]">
            {
              file ? file.name :
              prompt
            }
            </div>
          </>
        }
      </div>
    </label>
  )
}

Upload.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  prompt: PropTypes.node,
  width: PropTypes.string,
  dataAttribute: PropTypes.string
};

Upload.defaultProps = {
  className: '',
  height: "h-[115px]",
  icon: <UploadIcon className="text-primary" />,
  prompt: <div>
      <FormattedMessage
        id="components.upload.defaultProps.promptPart1"
        defaultMessage="Drag & drop CSV file here or"
      />
      <span className='text-primary ml-1.5'>
      <FormattedMessage
        id="components.upload.defaultProps.promptPart2"
        defaultMessage="click to browse" 
      />
      </span>
  </div>,
  width: "w-[480px]",
  dataAttribute: ''
};