import gql from "graphql-tag"

export const FETCH_AI_CHATS = gql`
  query aiChats($siteId: Int!) {
    aiChats(siteId: $siteId) {
      id
      siteId
      name
    }
  }
`;

export const FETCH_AI_CHAT = gql`
  query aiChat($id: Int!) {
    aiChat(id: $id) {
      id
      siteId
      completionId
      response
    }
  }
`;