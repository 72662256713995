import PropTypes from "prop-types";
import AvatarIcon from "../../assets/svg/AvatarIcon";

export const Avatar = (props) => {
  const { imageSrc, width, height } = props;
  return (
    <div>
      {imageSrc ? (
        <img width={width} height={height} className="rounded-full" src={imageSrc} alt="" />
      ) : (
        <AvatarIcon/>
      )}
    </div>
  );
};

Avatar.propTypes = {
  imageSrc: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Avatar.defaultProps = {
  width: 30,
  height: 30,
  imageSrc: ""
};
