import gql from "graphql-tag"

export const CURRENT_USER = gql`
  query me($months: Int, $userStats: Boolean = false, $userPassword: Boolean = false, $checkCancelLink: Boolean = false, $includeSiteOwner: Boolean = false) {
    me {
      id
      name
      email
      displayEmail
      referral
      channelName
      timeZone
      lifeTimeSentEmails @include(if: $userStats)
      currentBillingMonthSentEmails @include(if: $userStats)
      billing @include(if: $userStats)
      manualPlan
      createdAt
      data
      entitlements
      pricingVersionUrl
      hasPaidSubscriptions(months: $months) @include(if: $userStats)
      isShopify
      isWordpress
      isMailchimp
      isWix
      isBetaUser
      emailDisabled
      broadcastDisabled
      trialExpiresAt
      trialEligible
      subscriptionStatus
      isVerified
      isCreatedAfter
      unconfirmedEmail
      notVerified
      planExpiryDate
      guestUser
      lastSignInAt
      intercomUserHash
      siteCount
      hasPassword @include(if: $userPassword)
      showCancelLink @include(if: $checkCancelLink)
      site {
        id
        isSiteOwner @include(if: $includeSiteOwner)
        siteOwner @include(if: $includeSiteOwner) {
          id
          email
        }
      }
    }
  }
`;

export const DATA_FOR_SIDE_EFFECTS = gql`
  query me {
    me {
      id
      email
      subscribersCount
      activeWidgetsCount
      landingPageCount
      createdAt
    }
  }
`
