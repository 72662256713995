import { FC } from 'react'
import { Button } from '..'
import { trialEndModalForMembers } from '../../utils'
import { FormattedMessage } from 'react-intl'

interface TrailEndHeaderProps {
  daysLeft: number
  totalTrialDays: number
  isSiteOwner: boolean
}

export const TrailEndHeader:FC<TrailEndHeaderProps> = ({ daysLeft, totalTrialDays, isSiteOwner }) => {
  const remainingDays = daysLeft < 0 ? 0 : daysLeft
  const progressPercent = ((remainingDays/totalTrialDays) * 100)?.toFixed()
  return <div className="flex items-center justify-center bg-primary-lighter text-sm p-2 h-12 w-full">
  <strong className="mr-1">{remainingDays}</strong>
  <FormattedMessage id={`notifications.remainingDays.${remainingDays == 1 ? 'day' : 'Days'}`} defaultMessage={`day${remainingDays == 1 ? '' : 's'} left in your trial`} />
    <div className={`relative w-96 mx-4`}>
      <div className={`overflow-hidden h-3 text-xs flex rounded-[5px] bg-gradient-to-r from-red-500 to-blue-800`}>
        <div style={{ left: `${progressPercent}%` }} className={`shadow-none relative w-full justify-center bg-gray-600`} />
        <div style={{ left:`${parseInt(progressPercent) - 3}%`}} className={`w-6 h-6 -top-4 bg-primary rounded-full absolute border-2 border-[#fff] text-[#fff] font-bold flex justify-center items-center`}>
          {remainingDays}
          <i className={`absolute -ml-3 w-6 h-3 overflow-hidden after:absolute after:w-[6px] after:h-[6px] after:bg-[#fff] left-2/4 after:-translate-x-2/4 top-full after:left-2/4 after:transform after:rotate-45 after:-translate-y-2/4`}></i>
        </div>
      </div>
  </div>
  <Button 
    id={'dasbhboard_topbar_upgrade_click'} 
    action="primary" 
    reactIntlId="global.button.upgradeNow"
    label="Upgrade Now"
    onClick={() => {
    window?.track_mixpanel('free-trial:topbar:upgrade-button')
    isSiteOwner ? window.pricing?.show() : trialEndModalForMembers()}
    } />
  </div>
}