import { Link } from 'react-router-dom'
import  SiteSelector from '../SiteSelector';
import MenuItem from "../MenuItem";
import { hasPlatformFeature } from '../../utils';


const Sidebar = (props) => {
  
  return (
    <div className="hidden md:flex md:flex-shrink-0 relative z-[2]" style={{boxShadow: "4px 0px 16px rgba(17, 17, 17, 0.02)"}}>
      <div className="flex flex-col w-[225px]">
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          {
            hasPlatformFeature('sites', 'switchable') &&
            <SiteSelector />
          }
          <div className="flex-1 flex flex-col pb-4 overflow-y-auto pt-[32px]">
            <nav className="flex-1 px-2 bg-white">
              {
              props.navigation.map((item, key) => {
                const isMainMenuSelected = window.location.pathname.includes(item.href)
                const isSubMenuSelected = item.subLinks && item.subLinks.some(subLink => window.location.pathname.includes(subLink))
                return <MenuItem selectedItem={isMainMenuSelected || isSubMenuSelected} key={key} {...item}/>})
              }
            </nav>
          </div>

          <div className="pb-[20px]">
            <nav className="px-2 space-y-1">
              {
                props.footerNavigation.map((item, key) => (
                  <Link key={key} to={item.href} data-attribute="site-setting">
                    <MenuItem  selectedItem={window.location.pathname.includes(item.href)} {...item}/>
                  </Link> 
                ))
              }
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
