import { Fragment, useRef, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import PropTypes from "prop-types"
import { IconCircleCheck, IconSearch } from "@tabler/icons"
import {getStringValue} from '../../utils'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Dropdown = ({ value, callback, action, component, searchEnabled = false, options, searchValue, searchCallback, width, alignTop, alignLeft, maxHeight, autoAdjustBottom, dataAttribute, dropdownItemDataAttribute, className = '', display, backgroundColor = '', noWidth = false, offsetHeight, disabled = false }) => {

  const [optionsHeight, setOptionsHeight] = useState('')
  const [autoTop, setAutoTop] = useState(false)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)
  
  const styles = {
    global: 'font-[14px]',
    action: {
      secondary: {
        text: 'text-secondary bg-gray-400',
        underline: 'border-b border-gray-800 pb-[12px]'
      },
      primary: {
        text: `text-secondary ${backgroundColor || 'bg-white'}`,
        underline: 'border-b border-gray-100 pb-[12px]'
      }
    }
  }

  const { global, action: actionStyles } = styles
  const typeStyles = global.concat(` ${actionStyles[action].text}`)
  const disabledStyles = 'pointer-events-none opacity-50'
  const underLineStyles = global.concat(` ${actionStyles[action].underline}`)

  const beforeEnter = () => {
    setOptionsHeight(`${buttonRef.current.clientHeight + dropdownRef.current.parentNode.offsetHeight - 30}`)

    //to aligntop if bottom of screen less than dropdown height
    const { bottom } = buttonRef.current?.getBoundingClientRect()

    if(bottom + dropdownRef.current?.parentNode.offsetHeight >= document.body.offsetHeight){
      setAutoTop(true)
    } else if(offsetHeight && bottom >= offsetHeight){
      setAutoTop(true)
    } else {
      setAutoTop(false)
    }
  }

  return (
    <Listbox value={value} onChange={callback} disabled={disabled}>
      {({ open }) => (
        <>
          <div className={`relative ${!noWidth && 'w-full'} ${display}`} onClick={(e) => e.stopPropagation()}>
            <Listbox.Button ref={buttonRef} className="w-full" {...(dataAttribute && {"data-attribute": `dropdown-${dataAttribute}`})}>
              { component }
            </Listbox.Button>
              <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={beforeEnter}
            >
              <Listbox.Options className={`absolute z-50 shadow-md py-[6px] overflow-auto sm:text-sm rounded-[8px] ${typeStyles} ${maxHeight} ${className}`} 
                style={{width: `${width}`, ...(((autoAdjustBottom) && (alignTop || autoTop)) && { top: `-${optionsHeight}px` }), ...(alignLeft && { right: `0px` })}}
              >
                <div ref={dropdownRef}>
                  {
                    searchEnabled && 
                    <div className="relative text-dark-grey w-auto mx-auto mt-[16px] mb-[14px] ml-[20px] mr-[20px]">
                      <span className="absolute opacity-20 left-[8px] top-[10px]">
                        <IconSearch color="#021747" stroke={1} size={16}/>
                      </span>
                      <input 
                        placeholder="Search..."
                        className="border-2 border-gray-400 rounded pl-[32px] text-mm-sm pr-[12px] h-[36px] pt-[4px] pb-[4px] w-full focus:outline-none"
                        value={searchValue}
                        onChange={searchCallback}
                      />
                    </div>
                  }
                  {options.map((item, index) => {
                    const { icon = null, iconPlacement = 'left', ctaIcon = null, ctaIconClick = null, noHoverEffect = false } = item
                    return(
                      <Fragment key={index}>
                        {
                          Object.keys(item).length > 0 &&
                            <Listbox.Option
                              className={({ active }) =>
                                classNames(
                                  (active && !noHoverEffect) ? 'bg-primary-lighter' : 'bg-none',
                                  `select-none relative pt-[8px] pl-[20px] pr-[20px] ${item.className} ${item.hasUnderline ? underLineStyles : 'pb-[8px]'} ${item.disabled ? disabledStyles : !noHoverEffect ? 'cursor-pointer' : ''}`
                                )
                              }
                              value={item}
                            >
                              <div 
                                {...(dropdownItemDataAttribute && 
                                {"data-attribute": `dropdown-item-${dropdownItemDataAttribute}-${item.value ? getStringValue(item.value) : getStringValue(item.label)}`})}
                              >
                                <div className='flex items-center'>
                                  {
                                    icon && iconPlacement === 'left' &&
                                    <span className="pr-[16px]">
                                      {icon}
                                    </span>
                                  }
                                  <span className="text-sm w-full overflow-hidden">
                                    {item.label}
                                  </span>
                                  {
                                    icon && iconPlacement === 'right' &&
                                    <span className="pl-[16px]">
                                      {icon}
                                    </span>
                                  }
                                  {
                                    ctaIcon &&
                                    <span className="pl-[16px]" onClick={ (e) => {  ctaIconClick() } }>
                                      {ctaIcon}
                                    </span>
                                  }
                                </div>
                                {item.selected ? (
                                  <span className={'absolute inset-y-0 right-0 flex items-center pr-[16px]'}>
                                    <IconCircleCheck stroke={1.25} color={"#525C70"} size={20}/>
                                  </span>
                                ) : null}
                              </div>
                            </Listbox.Option>
                          }
                        </Fragment>
                    )
                  })}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

Dropdown.propTypes = {
  callback: PropTypes.func, 
  action: PropTypes.oneOf(['secondary', 'primary']), 
  component: PropTypes.element, 
  searchEnabled: PropTypes.bool,
  options: PropTypes.array,
  searchValue: PropTypes.string,
  searchCallback: PropTypes.func,
  width: PropTypes.string,
  alignment: PropTypes.oneOf(["right", "left"]),
  alignTop: PropTypes.bool, 
  alignLeft: PropTypes.bool,
  maxHeight: PropTypes.string,
  autoAdjustBottom: PropTypes.bool,
  dataAttribute: PropTypes.string,
  dropdownItemDataAttribute: PropTypes.string,
  display: PropTypes.string,
  noWidth: PropTypes.bool,
  offsetHeight: PropTypes.number,
  disabled: PropTypes.bool
};

Dropdown.defaultProps = {
  action: 'primary',
  component: <div>Dropdown</div>, 
  searchEnabled: false,
  options: [
    {
      label: "Shazaib",
      value: "shahzaib"
    },
    {
      label: "Ameeq",
      value: "ameeq",
      hasUnderline: true,
      icon: <IconSearch size={16} color={"#000"} stroke={1.5}/>,
      selected: true
    },
    {
      label: "Zain",
      value: "zain",
      disabled: true
    }
  ],
  searchValue: '',
  callback: () => console.log(""),
  searchCallback: () => console.log(""),
  width: "100%",
  alignTop: false, 
  alignLeft: false,
  maxHeight: "",
  autoAdjustBottom: true,
  display:"",
  noWidth: false,
  offsetHeight: 0,
  disabled: false
};
