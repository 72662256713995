import { useState, useRef, useEffect } from 'react'
import { Spinner } from '../..'
import { FormattedMessage } from 'react-intl';

const EditIntegration = (props) => {
  const { integrationId, error, integrationName, handleUpdate, setError } = props
  const [loading, setLoading] = useState(true)
  const iframeRef = useRef()

  const onLoad = () => {
    const iframe = iframeRef.current
    //const height = iframe.contentWindow.document.body.scrollHeight
    //console.log('iframe',iframe)
    //iframe.style.height = `${height}px`
    setLoading(false)
  }

  useEffect(() => {
    if(error){
      setLoading(false)
    }
    return () => {
      setError(null)
    }
  }, [])

  return (
    <div className="relative h-full">
    {
      loading && 
      <div className="inset-0 absolute flex items-center justify-center">
        <Spinner width={48} height={48} />
      </div>
    }
    {
      error &&
      <div className="p-4 mb-[12px] border border-danger bg-danger-light rounded-[8px] flex justify-between w-full">
        <div className="flex flex-col">
          <div className="text-sm text-danger mb-[8px] font-medium">
            {integrationName} <FormattedMessage id="integrations.error.title" defaultMessage="Error" />
          </div>
          <div className="text-sm font-normal text-secondary">{error}</div>
              <div className="text-sm font-normal text-secondary cursor-pointer underline" onClick={() => handleUpdate({ integrationId: null, step: 'list'})}><FormattedMessage id="integrations.go_back.title" defaultMessage="Go Back" /></div>
        </div>
      </div>
    }
    <iframe 
      ref={iframeRef}
      frameBorder="0"
      onLoad={onLoad}
      src={`${process.env.REACT_APP_RAILS}/connected_integrations/${integrationId}/edit?token=${window.token}`} 
      style={{ height: '100%' }}
    />
    </div>
  )
}

export default EditIntegration