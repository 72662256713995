const CollectionPage = () => <svg className="text-primary" width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.36 0H1.44C1.05822 0.00041927 0.692191 0.152268 0.422229 0.422229C0.152268 0.692191 0.00041927 1.05822 0 1.44V25.56C0.00041927 25.9418 0.152268 26.3078 0.422229 26.5778C0.692191 26.8477 1.05822 26.9996 1.44 27H29.36C29.7418 26.9996 30.1078 26.8477 30.3778 26.5778C30.6477 26.3078 30.7996 25.9418 30.8 25.56V1.44C30.7996 1.05822 30.6477 0.692191 30.3778 0.422229C30.1078 0.152268 29.7418 0.00041927 29.36 0ZM2.44 1.96H28.36C28.4873 1.96011 28.6093 2.01072 28.6993 2.10072C28.7893 2.19071 28.8399 2.31273 28.84 2.44V5.84H1.96V2.44C1.96011 2.31273 2.01072 2.19071 2.10072 2.10072C2.19071 2.01072 2.31273 1.96011 2.44 1.96ZM28.36 25.04H2.44C2.31273 25.0399 2.19071 24.9893 2.10072 24.8993C2.01072 24.8093 1.96011 24.6873 1.96 24.56V7.8H28.84V24.56C28.8399 24.6873 28.7893 24.8093 28.6993 24.8993C28.6093 24.9893 28.4873 25.0399 28.36 25.04Z" fill="#0057FF"/>
<rect x="25.3999" y="3" width="2" height="2" rx="1" fill="#0057FF"/>
<rect x="22.3999" y="3" width="2" height="2" rx="1" fill="#0057FF"/>
<rect x="3.3999" y="3" width="15" height="2" rx="1" fill="#0057FF"/>
<mask id="path-5-inside-1" fill="white">
<rect x="3.3999" y="17" width="7" height="6" rx="1"/>
</mask>
<rect x="3.3999" y="17" width="7" height="6" rx="1" stroke="#0057FF" strokeWidth="4" mask="url(#path-5-inside-1)"/>
<mask id="path-6-inside-2" fill="white">
<rect x="3.3999" y="10" width="7" height="6" rx="1"/>
</mask>
<rect x="3.3999" y="10" width="7" height="6" rx="1" stroke="#0057FF" strokeWidth="4" mask="url(#path-6-inside-2)"/>
<mask id="path-7-inside-3" fill="white">
<rect x="11.3999" y="17" width="8" height="6" rx="1"/>
</mask>
<rect x="11.3999" y="17" width="8" height="6" rx="1" stroke="#0057FF" strokeWidth="4" mask="url(#path-7-inside-3)"/>
<mask id="path-8-inside-4" fill="white">
<rect x="11.3999" y="10" width="8" height="6" rx="1"/>
</mask>
<rect x="11.3999" y="10" width="8" height="6" rx="1" stroke="#0057FF" strokeWidth="4" mask="url(#path-8-inside-4)"/>
<mask id="path-9-inside-5" fill="white">
<rect x="20.3999" y="17" width="7" height="6" rx="1"/>
</mask>
<rect x="20.3999" y="17" width="7" height="6" rx="1" stroke="#0057FF" strokeWidth="4" mask="url(#path-9-inside-5)"/>
<mask id="path-10-inside-6" fill="white">
<rect x="20.3999" y="10" width="7" height="6" rx="1"/>
</mask>
<rect x="20.3999" y="10" width="7" height="6" rx="1" stroke="#0057FF" strokeWidth="4" mask="url(#path-10-inside-6)"/>
</svg>

export default CollectionPage