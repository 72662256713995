import gql from 'graphql-tag'

const CreateEmail = gql`
  mutation createEmail($input: CreateEmailInput!) {
    createEmail(input: $input) 
    {
      success
      error
      email {
        id
      }
    }
  }
`;
const ArchiveEmail = gql`
  mutation archiveEmail ($id: Int!) {
    archiveEmail(input: { 
      id: $id
    }) 
    {
      success
      error
      email {
        id
      }
    }
  }
`;
const CancelSchedule = gql`
  mutation cancelSchedule ($id: Int!) {
    cancelSchedule(input: { 
      id: $id
    }) 
    {
      success
      error
      email {
        id
      }
    }
  }
`;

const DuplicateEmail = gql`
  mutation duplicateEmail ($id: Int!) {
    duplicateEmail(input: { 
      id: $id
    }) 
    {
      success
      error
      email {
        id
      }
    }
  }
`;
const DeleteEmail = gql`
  mutation deleteEmail ($id: Int!) {
    deleteEmail(input: { 
      id: $id
    }) 
    {
      success
      error
    }
  }
`;

const DeactivateEmail = gql`
  mutation deactivateEmail ($id: Int!) {
    deactivateEmail(input: { 
      id: $id
    }) 
    {
      success
      error
      email {
        id
        status
      }
    }
  }
`;

const ConvertEmailToTemplate = gql`
  mutation convertEmailToTemplate ($id: Int!, $name: String!) {
    convertEmailToTemplate(input: { 
      id: $id
      name: $name
    }) 
    {
      success
      error
      emailTemplate {
        id
      }
    }
  }
`;

const CreateEmailPageUrlMapping = gql`
  mutation createEmailPageUrlMapping ($id: Int!) {
    createEmailPageUrlMapping(input: { 
      id: $id
    }) 
    {
      success
      error
      email {
        id
        shortCode
      }
    }
  }
`;

const UpdateEmail = gql`
  mutation updateEmail ($id: Int!, $emailTemplateId: Int, $delayInterval: Int, $delayPeriod: String, $from: String, $fromName: String, $name: String, $preheader: String, $subject: String, $json: JSON, $campaignFolderId: Int) {
    updateEmail(input: { 
      id: $id
      delayInterval: $delayInterval
      delayPeriod: $delayPeriod
      from: $from, 
      fromName: $fromName
      name: $name
      preheader: $preheader
      subject: $subject
      json: $json
      emailTemplateId: $emailTemplateId
      campaignFolderId: $campaignFolderId
    }) 
    {
      success
      error
      email {
        id
        delayPeriod
        delayInterval
        from
        fromName
        name
        preheader
        subject
        json
        emailTemplateId
        campaignFolderId
      }
    }
  }
`;

const UpdateEmailTemplate = gql`
  mutation updateEmailTemplate ($id: Int!, $name: String) {
    updateEmailTemplate(input: { 
      id: $id
      name: $name
    }) 
    {
      success
      error
      emailTemplate {
        id
        name
      }
    }
  }
`;

const CreateOrUpdateEmailComplianceReview = gql`
  mutation createOrUpdateEmailComplianceReview($emailId: Int!) {
    createOrUpdateEmailComplianceReview(input: { 
      emailId: $emailId
    }) 
    {
      success
      error
      complianceReview {
        id
        status
      }
    }
  }
`;

export {
  CancelSchedule, ArchiveEmail, CreateEmail, DuplicateEmail, DeleteEmail, DeactivateEmail, ConvertEmailToTemplate,UpdateEmail, CreateEmailPageUrlMapping, UpdateEmailTemplate, CreateOrUpdateEmailComplianceReview
}
