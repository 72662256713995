import PropTypes from "prop-types"
import Radio from './Radio'

const Picker = (props) => {
  const { value, options, onChange, className, size, variant } = props

  const defaultValue = options?.length > 0 && options?.[0]?.value

  return (
    <div className={className}>
    {
      options.map((item,index) => {
        const radioProps = {
          ...item,
          size,
          active: Array.isArray(item?.value) ? item?.value.includes(value) : (value || defaultValue) === item?.value,
          onClick: () => onChange(Array.isArray(item?.value) ? item?.value[0] : item?.value),
          variant,
        }

        return <Radio key={index} {...radioProps} className="mr-[8px]" />
      })
    }
    </div>
  )
}

Picker.propTypes = {
  size: PropTypes.oneOf(['small','large']),
  className: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['icon','basic']),
  options: PropTypes.arrayOf({ value: PropTypes.string, label: PropTypes.string, Icon: PropTypes.element }),
  onChange: PropTypes.func,
};

Picker.defaultProps = {
  size: 'small',
  className: 'flex',
  value: null,
  variant: 'icon',
  options: [
    { value: 'small', label: 'S' },
    { value: 'medium', label: 'M' },
    { value: 'large', label: 'L' },
    { value: 'extraLarge', label: 'XL' }
  ],
  
  onChange: (e) => console.log('onChange',e),
};

export default Picker