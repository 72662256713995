import Proptypes from "prop-types"

export const TrayNotification = (props) => {
  const { body, color, title, date = "", onClick, actionTakenAt } = props
  const variant = {
    color: {
      primary: "bg-primary",
      success: "bg-success",
      danger: "bg-danger"
    }
  }

  const colorStyles = variant.color[color]

  return(
    <div onClick={onClick} className="w-full cursor-pointer min-h-[70px] max-h-96 bg-white hover:bg-gray-100 border border-gray-200 pointer-events-auto overflow-hidden p-4">
      <div className="flex items-center">
        <div className={`${colorStyles} h-[24px] w-[24px] rounded-full flex justify-center items-center mr-4`}><props.icon size="16px" className="text-white" /></div>
        <p className={`${actionTakenAt ? 'font-normal' : 'font-medium'} text-lg leading-[18px] max-w-[240px] text-secondary`}>{title}</p>
        <span className="text-sm leading-[14.22px] text-secondary opacity-50 inline-block ml-auto">{date}</span>
      </div>
      <div className="flex relative items-center">
        <div className={`${actionTakenAt ? 'hidden' : 'block'} ${colorStyles} absolute rounded-full w-1 h-[80%] top-[5px] ml-[10px] mr-7`}/>
        <div className="mt-[4px] text-sm text-gray-900 leading-[17px] ml-10">{body}</div>
      </div>
    </div>
  )
}

TrayNotification.propTypes = {
  body: Proptypes.string,
  color: Proptypes.oneOf(["primary", "success", "danger"]),
  icon: Proptypes.elementType,
  title: Proptypes.string,
  className: Proptypes.string,
  date: Proptypes.string,
  onClick: Proptypes.func,
  actionTakenAt: Proptypes.any
};

TrayNotification.defaultProps = {
  body: 'Something went wrong',
  color: "success",
  icon: "bell",
  title: "Warning",
}