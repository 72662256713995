import { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react'
import { IconAlertCircle, IconNotebook, IconVideo, IconX } from '@tabler/icons'
import { createPortal, render, unmountComponentAtNode } from "react-dom";
import { ApolloProvider } from '@apollo/client';
import client from '../../graphql/client';
import { FEEDBACK_BUTTONS } from '../../constants';
import { Tooltip } from '../Tooltip';
import { FormattedMessage } from 'react-intl';

interface HelperCardProps {
  id?: string
  isHelperText?: Boolean
  reactIntlId?: string
  reactIntlDesc?: string
  title?: string
  description?: string
  children?: React.ReactNode
  component?: React.ReactNode
  className?: string
  cardClassName?: string
  setOpen: Dispatch<SetStateAction<boolean>>
  isCloseIcon: Boolean
  isArrow: Boolean
  position?: { 
    left: number 
    top: number 
  }
  feedBackCallback: (feedback: string, context: string) => void
  hasFeedBackButtons: Boolean
  videoLink: string
  knowledgeBaseReference: string
  hasFooter?: Boolean
  customPosition?: { 
    positionLeft: number 
    positionTop: number 
  },
  reactIntlTitleId?: string
  reactIntlDescriptionId?: string
}

const Helper = (props: HelperCardProps) => {
  const overlayEl = useRef<HTMLDivElement>(null)
  const { id = '', isHelperText = true, title = '', description = '', children, setOpen, position: { left, top } = {}, customPosition: { positionLeft = '', positionTop = '' } = {}, cardClassName = 'w-80', isCloseIcon = false, isArrow = false, reactIntlId, reactIntlDesc, hasFooter = true } = props

  const handleClose = () => {
    const target = document.getElementById(id)
    if (target) {
      unmountComponentAtNode(target)
      target?.parentNode?.removeChild(target)
    }
  }

  useEffect(() => {
    window.track_mixpanel?.('helpcard:opened');
  }, [])

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => e.keyCode === 27 && setOpen(prev => prev && false)
    const handleClickOutside = (e:Event) => {
      if(overlayEl.current && !overlayEl.current?.contains(e.target as Node)){
        handleClose()
        return setOpen(prev => prev && false) 
      }
    }
    const handleIframeClick = () => {
      if (document?.activeElement?.tagName === "IFRAME") {
        handleClose()
      }
    }

    // add listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener('keydown', handleKeyPress, false)
    window.addEventListener("blur", handleIframeClick);
    // remove event on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false)
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener('blur', handleIframeClick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div 
      ref={overlayEl} 
      style={{ 
        zIndex: 100000000, 
        left: positionLeft ? positionLeft : left, 
        top: positionTop ? positionTop : (top && Number(top)) ? top + 20 : top
      }} 
      onClick={(e) => e.stopPropagation()}
      className={`absolute flex justify-center items-center opacity-0 bg-opacity-60 animate-modalFadeIn`} 
    >
      { isCloseIcon && <IconX className='absolute top-2 right-2 z-[1px] cursor-pointer w-4 h-4' onClick={() => handleClose()}/> }
      <div style={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)' }} className={` bg-white h-auto rounded-lg overflow-hidden ${cardClassName}`}>
        {
          !isArrow ?
          <div className="w-full h-1 bg-[#85ADFF]" />
          :
          <div className="w-0 h-0 border-t-[20px] border-t-transparent border-r-[20px] border-r-white border-b-[20px] border-b-transparent absolute top-2 left-[-12px]"></div>
        }
        <div className={`px-4 ${isArrow ? 'pt-2' : 'pt-5'} pb-4`}>
          <div className=' max-w-[500px]'>
            { isHelperText && <span className="text-gray-800 text-xs">
              {
                <FormattedMessage id={'tooltip.help_text'} defaultMessage={'Quick Help'} />
              }
            </span> } 
            { title && <h3 className="mt-3 mb-2 font-medium text-lg">
              {
                reactIntlId ?
                <FormattedMessage id={reactIntlId} defaultMessage={title} />
                :
                title
              }
            </h3> }
            { description && <p className="font-normal text-base text-gray-900">
              {
                reactIntlDesc ?
                <FormattedMessage id={reactIntlDesc} defaultMessage={description} />
                :
                description
              }
            </p> }
          </div>
          <div className={`${children && 'mt-4'}`}>{children}</div>
        </div>
        { hasFooter && <Footer {...props} /> }
      </div>
      </div>
  )
}

const HelperCard = ({ id= "mm-helper-card", ...props } : HelperCardProps) => {

  const ref = useRef<HTMLButtonElement | null>(null)
  const divRef = useRef<HTMLDivElement | null>(null)
  const [open,setOpen] = useState(false)

  let div = document.getElementById(id)
  if(!div){
    div = document.createElement('div')
    div.id = id
    document.body.appendChild(div)
  }

  const position = props?.component ? divRef.current?.getBoundingClientRect() : ref.current?.getBoundingClientRect()

  const HelperPortal = () => {
    return createPortal(
      <Helper 
        {...props} 
        position={position} 
        setOpen={setOpen} 
        id={id}
      /> ,
      div as HTMLDivElement
      )
  }
  return (
    <>
    {
      props?.component ?
      <div ref={divRef} onClick={e => {
        e.stopPropagation()
        setOpen(true)
      }}>
        {props.component}
        {open && <HelperPortal />}
      </div>
      :
      <>
      <button ref={ref} className={props.className} onClick={e => {
        e.stopPropagation()
        setOpen(true)
        }}>
        <IconAlertCircle size={16} className="text-primary" />
      </button>
      {open && <HelperPortal />}
      </>
    }
    </>
  )
}

export const NewHelperCard = ({ id= "mm-modal", ...props } : HelperCardProps) => {
  let div = document.getElementById(id)
  if(!div){
    div = document.createElement('div')
    div.id = id
    document.body.appendChild(div)
  }
  render(<ApolloProvider client={client}><Helper {...props} 
    customPosition={props.customPosition}
    setOpen={() => true} 
    id={id}/></ApolloProvider> ,div)
}

const Footer = (props: HelperCardProps) => {
  const [selectedFeedBackBtn, setFeedbackBtn] = useState('');
  const { hasFeedBackButtons, videoLink, knowledgeBaseReference, feedBackCallback = () => {}, title } = props;
  const feedbackContext = title?.toLowerCase()?.split(' ')?.join('-') || "";
  return (
    <div className='flex h-[43px] justify-between p-[8px] border-t-[1px] border-[#D3D7DC]'>
      <div className={`flex absolute gap-x-[8px] ${!hasFeedBackButtons && 'hidden'}`}>
        {
          FEEDBACK_BUTTONS.map(({ tooltipText, value, Icon, reactIntlId }, id) => {
            const isActive = value === selectedFeedBackBtn;
            return (
              <Tooltip key={id} label={tooltipText} reactIntlId={reactIntlId}>
                <Icon 
                  onClick={() => {
                    if (!isActive) {
                      setFeedbackBtn(value);
                      feedBackCallback(value, feedbackContext)
                    }
                  }} 
                  size={26} 
                  className={`hover:bg-primary hover:text-white hover:cursor-pointer p-[4px] border-[1px] border-transparent ${isActive ? 'bg-primary text-white' : 'text-primary bg-[#E7EEFD]'} rounded-[50px]`}
                />
              </Tooltip>
            );
          })
        }
      </div>
      <div className={`flex absolute right-2.5 gap-x-[8px] ${!(videoLink || knowledgeBaseReference) && 'hidden'}`}>
        { knowledgeBaseReference && <IconNotebook onClick={() => {
            window.track_mixpanel?.('helpcard:article-click');
            window.open(knowledgeBaseReference)
          }} size={26} className='hover:bg-primary hover:text-white hover:cursor-pointer p-[4px] border-[1px] border-transparent text-primary bg-[#E7EEFD] rounded-[50px]'/> }
        { videoLink && <IconVideo size={26} onClick={() => {
            window.track_mixpanel?.('helpcard:video-click');
            window.open(videoLink)
        }} className='hover:bg-primary hover:text-white hover:cursor-pointer p-[4px] border-[1px] border-transparent text-primary bg-[#E7EEFD] rounded-[50px]'/> }
      </div>
    </div>
  );
};

export default HelperCard