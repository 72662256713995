import { lazy } from 'react';
import { hasPlatformFeature } from '../utils';

export const appRoutes = ({ platformFeatures }) => {

  if (window.platformFeatures === undefined) {
    window.platformFeatures = platformFeatures
  }

  return [
    ...(hasPlatformFeature("sites", "onboarding_wizard") ? [{
      id: 'onboarding-wizard',
      path: '/sites/new',
      component: window.isAppflows ? lazy(() => import(/* webpackChunkName: "onboarding-wizard"  */ '../modules/AppflowsOnboarding')) : lazy(() => import(/* webpackChunkName: "onboarding-wizard"  */ '../modules/Onboarding')),
    }] : []
    ),
    {
      id: "sites",
      path: "/sites/:siteId",
      component: lazy(() => import(/* webpackChunkName: "site" */ './Site')),
    },
    {
      id: 'siteList',
      path: '/sites',
      component: lazy(() => import(/* webpackChunkName: "site-list" */ '../modules/Sites')),
    },
    ...(
      hasPlatformFeature('account') ? [{
        id: 'account',
        path: '/account',
        component: lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/AccountSettings')),
      }] : []
    ),
    ...(
      hasPlatformFeature('automations') ? [{
        id: 'journeyTemplate',
        path: '/journey-template/:journeyTemplateId',
        component: lazy(() => import(/* webpackChunkName: "journey-builder" */ "../modules/JourneyBuilder")),
      }] : []
    ),
    {
      id: 'admin',
      path: '/admin',
      component: lazy(() => import(/* webpackChunkName: "admin" */ "../modules/Admin")),
    },
    {
      id: 'verifyEmail',
      path: '/verify-email',
      component: lazy(() => import(/* webpackChunkName: "verifyEmail" */ "../modules/EmailVerification")),
    }
  ];
}

export const publicRoutes = [
  {
    id: 'auth',
    path: '/users/',
    component: lazy(() => import(/* webpackChunkName: "Auth" */ "./Auth")),
  },
  {
    id: 'create',
    path: '/create/email/:slug',
    component: lazy(() => import(/* webpackChunkName: "CreateEmail" */ "./CreateEmail/index")),
  },
  {
    id: 'partnerOnboarding',
    path: '/partner/:partnerId',
    component: lazy(() => import(/* webpackChunkName: "CreatePartner" */ "../modules/PartnerOnboarding")),
  }
]