import { DEFAULT_TRANSLATION, FLAGS_CDN_URL } from "../../../constants/defaultTranslation";
import { countries } from "../../../modules/Onboarding/data";
import { Dropdown } from "../../Dropdown";
import { UtilityModal } from "../../Modal";
import { ConfirmationModal } from "./ConfirmationModal";

export const LanguageSelectionDropdown = ({ appLocale = DEFAULT_TRANSLATION, userId }) => {
  const selectedLang = appLocale;
  const [, langCode = ''] = selectedLang?.split('-');
  const isTestLang = !langCode;

  return (
    <Dropdown
      component={
        <div className={`${isTestLang ? 'mt-[1px] pt-[5px]' : 'mt-[6px] pt-[8px]'} mr-[10px] bg-[#F1F1F1] h-[32px] w-[32px] rounded-[50px]`}>
          {
            !isTestLang ?
            <img height='22px' width='22px' className='ml-[5px] rounded-[2px]' src={`${FLAGS_CDN_URL}/${langCode.toLowerCase()}.svg`} />
            :
            <span>T</span>
          }
        </div>
      }
      alignLeft={true}
      width='180px'
      callback={({ value, label }) => {
        UtilityModal({
          body: props => 
            <ConfirmationModal 
              {...props}
              userId={userId}
              appLocale={value}
              label={label}
            />
          });
      }}
      options={
        require?.context('../../../translations/', false, /\.json$/)?.keys()?.map((directoryFileName) => {
          const [, fileName] = directoryFileName?.split('./');
          const [translationName] = fileName?.split('.json');
          const [, twoLetterCode = ''] = translationName.split('-');
          const { label = '' } = countries.find(({ value }) => value === twoLetterCode) || {};
          return (
            {
              value: translationName,
              label: `${label}`,
              icon: twoLetterCode ? <img height={27} width={27} className='rounded-[2px]' src={`${FLAGS_CDN_URL}/${twoLetterCode.toLowerCase()}.svg`} /> : <div className='w-[60px]'>Test File</div>
            }
          );
        })
      }
    />
  );
};