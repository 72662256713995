import gql from "graphql-tag";

const GET_CONFIG = gql`
  query GetData {
    siteId @client
    visible @client
    stepName @client
    isMailchimp @client
    channelName @client
  }
`;

export default GET_CONFIG;
