import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { IconTrash } from "@tabler/icons"
import { FormattedMessage } from "react-intl";



import { Button } from "../../components"
import { loadUnlayerScript } from "../../utils"
import { ReactComponent as ImageFilePlaceholder } from "../../assets/images/image-file-placeholder.svg"

export const MediaLibraryUpload = ({ value, onChange, className }) => {

  const { siteId } = useParams();
  const [showUploadedImageOptions, setShowUploadedImageOptions] = useState(false)

  useEffect(() => {
    loadUnlayerScript()
  }, [])

  const triggerMediaLibrary = () => {
    if (typeof window.mediaLibrary != 'undefined') {
      window?.mediaLibrary.open('images', siteId, function(file) {
        onChange(file.large_url)
        window?.mediaLibrary.close()
      })
    }
  }

  const deleteImage = () => {
    if (typeof window.mediaLibrary != 'undefined') {
      onChange('')
    }
  }

  return (
    <>
        {
          value ? 
          <div 
            className={`rounded-[4px] relative ${className}`}
            style={{
              backgroundImage: `url('${value}')`,
              backgroundSize: 'cover',
              backgroundPosition: "center center"
            }}
            onMouseOver={() => setShowUploadedImageOptions(true)}
            onMouseLeave={() => setShowUploadedImageOptions(false)}
          >
            {
              showUploadedImageOptions &&
              <>
                <div 
                  className="absolute flex top-0 left-0 right-0 bottom-0 w-full h-full bg-black opacity-70 rounded-[4px] z-1"
                  
                >
                </div>
                <div className="flex w-full h-full flex-col justify-center items-center z-2 relative">
                  <Button
                    label="Change Image"
                    action="transparent"
                    labelStyles="text-xs"
                    onClick={triggerMediaLibrary}
                  />
                  <IconTrash 
                    stroke={1.25} 
                    color="#DC3535"
                    className="mt-[6px] cursor-pointer"
                    onClick={deleteImage}
                  />
                </div>
              </>
            }
          </div>
          :
          <div className={`border border-dashed rounded-[4px] flex flex-col justify-center items-center cursor-pointer ${className}`}
          onClick={triggerMediaLibrary}
        >
          <ImageFilePlaceholder className="text-primary" fill="currentColor" />
          <div className="text-xs text-secondary pt-[8px]">
            <FormattedMessage 
              id="global.image_upload.click_to"
              defaultMessage="Click to"
            />{" "}
            <span className="text-primary">
              <FormattedMessage 
                id="global.image_upload.upload_image"
                defaultMessage="Upload Image"
              />
            </span>
          </div>
        </div>
        }
    </>
  )
}

MediaLibraryUpload.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string
};

MediaLibraryUpload.defaultProps = {
  onChange: () => console.log("Media Library Upload"),
  value: "",
  className: 'w-[148px] h-[78px]'
};