import PropTypes from 'prop-types';
import { BeatLoader as BeatLoaderComponent } from "react-spinners";

export const BeatLoader = ({
  size
}) => {
  let partner = 'mailmunch'
  
  const appflows = window.location.hostname.includes('appflow')
  const whalehero = window.location.hostname.includes('whalehero')
  const autoscale = window.location.hostname.includes('autoscale')
  const funnelhq = window.location.hostname.includes('funnelhq')

  if(appflows) partner = 'appflows'
  else if(whalehero) partner = 'whalehero'
  else if(autoscale) partner = 'autoscale'
  else if (funnelhq) partner = 'funnelhq'
  else partner = 'mailmunch'

  const colors = {
    'funnelhq': '#0A0A0A',
    'appflows': '#24484C',
    'whalehero': '#2F3E46',
    'autoscale': '#ED6A33',
    'mailmunch': '#0057FF',
  }
  
  return(
    <BeatLoaderComponent color={colors[partner]} size={size} />
  )
}

BeatLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType(["string", "number"])
};

BeatLoader.defaultProps = {
  color: "#0057FF",
  size: 50
};