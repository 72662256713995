import PropTypes from "prop-types"

const Radio = (props) => {
  const { label, active, onClick, className, Icon, size, variant, dataAttribute } = props

  const activeStyles = 'border border-primary bg-white text-primary'
  const defaultStyles = 'bg-gray-400 text-gray-900'
  const sizeStyles = size === 'xl' ? `w-[90px] h-[77px]` : size === 'large' ? `w-[78px] h-[50px] ${active ? '' : 'p-[1px]'}`: 'w-[32px] h-[32px]'

  const BasicVariant = () => (
    <div className={`flex items-start text-sm ${className} cursor-default`} onClick={onClick} {...(dataAttribute && {'data-attribute': `radio-${dataAttribute}`})}>
      <input type="radio" checked={active} className="h-4 w-4 border-gray-300 mt-[2px]" />
      <span className="ml-[12px]">{label}</span>
    </div>
  )

  const IconWidgetVariant = () => {
    return (
      <div className={`flex ${active ? activeStyles : defaultStyles} ${sizeStyles} items-center justify-center cursor-default rounded-[4px] font-normal text-sm ${className}`} onClick={onClick} {...(dataAttribute && {'data-attribute': `radio-${dataAttribute}`})}>
        { label || Icon && <Icon /> }
      </div>
    )
  }

  const IconVariant = () => (
    <div className="flex flex-col items-center">
      <div className={`flex ${active && !size === 'xl' ? activeStyles : defaultStyles} ${sizeStyles} items-center justify-center cursor-default rounded-[4px] font-normal text-sm ${className}`} onClick={onClick}>
        { Icon && <Icon active={active}/> }
      </div>
      <div className="mt-[8px] text-xs font-normal text-secondary">
      { label }
      </div>
    </div>
  )

  const renderRadio = () => {
    switch(variant) {
      case 'widget':
        return <IconWidgetVariant />
      case 'icon':
        return <IconVariant />
      case 'basic':
        return <BasicVariant />
    }
  }

  return renderRadio()
}

Radio.propTypes = {
  size: PropTypes.oneOf(['small','large']),
  Icon: PropTypes.element,
  label: PropTypes.string,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['widget','icon','basic']),
  onClick: PropTypes.func,
};

Radio.defaultProps = {
  size: 'small',
  Icon: null,
  label: '',
  active: false,
  variant: 'widget',
  onClick: null
};

export default Radio